import {
  CLEANUP_SEARCH_RESULTS,
  GET_ULTIMATE_SEARCH_RESULTS,
} from '../actions-index';
import { ERROR_CONSTS } from '../util/constants';

const initialState = {
  status: '',
  alert: ERROR_CONSTS.UI.GENERIC.EMPTY_ALERT,
};

export const ultimateSearchReducer = (
  state = {
    status: '',
    alert: ERROR_CONSTS.UI.GENERIC.EMPTY_ALERT,
    data: {},
    type: GET_ULTIMATE_SEARCH_RESULTS,
  },
  action = {}
) => {
  switch (action.type) {
    case CLEANUP_SEARCH_RESULTS:
      return initialState;
    case GET_ULTIMATE_SEARCH_RESULTS:
      
      return {
        ...state,
        searchResults: {
          status: action.payload.status,
          alert: action.payload.alert,
          data: action.payload.data,
          type: GET_ULTIMATE_SEARCH_RESULTS,
        },
      };
    default:
      return state;
  }
};