import {
  getAssignApplicationState,
  getNavSearchResults,
  getNotifyAdminState,
  getClearNotifyAdminState,
  getReassignApplicationState,
  getReportConflictState,
  getRestartApplicationState,
  getSaveReviewState,
  getSubmitRecommendationState,
  getTrainingState,
  getUnassignApplicationState,
  getUpdatePriorityState,
  getUnassignTraineeState,
} from '../../../../Common/redux/selectors';
import { OVERRIDE_RECOMMENDATION } from '../../../../actions-index';
const { useSelector } = require('react-redux');

const useGetSelector = (props = {}) => {
  const { type } = props;
  let state;
  const selectorMap = {
    SAVE_REVIEW: getSaveReviewState,
    SUBMIT_RECOMMENDATION: getSubmitRecommendationState,
    ASSIGN_APPLICATION: getAssignApplicationState,
    UNASSIGN_APPLICATION: getUnassignApplicationState,
    RESTART_APPLICATION: getRestartApplicationState,
    NOTIFY_ADMIN: getNotifyAdminState,
    CLEAR_NOTIFY_ADMIN: getClearNotifyAdminState,
    REASSIGN_APPLICATION: getReassignApplicationState,
    REPORT_CONFLICT: getReportConflictState,
    FETCH_SEARCH_RESULTS: getNavSearchResults,
    CREATE_TRAINING: getTrainingState,
    UNASSIGN_TRAINEE: getUnassignTraineeState,
    ASSIGN_TRAINEE: getTrainingState,
    UPDATE_PRIORITY: getUpdatePriorityState,
  };

  state = useSelector((state) => {
    if (type === OVERRIDE_RECOMMENDATION) {
      return;
    } else if (type) {
      return selectorMap[type](state);
    } else {
      return;
    }
  });

  return state;
};

export default useGetSelector;
