export const getStudentState = (state) => state?.studentData?.student
export const getApplicationDocumentsState = (state) => state?.studentData?.applicationDocuments
export const getDocumentFilesState = (state) => state?.studentData?.documentFiles
export const getSaveReviewState = (state) => state?.submitDetailsReducer?.saveReview
export const getSubmitRecommendationState = (state) => state?.submitDetailsReducer?.submitRecommendation
export const getAssignApplicationState = (state) => state?.assignApplicationReducer?.assignApplication
export const getUnassignApplicationState = (state) => state?.unassignApplicationReducer?.unassignApplication
export const getRestartApplicationState = (state) => state?.restartApplicationReducer?.restartApplication
export const getNotifyAdminState = (state) => state?.notifyAdminReducer?.notifyAdmin
export const getClearNotifyAdminState = (state) => state?.clearNotifyAdminReducer?.clearNotifyAdmin
export const getReassignApplicationState = (state) => state?.reassignApplicationReducer?.reassignApplication
export const getReportConflictState = (state) => state?.reportConflictReducer?.reportConflict
export const getCommitteeReviewersState = (state) => state?.committeeReviewerReducer?.reviewers
export const getUserCognitoGroups = (state) => state?.authentication?.user?.cognitoGroups
export const getNavSearchResults = (state) => state?.navbarSearchReducer?.searchResults
export const getNavSearchValue = (state) => state?.navbarSearchReducer?.searchValue
export const getCommitteeReviewersFromDBState = (state) => state?.manageReviewersReducer?.reviewers
export const getCommitteeReviewersActiveFromDBState = (state) => state?.manageActiveReviewersReducer?.reviewers
export const getBulkAssignState = (state) => state?.manageReviewersReducer?.bulkAssign
export const getActiveReviewerState = (state) => state?.manageActiveReviewersReducer?.reviewerStatus
export const getUpdateManageReviewerState = (state) => state?.updateManageReviewerReducer?.updateReviewer
export const getManageReviewerState = (state) => state?.ManageReaderReducer?.reviewer
export const getDashBoardState = (state) => state?.DashBoardReducer?.committeeList 
export const getNextAppState = (state) => state?.NextAppReducer?.nextApp
export const getOverrideRecommendationState = (state) => state?.overrideRecommendationReducer?.overrideRecommendation
export const getReportsState = (state) => state?.reportsReducer?.report
export const getExportReportsState = (state) => state?.reportsReducer?.exportReportMessage;
export const getReportAthenaCustomState = (state) => state?.athenaReportReducer
export const getTrainingState = (state) => state?.createTrainingReducer?.createTraining
export const getUpdatePriorityState = (state) => state?.updatePriorityReducer?.updatePriority
export const getCustomReportState = (state) => state?.reportsDashReducer?.customReports
export const getUnassignTraineeState = (state) => state?.unassignTraineeReducer?.unassignTrainee

export const selectorMap = {
    SAVE_REVIEW: getSaveReviewState,
    SUBMIT_RECOMMENDATION: getSubmitRecommendationState,
    ASSIGN_APPLICATION: getAssignApplicationState,
    UNASSIGN_APPLICATION: getUnassignApplicationState,
    RESTART_APPLICATION: getRestartApplicationState,
    NOTIFY_ADMIN: getNotifyAdminState,
    CLEAR_NOTIFY_ADMIN: getClearNotifyAdminState,
    REASSIGN_APPLICATION: getReassignApplicationState,
    REPORT_CONFLICT: getReportConflictState,
    CREATE_TRAINING: getTrainingState,
    UPDATE_PRIORITY: getUpdatePriorityState,
    CUSTOM_REPORTS: getCustomReportState,
    UNASSIGN_TRAINEE: getUnassignTraineeState,
  };