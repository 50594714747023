import {
  GET_REPORT,
  GET_CUSTOMFILTEROPTIONS,
  EXPORT_RECOMMENDATION_REPORT,
  EXPORT_ADMINREVIEW_REPORT,
  RESET_REPORTSALERTS,
  RESET_REPORTSFILTERALERTS,
  EXPORT_REPORT_MESSAGE,
} from '../../../actions-index';
import { ERROR_CONSTS, STATUS } from '../../../util/constants';

const initialState = {
  status: STATUS.LOADING,
  alert: ERROR_CONSTS.UI.GENERIC.EMPTY_ALERT,
  data: { rows: [], columns: [], filterOptions: [] },
};

export const reportsReducer = (
  state = {
    report: {
      status: STATUS.LOADING,
      alert: ERROR_CONSTS.UI.GENERIC.EMPTY_ALERT,
      data: {
        rows: [],
        filterOptionsOriginal: [],
        filterOptionsCustom: [],
      },
      type: GET_REPORT,
    },
    exportStatus: {
      status: '',
      alert: ERROR_CONSTS.UI.GENERIC.EMPTY_ALERT,
    },
  },
  action = {}
) => {
  switch (action.type) {
    case GET_REPORT: {
      if (action.payload.timeStamp <= state.report.timeStamp) { return state; }
      
      const rows = getRows(state.report.data.rows, action.payload);
      const columns = action.payload.data?.columns ?? [];
      const { filterOptions, filterOptionsOriginal } = getFilterOptionsFromPayload(action.payload.data, state.report.data.filterOptionsCustom);
      const lastEvaluatedKey = action.payload.data?.lastEvaluatedKey;

      return {
        ...state,
        report: {
          ...initialState,
          status: action.payload.status,
          alert: action.payload.alert,
          timeStamp: action.payload.timeStamp,
          data: {
            ...state.report.data,
            rows,
            columns,
            filterOptions,
            filterOptionsOriginal,
            lastEvaluatedKey,
          },
          lastKey: action.payload.lastKey,
          type: GET_REPORT,
        },
      };
    }

    case GET_CUSTOMFILTEROPTIONS: {
        const filterOptionsCustom = getSafeCustomFilters(action.payload.data);

        let newState = {
            ...state,
            report: {
                type: GET_REPORT,
                ...state.report,
                data: {
                    ...state.report.data,
                    filterOptions: [ ...(state.report.data.filterOptionsOriginal ?? []), ...(filterOptionsCustom ?? [])],
                    filterOptionsCustom: filterOptionsCustom ?? state.report.data.filterOptionsCustom ?? [],
                    filterOptionsCustomStatus: action.payload.status,
                    isLoadingCustomFilters: action.payload.status === STATUS.LOADING,
                    customFiltersErrorAlert: action.payload.alert,
                }
            }
        };

        return newState;
    }

    case RESET_REPORTSALERTS:
    case RESET_REPORTSFILTERALERTS:
    {
        let resetReportAlerts = action.payload?.status !== STATUS.ERROR; // See AlertBar.js and useAlertBarStatus.js
        let alertAndStatusReset = {}, customFiltersAlertAndMessageReset = {};

        if (resetReportAlerts && action.type === RESET_REPORTSALERTS) {
            alertAndStatusReset.status = action.payload.status;
            alertAndStatusReset.alert = action.payload.alert;
        }
  
        if (resetReportAlerts && action.type === RESET_REPORTSFILTERALERTS) {
          customFiltersAlertAndMessageReset.filterOptionsCustomStatus = state?.data?.isLoadingCustomFilters ? state?.data?.filterOptionsCustomStatus : STATUS.SUCCESS;
          customFiltersAlertAndMessageReset.customFiltersErrorAlert = undefined;
        }

        return {
            ...state,
            report: {
              ...state?.report,
              ...alertAndStatusReset,
              data: {
                ...state?.report.data,
                ...customFiltersAlertAndMessageReset
              },
            },
          };
    }

    case EXPORT_RECOMMENDATION_REPORT: {
      return {
        ...state,
        exportStatus: {
          ...initialState,
          status: action.payload.status,
          alert: action.payload.alert,
          type: EXPORT_RECOMMENDATION_REPORT,
        },
      };
    }

    case EXPORT_ADMINREVIEW_REPORT: {
      return {
        ...state,
        exportAdminReviewsStatus: {
          ...initialState,
          status: action.payload.status,
          alert: action.payload.alert,
          type: EXPORT_ADMINREVIEW_REPORT,
        },
      };
    }
    
    case EXPORT_REPORT_MESSAGE: {
        return {
            ...state,
            exportReportMessage: {
                status: action.status ?? STATUS.SUCCESS,
                alert: {
                    MESSAGE: action.message,
                },
                downloadComplete: action.downloadComplete ?? true,
            },
        }
    }

    default:
      return state;
  }
};

function getRows(currentRows, { data, status }) {
    if ([STATUS.CLEAR].includes(status)) { return []; }
    
    const rowsAsJsonMaps = data?.rowsAsJsonMaps ?? [];
    
    const newRowsFromPayload = rowsAsJsonMaps.map(rowAsJsonMap => {
        let row = JSON.parse(rowAsJsonMap);
        row.id = `${row.applicantEmployeeId}#${row.committeeId}#${row.term}`;

        // Need unique ids in datagrid per reviewer for past due report
        if (row.readerEmployeeId) { row.id = `${row.readerEmployeeId}#${row.id}`; }
        if (row.completedByDueDate) { row.id = `${row.completedByDueDate}#${row.id}`; } // TODO Should we do this by reportType instead?
        return row;
    });
    
    return [ ...currentRows, ...newRowsFromPayload ];
}

function getFilterOptionsFromPayload(payload, filterOptionsCustom) {
    let filterOptions = [], filterOptionsOriginal = [];
    const filterOptionsAsJsonArr = payload?.filterOptionsAsJsonArr;
    
    if (typeof filterOptionsAsJsonArr === "string") {
        filterOptions = JSON.parse(filterOptionsAsJsonArr);
        filterOptionsOriginal = JSON.parse(filterOptionsAsJsonArr);
        if (Array.isArray(filterOptions)) { filterOptions.push(...(filterOptionsCustom ?? [])); } // Only push if it's an array
    }
    
    return { filterOptions, filterOptionsOriginal };
}

function getSafeCustomFilters(str) {
    if (!str) { return; }

    try {
        return JSON.parse(str);
    } catch (exception) {
        console.error("Error while parsing custom filters from str", str, exception);
    }

    return [];
}
