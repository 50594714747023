import {
  ACTIVATE_REVIEWERS,
  BULK_ASSIGN,
  FETCH_ALL_REVIEWERS,
  UPDATE_REVIEWER_GOALS
} from '../actions-index';
import { ERROR_CONSTS } from '../util/constants';
import { sortByAttribute } from '../util/functions';

const initialState = {
  status: '',
  alert: ERROR_CONSTS.UI.GENERIC.EMPTY_ALERT,
};

export const manageReviewersReducer = (
  state = {
    reviewers: { ...initialState, data: [], type: FETCH_ALL_REVIEWERS },
    bulkAssign: { ...initialState, data: [], type: BULK_ASSIGN },
    reviewerStatus: { ...initialState, data: null, type: ACTIVATE_REVIEWERS },
  },
  action = {}
) => {
  switch (action.type) {
    case FETCH_ALL_REVIEWERS: {
      let reviewers = action.payload.data;
      return {
        ...state,
        reviewers: {
          status: action.payload.status ?? '',
          alert: action.payload.alert ?? '',
          data: reviewers ? sortByAttribute(reviewers, 'lastName') : [],
          type: FETCH_ALL_REVIEWERS,
        },
      };
    }
    case BULK_ASSIGN:
      return {
        ...state,
        bulkAssign: {
          status: action.payload.status ?? '',
          alert: action.payload.alert ?? '',
          data: action.payload.data ?? null,
          type: BULK_ASSIGN,
        },
      };
    case UPDATE_REVIEWER_GOALS:
      return {
        ...state,
        reviewers: {
          ...state.reviewers,
          data: state.reviewers.data.map((item) => {
            const reviewer = action.payload.reviewers.find((reviewer) => {
              return item.reviewerEmployeeId === reviewer.reviewerEmployeeId;
            });
            return reviewer ? { ...item, quota: reviewer.quota } : item;
          }),
        },
      };
    default:
      return state;
  }
};
