import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from './reducers-index';

let composeEnhancers = null;
if (process.env.NODE_ENV === 'development' && window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  composeEnhancers =
    window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true, // (action) => { return ‘trace as string’; }
      traceLimit: 25,
    }) || compose;
} else {
  composeEnhancers = compose;
}


export default function configureStore(initialState) {
  let middlewares = [thunk];
  if (process.env.NODE_ENV === `development`) {
    middlewares.push(logger);
  }

  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
    // applyMiddleware(...middlewares)
  );
}
