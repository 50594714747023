import { API } from 'aws-amplify';
import { FETCH_ALL_REVIEWERS, UPDATE_MANAGE_REVIEWER, UPDATE_REVIEWER_GOALS } from '../actions-index';
import { SEVERITY_LEVELS, STATUS } from '../util/constants';
import { updateManageReviewerMutation } from './mutation';
import { getCommitteeReviewersFromDB } from './query';

export const getCommitteeReviewers = ({ committeeId, createdDate }) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_ALL_REVIEWERS,
        payload: {
          status: STATUS?.LOADING,
        },
      });

      const response = await API.graphql({
        query: getCommitteeReviewersFromDB,
        variables: {
          committeeId,
          createdDate,
        },
      });

      if (response) {
        dispatch({
          type: FETCH_ALL_REVIEWERS,
          payload: {
            status: STATUS?.SUCCESS,
            data: response?.data?.getCommitteeReviewersFromDB,
          },
        });
      } else {
        dispatch({
          type: FETCH_ALL_REVIEWERS,
          payload: {
            status: STATUS?.ERROR,
            alert: {
              MESSAGE:
                response?.data?.getCommitteeReviewersFromDB?.error?.message,
              SEVERITY: SEVERITY_LEVELS?.ERROR,
            },
          },
        });
      }
    } catch (error) {
      dispatch({
        type: FETCH_ALL_REVIEWERS,
        payload: {
          status: STATUS.ERROR,
          alert: {
            MESSAGE: error?.errors[0]?.message,
            SEVERITY: SEVERITY_LEVELS?.ERROR,
          },
        },
      });
    }
  };
};

export const bulkAssign = (reviewers) => {
  let response;

  return async (dispatch) => {
    dispatch({
      type: UPDATE_MANAGE_REVIEWER,
      payload: { status: STATUS?.LOADING },
    });
    try {
      response = await API.graphql({
        query: updateManageReviewerMutation,
        variables: { input: reviewers },
      });

      if (
        response?.data?.updateManageReviewer?.status?.toUpperCase() ===
        STATUS?.SUCCESS
      ) {
        dispatch({
          type: UPDATE_MANAGE_REVIEWER,
          payload: {
            status: STATUS?.SUCCESS,
            alert: {
              MESSAGE: response?.data?.updateManageReviewer?.details,
              SEVERITY: SEVERITY_LEVELS?.SUCCESS,
            },
          },
        });
        dispatch({
          type: UPDATE_REVIEWER_GOALS,
          payload: {
            reviewers: reviewers,
          },
        });
      } else {
        dispatch({
          type: UPDATE_MANAGE_REVIEWER,
          payload: {
            status: STATUS?.ERROR,
            details: '',
            alert: {
              MESSAGE: response?.data?.updateManageReviewer?.error?.message,
              SEVERITY: SEVERITY_LEVELS?.ERROR,
            },
          },
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_MANAGE_REVIEWER,
        payload: {
          status: STATUS?.ERROR,
          alert: {
            MESSAGE: error?.errors[0]?.message,
            SEVERITY: SEVERITY_LEVELS?.ERROR,
          },
        },
      });
    }
  };
};
