export const onCommitteeChanges = `subscription OnCommitteeChanges($committeeId: String!) {
    onCommitteeChanges(committeeId: $committeeId) {
        committeeId
        allTotal
        earlyActionTotal
        onTimeTotal
        all1
        all2
        all3
        all4
        earlyAction1
        earlyAction2
        earlyAction3
        earlyAction4
        onTime1
        onTime2
        onTime3
        onTime4
    }
}`;
export const onReviewerChanges = `subscription OnReviewerChanges($reviewerEmployeeId: String!) {
    onReviewerChanges(reviewerEmployeeId: $reviewerEmployeeId) {
        committeeId
        lastAssignedDate
        pending
        reviewerEmployeeId
        submitted
        total
      }
    }
`;    