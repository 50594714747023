export const NOTES_STATES_CASES = {
    ADD: "ADD",
    RESET: "RESET",
    SET_RESTRICTIONS: "SET_RESTRICTIONS",
};

export function formsReducer(state = [], action) {
    switch (action.type) {
      case "RESET": {
        return [];
      }
      case "ADD": {
        const { payload = [], section = "" } = action;
        
        let newState = [];
        for(let item of payload) {
            newState.push({ label: item.label, value: false, section: section, parentLabel: item.parentLabel})
        }

        newState = [
            ...newState,
            ...state
        ]
        return newState;
        
      }
      case NOTES_STATES_CASES.SET_RESTRICTIONS: {
        const newState = [...state]; // Shallow clone       

        // Reformat for exportStringToArray & push into state or replace existing
        Object.keys(action?.payload?.state ?? {}).forEach(noteKey => {
          const newCheckItemState = {
            label: noteKey,
            value: action?.payload.state[noteKey]?.value ?? false,
            section: "restrictions",
            hasChildren: action?.payload.state[noteKey]?.hasChildren ?? false,
          }

          // replace if note exists, otherwise push new
          const existingItem = newState.find((item) => item.label === noteKey)

          if (existingItem) {
            const itemIndex = newState.indexOf(existingItem)
            newState[itemIndex] = newCheckItemState
          } else {
            newState.push(newCheckItemState);
          }
        });

        return newState;
      }
      
      default: {  
        const name = action.target.name;
        const parentLabel = state.find(item => item.label === name)?.parentLabel;
        const index = state.findIndex(item => item.label === name && item.section === action.section);
        if (index === -1) { return [...state]; } // Bail if we can't find the option
        let value;
        
        switch (action.target.type) {
          case "checkbox":
            value = action.target.checked;

            //if parentLabel is null and value is false then we need to uncheck everything that has this as the parentLabel
            if (parentLabel === null && value === false) {
              for (const item of state) {
                if (item.parentLabel === name) {
                  item.value = false;
                }
              }
            }
            break;
          default:
            value = action.target.value;
  
            break;
        }
  

        let newState = [...state];
        newState[index] = {
          ...newState[index],
          value,
        };
  
        return newState;
      }
    }
  }

  export function validate(state) {

    let parents = state.filter((item) => item.parentLabel === null && item.value === true);

    let validParents = [];
    for(let parent of parents) {
      //do anything have this as a parent?
      let children = state.filter((item) => item.parentLabel === parent.label);
      if(children.length > 0) {
        validParents.push(parent);
      }
    }

    for(let parent of validParents) {
      let children = state.filter((item) => item.parentLabel === parent.label && item.value === true);
      if(children.length === 0) {
        // console.log("no children for parent: ", parent.label);
        return false;
      }
    }
    return true;
  }
  
  export function exportStringArray(state, section) {
    let stringArray = [];
    for(let item of state) {
      if(item.section === section && item.value === true)
      stringArray.push(item.label);
    }
    return stringArray;
  }

  export function exportActionVariable(state, section) {
    let arr = [];
    if (section === 'evaluation') {
      for (let item of state) {
        if (item.section === 'evaluation') {
          arr.push({parentLabel: item.parentLabel, value: item.value})
        }
      }
    } else if (section === 'document') {
      for (let item of state) {
        if (item.section === 'document') {
          arr.push({parentLabel: item.parentLabel, value: item.value})
        }
      }
    }
    
    return arr;
  }

export function sectionIsDisabled(allPossibleCheckboxes, checkboxesState, section, isChecked) {
    return !isChecked && sectionIsFull(allPossibleCheckboxes, checkboxesState, section); // Allow checkbox to be unchecked
}

function sectionIsFull(allPossibleCheckboxes, checkboxesState, section) {
    let attributeNotesChecked = [];
    
    if (section === "competitive") {
        const competitiveSimple = allPossibleCheckboxes?.competitive?.map?.(checkbox => checkbox.label) ?? [];
        attributeNotesChecked = checkboxesState.filter(checkbox => checkbox.section === section && competitiveSimple.includes(checkbox.label) && checkbox.value === true)
    } else if (section === "noncompetitive") {
        const noncompetitiveSimple = allPossibleCheckboxes?.noncompetitive?.map?.(checkbox => checkbox.label) ?? [];
        attributeNotesChecked = checkboxesState.filter(checkbox => checkbox.section === section && noncompetitiveSimple.includes(checkbox.label) && checkbox.value === true);
    } else {
        return false; // Only competitive/noncompetitive can be disabled by being full
    }
    
    return attributeNotesChecked.length >= 3;
}