import { GET_REPORT_ATHENA, GET_REPORT_ATHENA_ERROR, RESET_REPORT_ATHENA_COUNT, GET_REPORT_ATHENA_CHANGE_REPORTID } from '../actions-index';
import { ERROR_CONSTS } from '../util/constants';
import { STATUS } from '../util/constants';

export default function athenaReportReducer(state, action) {
    const isLoading = action?.payload?.status === STATUS.LOADING;
    
    switch (action.type) {
        case GET_REPORT_ATHENA: {
            // Handle when the user clears an error popup gracefully (previously was clearing all data on the screen)
            if (action?.payload?.status === null && action.payload.alert?.MESSAGE === "" && action.payload.alert.SEVERITY === null && action.payload.details === "") {
                return {
                    ...state,
                    status: action.payload.status ?? "",
                    alert: action.payload.alert ?? ERROR_CONSTS.UI.GENERIC.EMPTY_ALERT,
                };
            }

            const columnDefinitions = getColumnDefinitions(action);
            
            return {
                type: GET_REPORT_ATHENA,
                status: action.payload.status ?? "",
                isGettingNextPage: action.payload.isGettingNextPage ?? state.isGettingNextPage,
                isLoading,
                alert: action.payload.alert ?? ERROR_CONSTS.UI.GENERIC.EMPTY_ALERT,

                applications    : getApplications(state, action, isLoading, columnDefinitions),
                columns         : columnDefinitions,
                count           : getCount(state, action),
                filterOptions   : getFilterOptions(state, action, isLoading),
                queryExecutionId: getQueryExecutionId(state, action),
                nextToken       : getNextToken(state, action),
                reportName      : getReportName(state, action),
                errorCount      : !isLoading ? 0 : state.errorCount ?? 0,
            };
        }
        
        case GET_REPORT_ATHENA_ERROR: {
            return {
                ...state,
                type: GET_REPORT_ATHENA,
                status: action.payload.status ?? "",
                isGettingNextPage: action.payload.isGettingNextPage ?? state.isGettingNextPage,
                isLoading,
                alert: action.payload.alert ?? ERROR_CONSTS.UI.GENERIC.EMPTY_ALERT,
                errorCount: (state.errorCount ?? 0) + 1
            };
        }

        case RESET_REPORT_ATHENA_COUNT: {
            return {
                ...state,
                count: 0
            };
        }

        case GET_REPORT_ATHENA_CHANGE_REPORTID: { state = undefined; } // Fall through to default below
        default: return state ?? {
            type: GET_REPORT_ATHENA,
            applications: [],
            columns: [],
            count: 0,
            filterOptions: [],
            isLoading: true,
            queryExecutionId: [],
            nextToken: [],
            status: STATUS.LOADING,
            errorCount: 0,
        };
    }
}

function getNextToken(state, action) { return action.payload?.data?.nextToken ?? undefined; }
function getQueryExecutionId(state, action) { return action.payload?.data?.queryExecutionId ?? undefined; }
function getReportName(state, action) { return action.payload?.data?.reportName ?? state.reportName; }

function getApplications(state, action, isLoading, columnDefinitions) {
    if (isLoading) { return state.applications; }
    
    let applications = [];
    
    // Prepend the rows we currently have
    if (state.isGettingNextPage) { applications.push(...clone(state.applications ?? [])); }

    if (action.payload?.data?.applications) {
        applications.push(...action.payload.data.applications);
    }

    const columnKeys = getKeys(columnDefinitions);
    applications.forEach(expandColumnsFromDataAsJson.bind(columnKeys));

    return applications;
}

function getKeys(columnDefinitions) {
    const defaultKeys = [
        "applicantemployeeid",
        "committeeid",
        "term",
    ];

    if (!Array.isArray(columnDefinitions)) {
        // Default keys if none provided
        return defaultKeys;
    }

    const keys = columnDefinitions.filter(column => column?.isKey && typeof column.field === "string").map(column => column.field);
    return keys.length > 0 ? keys : defaultKeys;
}

function getCount(state, action) {
    const { isGettingNextPage } = action?.payload ?? {};
    if (isGettingNextPage) { return state?.count ?? 0; }

    return isNaN(action?.payload?.data?.count) ? 0 : (parseFloat(action?.payload?.data?.count) ?? state?.count ?? 0);
}

function clone(o) { return JSON.parse(JSON.stringify(o)); }

function expandColumnsFromDataAsJson(row) {
    try {
        if (row.id) { return; }
        const keys = this;
        row.id = getIdFromKeys(row, keys);

        const dataAsJsonMap = row.dataAsJsonMap;
        delete row.dataAsJsonMap;

        if (typeof dataAsJsonMap !== "string") { return; }

        const extraDataObj = JSON.parse(dataAsJsonMap);
        
        for (let key of Object.keys(extraDataObj)) {
            row[key] = extraDataObj[key];
        }

        row.id = getIdFromKeys(row, keys); // Re-do, now that we have more data
    } catch (exception) {
        console.error("Error while pulling dataAsJson", exception);
    }
}

function getIdFromKeys(row, keys) {
    return keys.map(key => row?.[key]).join("_");
}

function getColumnDefinitions(action) {
    const applications = action.payload?.data?.applications ?? [];
    let defaultColumnDefinitions = Object.keys(applications[0] ?? {})
        .map(columnKey => ({ field: columnKey, headerName: columnKey, width: "250" }))
        .filter(column => column.field !== "id");

    if (typeof action.payload?.data?.columnMappingsAsJson !== "string") { return defaultColumnDefinitions; }

    try {
        const mappings = JSON.parse(action.payload?.data?.columnMappingsAsJson);
        let orderedColumnDefs = Object.entries(mappings).map(entry => {
            return {
                field       : getUnwrappedColumnName(entry[0]),
                headerName  : getSafeHeaderName(entry[1], entry[0]),
                width       : entry[1]?.width ?? "250",
                isKey       : entry[1]?.isKey ?? false,
                isHidden    : entry[1]?.isHidden ?? false,
            };
        });

        return orderedColumnDefs;
    }  catch (exception) {
        console.error(`Error while parsing columnMappings=[${action.payload?.data?.columnMappingsAsJson}]`, exception);
    }

    return defaultColumnDefinitions;
}

function getSafeHeaderName(metadata = {}, key) {
    let headerName = (metadata.headerName?.length > 0 ? metadata.headerName : key) ?? key;
    return getUnwrappedColumnName(headerName);
}

// returns xyz if headerName is abc."xyz"
function getUnwrappedColumnName(headerName) {
    if (!headerName.includes('.')) { return headerName; }

    // Split and return fieldname from tablename."fieldname"
    if (headerName.includes(`."`)) {
        headerName = headerName.split('."')[1];
        return headerName.slice(0, -1);
    }
    
    // Split and return fieldname from tablename.fieldname
    return headerName.split('.')[1];
}

function getFilterOptions(state, action, isLoading) {
    if (isLoading && state.filterOptions) { return state.filterOptions; }
    const filtersAsJson = action.payload?.data?.filtersAsJson;

    try {
        if (typeof filtersAsJson === "string") {
            const allFilters = JSON.parse(filtersAsJson);
            const filtersWithoutPrefilters = allFilters.filter(filter => filter.isPrefilter !== true);
            return filtersWithoutPrefilters;
        }
    } catch (exception) {
        console.error(`Error while parsing filter options for ${filtersAsJson}`, exception);
    }

    return [];
}