import React, { useContext, useState, Fragment, useEffect } from "react";
import {
  PageHeading,
  withPageHeading,
  TableOfContents,
  Mui,
  TOC,
} from "@osu/react-ui";
import Routes from "../../Routes/components";
import { useLocation, useHistory } from "react-router-dom";
import BreadcrumbTrail from "../../Common/components/BreadcrumbTrail";
import ApplicantAppBar from "../../Applicant/components/appBar";
import Navbar from "../../Navbar/containers";
import useRoleAuthentication from "../../Common/hooks/useRoleAuthentication";


const useStyles = Mui.makeStyles((theme) => {
  return {
    root: {
      [theme.breakpoints.up("md")]: {
        display: "flex",
        flexDirection: "row-reverse",
      },
      minHeight: "calc(100vh - var(--nav-height) - var(--footer-height))",
    },
    toc: {
      zIndex: theme.zIndex.appBar - 2,
    },
  };
});

function Main(props = {}) {
  const { className, unauthorized, loading, spacingTop, paths, ...rest } =
    props;
  const classes = useStyles();
  const location = useLocation();
  const { exists } = useContext(TOC.Context);
  let history = useHistory();
  const authDetails = useRoleAuthentication();

  useEffect(() => {
    if (authDetails?.HAS_ROLES === false && !location.pathname.includes("/unauthorized")) {
      history.push("/unauthorized");
    }
  }, [authDetails, history])


  const [pageAppHeader, setPageAppHeader] = useState({ isLoaded: false });
  const [containerSize, setContainerSize] = useState("xl");
  
  return (
    <Fragment>
        {authDetails?.HAS_ROLES && <Navbar>
          {(location.pathname.includes("/applicant") || location.pathname.includes("/view") || location.pathname.includes("/training")) && ( <ApplicantAppBar pageAppHeader={pageAppHeader} /> )}
        </Navbar>}
      <Mui.Box
        flexGrow={100}
        className={`${className} ${classes.root}`.trim()}
        {...rest}
      >
        <Mui.Hidden smDown>
          <TableOfContents
            spacingTop={false}
            PaperProps={{
              className: classes.toc,
            }}
            StylizedLinkProps={{ color: "primary" }}
          />
        </Mui.Hidden>
        <Mui.Container className="margin-y-4" maxWidth={containerSize}>
          <Mui.Hidden smDown>
            <BreadcrumbTrail />
          </Mui.Hidden>
          <Mui.Hidden smDown>
            <PageHeading variant="h3" />
          </Mui.Hidden>
          <Mui.Hidden mdUp>
            <header className="display-flex">
              <PageHeading variant="h3" />
            </header>
            {exists && <TableOfContents variant="popover" />}
          </Mui.Hidden>
          <Routes setPageAppHeader={setPageAppHeader} setContainerSize={setContainerSize}/>
        </Mui.Container>
      </Mui.Box>
    </Fragment>
  );
}

Main.defaultProps = {
  className: "",
  tabIndex: "-1",
  component: "main",
};

export default withPageHeading(Main);
