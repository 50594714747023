export const getApplication = `query GetApplication($applicantEmployeeId: String!, $admitTerm: String, $committeeId: String) {
  getApplication(applicantEmployeeId: $applicantEmployeeId, admitTerm: $admitTerm, committeeId: $committeeId) {
    status
    data {
      applicantEmployeeId
      firstName
      lastName
      committee {
        key
        value
      }
      admitTerm {
        key
        value
      }
    }
  }
}
`;

export const getApplicationSLTReadOnly = `query GetApplication($applicantEmployeeId: String!, $admitTerm: String, $committeeId: String) {
  getApplication(applicantEmployeeId: $applicantEmployeeId, admitTerm: $admitTerm, committeeId: $committeeId) {
    status
    data {
      applicantEmployeeId
      firstName
      lastName
      committee {
        key
        value
      }
      admitTerm {
        key
        value
      }
    }
  }
}
`;

export const searchCommitteeReviewers = `query SearchCommitteeReviewers($searchString: String!) {
  searchCommitteeReviewers(searchString: $searchString) {
    data {
      campusId
      reviewerEmployeeId
      lastName
      firstName
    }
    error {
      message
      date
    }
  }
}
`;