import { STORAGEKEY_VISIBLEROWS, defaultVisibleRows, defaultVisibleColumns } from "./reportConstants";

export function resetResultsPage(dispatch) { setResultsPage(dispatch, 0); }
export function setColumnOptions(dispatch, payload) { dispatch({ type: "column_options", payload }); }
export function setColumns(dispatch, payload) { dispatch({ type: "columns_to_show", payload }); }
export function setCurrentLimit(dispatch, limit) { dispatch({ type: "set_limit", payload: limit }); }
export function setResultsPage(dispatch, pageNumber) { dispatch({ type: "set_results_page", payload: pageNumber }); }
export function setRowsPerPage(dispatch, rowsPerPage) { dispatch({ type: "set_rows_per_page", payload: rowsPerPage }); }
export function setReportType(dispatch, payload) { dispatch({ type: "set_report_type", payload }); }
export function setFilters(dispatch, payload) { dispatch({ type: "set_filters", payload }); }
export function setFilterOptions(dispatch, payload) { dispatch({ type: "set_filters_options", payload }); }
export function toggleShowAssigned(dispatch) { dispatch({ type: "toggle_assigned" }); }

export function INITIAL_PAGE_STATE(reportType) {
    const reportColumnStorageKey = `UGA-VISIBLE_COLUMNS-${reportType}`;
    const reportFilterStorageKey = `UGA-REPORT_FILTERS-${reportType}`;
    let columnsPulledFromStorage, filtersPulledFromStorage;
    
    try {
        columnsPulledFromStorage = JSON.parse(localStorage.getItem(reportColumnStorageKey) ?? "null");
        filtersPulledFromStorage = JSON.parse(localStorage.getItem(reportFilterStorageKey) ?? "{}");
    } catch(exception) {
        // Swallow error, below we'll just use defaults
    }

    return {
        columnOptions: [],
        columns: columnsPulledFromStorage ?? defaultVisibleColumns[reportType] ?? [],
        limit: 500,
        filterOptions: undefined,
        filters: filtersPulledFromStorage?.filters ?? {},
        filtersForApiCall: filtersPulledFromStorage?.filtersForApiCall ?? "{}",
        isLoadingData: true,
        reportColumnStorageKey,
        reportFilterStorageKey,
        reportType,
        resultsPage: 0,
        rowsPerPage: loadDefaultVisibleRows(),
        selectedApplications: [],
        showAssigned: false,
    }
}

export function DatagridReducer(state, action) {
    switch(action.type) {
        case "column_options": { return { ...state, columnOptions: action.payload }; }
        case "columns_to_show": {
            const columns = action.payload;
            localStorage.setItem(state.reportColumnStorageKey, JSON.stringify(columns)); // Save to storage for user
            return { ...state, columns };
        }

        case "set_filters_options": { 
            addOldFilters(state.filters, state.reportType, action.payload);
            return { 
                ...state, 
                filterOptions: action.payload 
            }; 
        }
        case "set_filters": {
            const filters = action.payload;
            const filtersForApiCall = getFiltersForApiCall(filters, state.filterOptions);
            const filterLabels = getFiltersLabelsToSave(filters, state.filterOptions);

            localStorage.setItem(state.reportFilterStorageKey, JSON.stringify({ filters, filtersForApiCall, filterLabels })); // Save to storage for user
            
            return {
                ...state,
                filters,
                filtersForApiCall
            };
        }

        case "set_limit": { return { ...state, limit: action.payload }; }
        case "set_results_page": { return { ...state, resultsPage: action.payload }; }
        case "set_rows_per_page": {
            saveVisibleRowsToStorage(action.payload);
            return { ...state, rowsPerPage: action.payload };
        }
        case "set_selected_applications": { return { ...state, selectedApplications: action.payload }; }
        case "toggle_assigned": { return { ...state, showAssigned: !state.showAssigned }; }

        // If we change reports, load the new reports options from storage
        case "set_report_type": {
            if (state.reportType === action.payload) { return state; }
            return INITIAL_PAGE_STATE(action.payload);
        }
        
        default: throw new Error();
    }
}

// Helper functions
//
function getFiltersForApiCall(filters, filterOptions) {
    let filtersClone = JSON.parse(JSON.stringify(filters)); // Clone

    // Loop through and convert types when necessary
    Object.keys(filtersClone ?? {}).forEach(filterKey => {
        const currentFilterOptions = filterOptions?.find(filterOption => filterOption.key === filterKey);
        if (!currentFilterOptions) { delete filtersClone[filterKey]; return; }
        if (currentFilterOptions.values.length === 0) { return; }

        switch(typeof currentFilterOptions.values[0]) {
            case "string":  { return; }
            case "boolean": { filtersClone[filterKey] = filtersClone[filterKey] === "true"; return; }
            case "number":  { filtersClone[filterKey] = parseFloat(filtersClone[filterKey]); return; }
            default:        { return; }
        }
    });
    
    return JSON.stringify(filtersClone);
}

function getFiltersLabelsToSave(filters, updatedFilterOptions) {
    let newSavedLabels = {}; // Initialize as an empty object
    Object.entries(filters)?.forEach(([filterKey, filterValue]) => { 
        const filterOption = updatedFilterOptions?.find(option => option.key === filterKey);
        if (filterOption) {
            const matchingOption = filterOption?.values.find(option => option.value === filterValue);
            if (matchingOption) {
                newSavedLabels = {
                    ...newSavedLabels,
                    [filterKey]: matchingOption.label
                };
            }
        }
    });
    return newSavedLabels;
}
function addOldFilters(filters, reportType, updatedFilterOptions) {
    Object.entries(filters)?.forEach(([filterKey, filterValue]) => {
      const savedLabelsFromStorage = localStorage.getItem(`UGA-REPORT_FILTERS-${reportType}`);
      const parsedLabels = savedLabelsFromStorage ? JSON.parse(savedLabelsFromStorage) : {};
      
      const filterOption = updatedFilterOptions?.find(option => option.key === filterKey);
      if (filterOption) {
        // Check if the value already exists in filterOption.values, if it doesn't, add it and disable it
        const valueExists = filterOption?.values.some(option => option.value === filterValue);
        if (!valueExists) {
          filterOption?.values.push({
            value: filterValue,
            label: parsedLabels?.filterLabels?.[filterKey] || filterValue,
            disabled: true
          });
        }
      }
    });
  }

// Rows per page logic
//
function loadDefaultVisibleRows() {
    let rowsPerPage = defaultVisibleRows;
    
    try {
        rowsPerPage = loadVisibleRowsFromStorage();
    } catch(error) {
        return rowsPerPage;
    }
    
    return rowsPerPage;
}

function loadVisibleRowsFromStorage() { 
    return JSON.parse(localStorage.getItem(STORAGEKEY_VISIBLEROWS) ?? JSON.stringify(defaultVisibleRows)); 
}

function saveVisibleRowsToStorage(rows) { 
    localStorage.setItem(STORAGEKEY_VISIBLEROWS, JSON.stringify(rows)); 
}