import { API } from 'aws-amplify';
// import { FETCH_MANAGE_APPLICATIONS_DATA, FETCH_ASSIGNABLE_APPLICATIONS, MANUAL_BATCH_ASSIGN_APPLICATIONS, FETCH_ASSIGNED_APPLICATIONS } from '../actions-index';
import { LIST_ADMISSIONS_CYCLES, SAVE_ADMISSIONS_CYCLE } from '../actions-index';
import { SEVERITY_LEVELS, STATUS } from '../util/constants';
import { listAdmissionsCyclesQuery } from './query';
import { saveNewAdmissionsCycleMutation } from './mutation';

export const listAdmissionsCycles = () => {
    return async (dispatch) => {
        const type = LIST_ADMISSIONS_CYCLES;

        try {
            dispatch({ type, payload: { status: STATUS.LOADING } });
            const query = listAdmissionsCyclesQuery;
            const response = await API.graphql({ query });

            if (response) {
                const payload = { status: STATUS.SUCCESS, data: response?.data?.listAdmissionsCycles };
                dispatch({ type, payload });
                return;
            }

            // Handle errors
            const errorPayload = {
                status: STATUS?.ERROR,
                alert: {
                    MESSAGE: response?.data?.listAdmissionsCycles?.error?.message,
                    SEVERITY: SEVERITY_LEVELS.ERROR,
                },
            };

            dispatch({ type, payload: errorPayload });
        } catch (error) {
            console.error(error, error?.errors?.[0]?.message);

            const errorPayload = {
                status: STATUS.ERROR,
                alert: {
                    MESSAGE: error?.errors?.[0]?.message,
                    SEVERITY: SEVERITY_LEVELS.ERROR,
                },
            };
            dispatch({ type, payload: errorPayload });
        }
    };
};

export const saveNewAdmissionsCycle = (terms, listAdmissionsCycles) => {
    return async (dispatch) => {
        const type = SAVE_ADMISSIONS_CYCLE;

        try {
            dispatch({ type, payload: { status: STATUS.LOADING } });

            const query = saveNewAdmissionsCycleMutation;
            const input = { input: { terms } };
            const variables = input;
            const response = await API.graphql({ query, variables });

            if (response && response?.data?.saveNewAdmissionsCycle?.status === "success") {
                const payload = {
                    status: STATUS.SUCCESS,
                    alert: {
                        MESSAGE: "Successfully saved new admission cycle",
                        SEVERITY: SEVERITY_LEVELS.SUCCESS,
                    },
                };
                dispatch({ type, payload });
                listAdmissionsCycles();
                return;
            }

            // Handle errors
            const errorPayload = {
                status: STATUS.ERROR,
                alert: {
                    MESSAGE: response?.data?.saveNewAdmissionsCycle?.error?.message,
                    SEVERITY: SEVERITY_LEVELS.ERROR,
                },
            };

            dispatch({ type, payload: errorPayload });
        } catch (error) {
            console.error(error, error?.errors?.[0]?.message);

            const errorPayload = {
                status: STATUS.ERROR,
                alert: {
                    MESSAGE: error?.errors?.[0]?.message,
                    SEVERITY: SEVERITY_LEVELS.ERROR,
                },
            };
            dispatch({ type, payload: errorPayload });
        }
    };
};