import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from '@material-ui/pickers';
import { Mui } from '@osu/react-ui';
import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useAlertHandling } from '../../../Common/hooks/useAlertHandling';
import {
  ASSIGN_APPLICATION,
  ASSIGN_TRAINEE,
  CLEAR_NOTIFY_ADMIN,
  CREATE_TRAINING,
  MENU_CHANGE, NOTIFY_ADMIN,
  OVERRIDE_RECOMMENDATION,
  REASSIGN_APPLICATION,
  RESTART_APPLICATION,
  SELECT_COMPLETEDBYDATE,
  SELECT_REVIEWER, SELECT_SECOND_REVIEWER,
  UNASSIGN_APPLICATION, UNASSIGN_TRAINEE,
  UPDATE_PRIORITY, fetchStudent
} from '../../../actions-index';
import { ERROR_CONSTS, STATUS } from '../../../util/constants';
import AutoComplete from './AutoComplete';
import Dialog from './Dialog';
import Menu from './Menu';
import ClearNotifyAdminDialogContent from './components/ClearNotifyAdminDialogContent';
import CreateTrainingDialogContent from './components/CreateTrainingDialogContent';
import NotifyAdminDialogContent from './components/NotifyAdminDialogContent';
import UnassignTraineeDialogContent from './components/UnassignTraineeDialogContent';
import useGetSelector from './hooks/useGetSelector';
import actionsReducer from './util';

const defaultState = {
  reviewer: '',
  reviewerTwo: '',
  completedByDate: getDefaultCompletedByDate(),
  dialog: {
    open: false,
    type: '',
    title: '',
    description: '',
    actions: [],
  },
};

function getDefaultCompletedByDate() {
    const date = new Date();
    const numberOfDaysInFuture = 7;
    date.setDate(date.getDate() + numberOfDaysInFuture);
    date.setHours(0, 0, 0, 0);
    return date;
}

const Actions = (props) => {
  const dispatch = useDispatch();
  const { pageAppHeader, isTraining } = props;
  let history = useHistory();
 

  const [actionState, setActionState] = useReducer(
    actionsReducer,
    defaultState
  );
  const { reviewer, secondReviewer, completedByDate, dialog } = actionState ?? {};
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [dialogIsOpen, setDialogOpen] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [dialogState, setDialogState] = useState(null);
  const [data, setData] = useState([]);
  const [localError, setLocalError] = useState(false);
  const alert = useAlertHandling({ state: dialogState, alert: dialogState?.alert} || null )

  const typeState = useGetSelector({ type: dialog?.type });
  const handleInitialDialogClose = () => {
    setReviewer('')
    setDialogOpen(false);
    setLocalError(false)
    setData([])
    if (dialog?.type === CREATE_TRAINING || dialog?.type === ASSIGN_TRAINEE) {
      dispatch({
        type: CREATE_TRAINING,
        payload: {
          ...dialogState,
          status: '',
          alert: dialogState?.status === STATUS?.SUCCESS ? dialogState?.alert : ERROR_CONSTS.UI.GENERIC.EMPTY_ALERT,
          allReviewers: true, 
          emplids: [],
          expectedRecommendation: '',
          details: '',
        },
      });
    } else if (dialog?.type === UNASSIGN_TRAINEE) {
      dispatch({
        type: UNASSIGN_TRAINEE,
        payload: {
          ...dialogState,
          status: '',
          alert: dialogState?.status === STATUS?.SUCCESS ? dialogState?.alert : ERROR_CONSTS.UI.GENERIC.EMPTY_ALERT,
          emplids: [],
          details: '',
        },
      });
    } else if (dialog?.type !== OVERRIDE_RECOMMENDATION) {
      dialogState?.status?.toUpperCase() !== STATUS?.SUCCESS &&
        dispatch({
          type: dialogState?.type,
          payload: {
            ...dialogState,
            status: null,
            alert: ERROR_CONSTS.UI.GENERIC.EMPTY_ALERT,
            details: '',
          },
        });
      }
    }
  const handleSuccessDialogClose = (type) => {
    setSuccessDialog(false);
    dispatch({
      type: dialogState?.type,
      payload: {
        status: null,
        alert: ERROR_CONSTS.UI.GENERIC.EMPTY_ALERT,
        details: '',
      },
    });
    
    // For the different types, look for DialogContent within this file and within actions.js; ignore SUBMIT_RECOMMENDATION, SAVE_REVIEW, NOTIFY_ADMIN (we redirect in those cases)
    const refreshPageDataTypes = [ASSIGN_APPLICATION, REASSIGN_APPLICATION, UNASSIGN_APPLICATION, RESTART_APPLICATION, CREATE_TRAINING, ASSIGN_TRAINEE, UNASSIGN_TRAINEE, UPDATE_PRIORITY, CLEAR_NOTIFY_ADMIN, RESTART_APPLICATION];
    if (refreshPageDataTypes.includes(type)) {
        dispatch(
            fetchStudent({
                queryKey: isTraining ? "getTraining" : "getApplication",
                committeeId: pageAppHeader?.committee,
                applicantEmployeeId: pageAppHeader?.emplId,
                admitTerm: pageAppHeader?.term?.key,
                recHistorySub: true,
                setRecHistorySub: null,
            })
        );
    }

    const stayOnApplicantPageTypes = [ASSIGN_APPLICATION, REASSIGN_APPLICATION, UNASSIGN_APPLICATION, RESTART_APPLICATION, UPDATE_PRIORITY, CLEAR_NOTIFY_ADMIN, CREATE_TRAINING, UNASSIGN_TRAINEE];
    if(!stayOnApplicantPageTypes.includes(type))
      history.push("/");
  };

  const { committeeName, recommendationHistory } = useSelector((state) => {
    return {
      committeeName: state?.studentData?.student?.data?.committeeDetails?.committeeName ?? "MISSING LABEL",
      recommendationHistory: state?.studentData?.student?.data?.recommendationHistory ?? [],
    };
  });

  useEffect(() => {
    if (dialogState !== typeState) {
      setDialogState(typeState)
    }
  }, [typeState]);

  useEffect(() => {
    if (
      dialogState?.status && dialogState?.status?.toUpperCase() === STATUS?.SUCCESS
    ) {
      handleInitialDialogClose();
      setSuccessDialog(true);
    }
    if (
      dialogState?.status?.toUpperCase() === STATUS?.CANCEL
    ) {
      handleInitialDialogClose();
    }
    
  }, [dialogState?.status, dispatch]);

  const DialogContent = () => {
    if (dialogState?.type === ASSIGN_APPLICATION) {
      return (
        <>
          <AutoComplete id="auto-complete-reviewer" reviewer={reviewer} setReviewer={setReviewer} type={dialog?.type} />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              className='a11y-button-reset'
              autoOk={true}
              disableToolbar
              inputVariant="outlined"
              variant="inline"
              format="MM/dd/yyyy"
              id="date-picker-inline"
              label="Complete by"
              value={completedByDate}
              minDate={new Date()}
              onChange={handleCompletedByDateChange}
              KeyboardButtonProps={{ 'aria-label': 'change date', }}
              style={{ marginTop: "1em", width: "100%" }}
              required
            />
          </MuiPickersUtilsProvider>
        </>
      );
    } else if (dialogState?.type === UNASSIGN_APPLICATION) {
      return (
        <AutoComplete
          id="auto-complete-reviewer"
          reviewer={reviewer}
          setReviewer={setReviewer}
          type={dialog?.type}
        />
      );
    } else if (dialog?.type === UNASSIGN_TRAINEE) {
      return <UnassignTraineeDialogContent  isTraining={isTraining} />;
    } else if (dialog?.type === NOTIFY_ADMIN) {
      return <NotifyAdminDialogContent data={data} setData={setData} localError={localError} setLocalError={setLocalError} />;
    } else if (dialog?.type === CLEAR_NOTIFY_ADMIN) {
      return <ClearNotifyAdminDialogContent recommendationHistory={recommendationHistory} />
    } else if (dialog?.type === REASSIGN_APPLICATION) {
      return (<>
        <Mui.Typography>From</Mui.Typography>
        <AutoComplete
          className={"margin-bottom-1"}
          id="auto-complete-reviewer"
          reviewer={reviewer}
          setReviewer={setReviewer}
          type={UNASSIGN_APPLICATION}
        />
        <Mui.Typography>To</Mui.Typography>
        <AutoComplete
          id="auto-complete-reviewer-two"
          reviewer={secondReviewer}
          setReviewer={setSecondReviewer}
          type={dialog?.type}
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            className='a11y-button-reset' 
            autoOk={true}
            disableToolbar
            inputVariant="outlined"
            variant="inline"
            format="MM/dd/yyyy"
            id="date-picker-inline"
            label="Complete by"
            value={completedByDate}
            minDate={new Date()}
            onChange={handleCompletedByDateChange}
            KeyboardButtonProps={{ 'aria-label': 'change date', }}
            style={{ marginTop: "1em", width: "100%" }}
            required
          />
        </MuiPickersUtilsProvider>
      </>)
    } else {
      return <></>;
    }
  };

  const handleMenuChange = ({ type }) => {
    setMenuAnchorEl(null);
    setActionState({
      type: MENU_CHANGE,
      payload: {
        menuType: type,
        pageAppHeader,
      },
    });
    setDialogOpen(true);
  };

  const setReviewer = (reviewer) => {
    setActionState({
      type: SELECT_REVIEWER,
      payload: reviewer,
    });
    setSubmitError(false);
  };

  const setSecondReviewer = (reviewer) => {
    setActionState({
      type: SELECT_SECOND_REVIEWER,
      payload: reviewer,
    });
    setSubmitError(false);
  };
  
  const handleCompletedByDateChange = completedByDate => {
    setActionState({
      type: SELECT_COMPLETEDBYDATE,
      payload: completedByDate,
    });
    setSubmitError(false);
  };

  return (
    <React.Fragment>
      <Menu
        selectMenuItem={handleMenuChange}
        menuAnchorEl={menuAnchorEl}
        setMenuAnchorEl={setMenuAnchorEl}
        isTraining={isTraining}
        pageAppHeader={pageAppHeader}
      />
      {dialog?.type && (
        <Dialog
          data={data}
          localError={localError}
          open={dialogIsOpen}
          onClose={handleInitialDialogClose}
          title={dialog.title}
          type={dialog.type}
          isTraining={isTraining}
          reviewer={reviewer}
          secondReviewer={secondReviewer}
          completedByDate={completedByDate}
          pageAppHeader={pageAppHeader}
          submitError={submitError}
          setSubmitError={setSubmitError}
          committeeName={committeeName}
          dialogState={dialogState}
          setDialogState={setDialogState}
          description={
            typeof dialog.description === 'function'
              ? dialog.description({
                committeeName,
                reviewer,
              })
              : dialog.description
          }
        >
          {[CREATE_TRAINING, ASSIGN_TRAINEE].includes(dialog?.type) ?
              <CreateTrainingDialogContent isTraining={isTraining} /> : <DialogContent />}
        </Dialog>
      )}
      {successDialog && (
        <Mui.Dialog
          open={successDialog}
          dialogState={dialogState}
          fullWidth
          maxWidth="sm"
        >
          {alert}
          <Mui.DialogContent>
            <Mui.DialogContentText id="alert-dialog-description">
              {dialogState?.alert?.MESSAGE}
            </Mui.DialogContentText>
          </Mui.DialogContent>
          <Mui.DialogActions className="padding-2">
            <Mui.Button onClick={() => handleSuccessDialogClose(dialogState?.type)}>
              Close
            </Mui.Button>
          </Mui.DialogActions>
        </Mui.Dialog>
      )}
    </React.Fragment>
  );
};

Actions.defaultProps = {
  reviewers: [],
};

export default Actions;
