export const listCustomReportsQuery = `
    query listCustomReportsQuery {
        listCustomReports {
            reports {
                reportId
                reportName
                reportDescription
                committeeIds
            }
            lastUpdatedTime
            status
            details
            error {
                message
                date
            }
        }
    }
`;