import {
  FETCH_ALL_REVIEWERS_ACTIVE,
  ACTIVATE_REVIEWERS,
  UPDATE_REVIEWERS_STATUS,
} from '../actions-index';
import { ERROR_CONSTS, STATUS } from '../util/constants';
import { sortByAttribute } from '../util/functions';

const initialState = {
  status: '',
  alert: ERROR_CONSTS.UI.GENERIC.EMPTY_ALERT,
};

export const manageActiveReviewersReducer = (
  state = {
    reviewers: { ...initialState, data: [], type: FETCH_ALL_REVIEWERS_ACTIVE },
    reviewerStatus: { ...initialState, data: null, type: ACTIVATE_REVIEWERS },
  },
  action = {}
) => {
  switch (action.type) { 
    case FETCH_ALL_REVIEWERS_ACTIVE: {
      let reviewers = action.payload.data;
      return {
        ...state,
        reviewers: {
          status: action.payload.status ?? '',
          alert: action.payload.alert ?? '',
          data: reviewers ? sortByAttribute(reviewers, 'lastName') : [],
          type: FETCH_ALL_REVIEWERS_ACTIVE,
        },
      };
    }
    case ACTIVATE_REVIEWERS:
      return {
        ...state,
        reviewerStatus: {
          status: action.payload.status ?? '',
          alert: action.payload.alert ?? '',
          data: action.payload.data ?? null,
          type: ACTIVATE_REVIEWERS,
        },
      };
    case UPDATE_REVIEWERS_STATUS:
      return {
        ...state,
        reviewers: {
          ...state.reviewers,
          data: state.reviewers.data.map((item) => {
            const reviewer = action.payload.reviewers.reviewers.find((reviewer) => {
              return item.reviewerEmployeeId === reviewer;
            });

            if(reviewer) {
              const newStatus = action.payload.reviewers.active ? STATUS.ACTIVE : STATUS.INACTIVE;
              item.data = newStatus;
              item.status = newStatus;
            }
            return item;
          }),
        },
      };
    default:
      return state;
  }
};
