import { makeStyles } from "@material-ui/core";
import { Mui, MuiLab } from "@osu/react-ui";
import useAutoCompleteReviewers from "./hooks/useAutoCompleteReviewers";


const useStyles = makeStyles((theme) => ({
  endAdornment: {
    top: 'calc(50% - 20px)'
  },
}));

const AutoComplete = (props = {}) => {
  const classes = useStyles()
  const { id, reviewer, setReviewer, type } = props;
  const { options } = useAutoCompleteReviewers({type: type})
  return (
    <MuiLab.Autocomplete
      // className={classes.endAdornment}
      classes={{endAdornment: classes.endAdornment}}
      id={id}
      options={options}
      getOptionLabel={(option) => option.label || ""}
      getOptionSelected={(option, value) => option.emplid === value.emplid}
      value={reviewer}
      onChange={(e, value) => setReviewer(value || "")}
      style={{ minWidth: 300}}
      renderInput={(params) => (
        <Mui.TextField {...params} label={"Choose Reviewer"} variant="outlined" />
      )}
    />
  );
}

export default AutoComplete;
