import React from 'react';

const ClearNotifyAdminDialogContent = props => {
    const { recommendationHistory } = props;
    
    const recommendationsWithNotify = recommendationHistory?.filter?.(recommendation => {
            return recommendation.notificationCreated 
                // Either it hasn't been cleared, or its a new notification (the created date is AFTER the cleared date)
                && !recommendation.notificationCleared
        }) ?? [];
    
    const reviewerNames = recommendationsWithNotify?.map?.(recommendation => `${recommendation.firstName} ${recommendation.lastName}`);

    return (
        <ul>
            {reviewerNames?.map?.( name => (
                <li key={name}>{name}</li>
            ))}
        </ul>
    );
};

export default ClearNotifyAdminDialogContent;