import { LIST_ADMISSIONS_CYCLES, SAVE_ADMISSIONS_CYCLE } from '../actions-index';
import { ERROR_CONSTS } from '../util/constants';

const initialState = {
    status: "",
    alert: ERROR_CONSTS.UI.GENERIC.EMPTY_ALERT,
    data: {},
};

export const manageAdmissionsCyclesReducer = ( state = initialState, action = {} ) => {
    switch (action.type) {
        case LIST_ADMISSIONS_CYCLES: {
            return {
                ...state,
                listAdmissionsCyclesData: {
                    status: action.payload.status ?? initialState.status,
                    alert: action.payload.alert ?? initialState.alert,
                    data: action.payload.data ?? initialState.data,
                    type: LIST_ADMISSIONS_CYCLES
                }
            }
        }

        case SAVE_ADMISSIONS_CYCLE: {
            return {
                ...state,
                saveAdmissionsCyclesData: {
                    status: action.payload.status ?? initialState.status,
                    alert: action.payload.alert ?? initialState.alert,
                    data: action.payload.details ?? initialState.data,
                    type: SAVE_ADMISSIONS_CYCLE
                }
            }
        }

        default: return state;
    }
};