import React, { useRef } from 'react';
import Main from '../../Main/components';
import { MAIN_ID } from '../../util/constants';
import {
  withOSUTheming,
  useScrollToHash,
  Mui,
  withTableOfContents,
  Footer,
  useFooterWithTOC,
  withDarkMode,
} from '@osu/react-ui';
import { AppProvider } from '../context';
// import OSUNavBar from "react-osu-navbar";
import { Navbar as OSUNavBar } from '@osu/navbar';
//import Navbar from "../../Navbar/containers";
import withAuthentication from '../../Authentication/withAuthentication';
import useCssVariableUpdater from '../hooks/useCssVariableUpdater';

function App(props = {}) {
  const { navHeight, darkMode } = props;
  const { ref } = useScrollToHash();
  const footerRef = useRef();
  useFooterWithTOC({ ref: footerRef });
  const trigger = Mui.useScrollTrigger({
    disableHysteresis: true,
    threshold: navHeight,
  });

  return (
    <div className="App" ref={ref}>
      <OSUNavBar darkMode={darkMode} />
      {/* <Navbar /> */}
      <Main spacingTop={!trigger} className="anchor-link" id={MAIN_ID} />
      <Footer
        ref={footerRef}
        organization={'Office of Technology and Digital Innovation'}
        maintainedBy={{
          label: 'Student Web Team',
          href: 'mailto:student-web-support@lists.osu.edu',
        }}
      />
    </div>
  );
}

const TOCWrappedApp = withTableOfContents(App);

function Provider(props = {}) {
  const { authorized, handleSignIn, authStatus, handleSignOut, darkMode } =
    props;
  const { navHeight } = useCssVariableUpdater();
  const appContext = {
    authStatus,
    authorized,
    handleSignIn,
    handleSignOut,
    navHeight,
    darkMode,
  };
  return (
    <AppProvider value={appContext}>
      <TOCWrappedApp darkMode={darkMode} navHeight={navHeight} />
    </AppProvider>
  );
}

export default withDarkMode(withOSUTheming(withAuthentication(Provider)));
