export const committeeFriendlyName = (data, committeeData) => {
    if (!data || committeeData.length === 0 || data?.length === 0) return data;
    let newCommitteeDetails = [];
    for (let committee of data.committeeDetails) {
        let newCommittee = {};
        let com = committeeData.find(o => o.committeeId === committee.committeeId);
        if (com) {
            newCommittee = {
                ...committee,
                committeeName: com.committeeName
            }
        }
        newCommitteeDetails.push(newCommittee);
    }
    data.committeeDetails = newCommitteeDetails;


    return data;
}

export function recalculateReviewerCounts(selectedReviewer, payload) {

    if (!selectedReviewer || !payload) return;

    let selectedReviewerSelectedCommittee = selectedReviewer.committeeDetails.find(o => o.committeeId === payload.committeeId);
    if (!selectedReviewerSelectedCommittee) return;

    if (payload.pending)
        selectedReviewerSelectedCommittee.pending = payload.pending;

    if (payload.submitted)
        selectedReviewerSelectedCommittee.submitted = payload.submitted;

    if (payload.total)
        selectedReviewerSelectedCommittee.total = payload.total;

    return selectedReviewer;
}