export const getCustomReportDataQuery = `query GetAthenaReportingData($reportId: String!, $committeeId: String!, $filters: String, $queryExecutionId: String, $nextToken: String, $fullDownloadData: FullDownloadData) {
    getAthenaReport(reportId: $reportId, committeeId: $committeeId, filterOptions: $filters, queryExecutionId: $queryExecutionId, nextToken: $nextToken, fullDownloadData: $fullDownloadData) {
        applications {
            applicantemployeeid
            committeeid
            term
            dataAsJsonMap
        }
        columnMappingsAsJson
        count
        filtersAsJson      
        nextToken
        queryExecutionId
        reportName
        error
    }
}`;
