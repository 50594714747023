export const assignApplicationsMutation = `mutation ManualBatchAssignApplications($input: AssignApplicationsInput) {
    manualBatchAssignApplicationsV2(input: $input) {
        status
        successDetails
        errorDetails {
            message
            date
        }
    }
}`;

export const unassignApplicationsMutation = `mutation manualBatchUnassignApplications($input: AssignApplicationsInput) {
    manualBatchUnassignApplications(input: $input) {
        status
        successDetails
        errorDetails {
            message
            date
        }
    }
}`;
