import { MENU_CHANGE, SELECT_REVIEWER, SELECT_SECOND_REVIEWER, SELECT_COMPLETEDBYDATE } from "../../../actions-index";
import { actionLabels, descriptions } from "./enums";

const actionsReducer = (state, action) => {
  const { payload = {} } = action ?? {}
  const { menuType } = payload ?? {}
  const dialog = {
    description: descriptions?.[menuType],
    title: actionLabels?.[menuType],
    type: menuType
  }
  
  switch (action.type) {
    case SELECT_REVIEWER:
      return {
        ...state,
        reviewer: payload,
      };
    case SELECT_SECOND_REVIEWER:
      return {
        ...state,
        secondReviewer: payload
      };
    case SELECT_COMPLETEDBYDATE:
      return {
        ...state,
        completedByDate: payload
      };
    case MENU_CHANGE:
      return {
        ...state,
        dialog
      };
    default:
      return state;
  }
};

export default actionsReducer;
