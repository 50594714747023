import { API } from 'aws-amplify';
import { GET_REPORT_ATHENA, GET_REPORT_ATHENA_ERROR } from '../actions-index';
import { SEVERITY_LEVELS, STATUS } from '../util/constants';
import { getCustomReportDataQuery } from './query';
import { setFilters } from "../Common/components/OneTrueDataGrid/DatagridReducer";
import { subscribeToFullDownload, getFullDownloadData } from "../Common/components/reports/csvSubscriptionDownloader";

export const getCustomReportData = (datagridDispatch, reportId, committeeId, filters, queryExecutionId, nextToken, allowRetry = true, fullDownloadData = false) => {
    return async (dispatch) => {
        const type = GET_REPORT_ATHENA;

        try {
            const isGettingNextPage = nextToken ? true : false;
            const query = getCustomReportDataQuery;
            const variables = { reportId, committeeId, filters, queryExecutionId, nextToken, fullDownloadData: getFullDownloadData(fullDownloadData)};
            let response = null;
            
            if (!variables.fullDownloadData.isFullDownload) {
                dispatch({ type, payload: { status: STATUS.LOADING, isGettingNextPage } });
                response = await API.graphql({ query, variables });
            } else {
                const { adminEmployeeId, datetimeId } = variables.fullDownloadData;
                await subscribeToFullDownload(dispatch, adminEmployeeId, committeeId, datetimeId);

                // Necessary because subscribing is slow; may miss data if we make API call too fast
                response = setTimeout(() => API.graphql({ query, variables }), 3000);
                return;
            }
            
            if (response && !response?.data?.getAthenaReport.error) {
                const payload = { status: STATUS.SUCCESS, data: response?.data?.getAthenaReport, isGettingNextPage };
                dispatch({ type, payload });
                return;
            }

            if (response?.data?.getAthenaReport?.error !== "Report not found" && allowRetry) {
                setTimeout(() => {
                    setFilters(datagridDispatch, {}); // Bad filters can potentially brick a custom report for a user (can't load page to get filters); auto-reset bad filters if we hit an error
                }, 5000);
            }
            
            // Handle errors
            const errorPayload = {
                status: STATUS.ERROR,
                alert: {
                    MESSAGE: response?.data?.getAthenaReport?.error,
                    SEVERITY: SEVERITY_LEVELS.ERROR,
                },
            };
            dispatch({ type: GET_REPORT_ATHENA_ERROR, payload: errorPayload });
        } catch (error) {
            if (allowRetry) {
                setTimeout(() => {
                    setFilters(datagridDispatch, {}); // Bad filters can potentially brick a custom report for a user (can't load page to get filters); auto-reset bad filters if we hit an error
                }, 5000);
            }
            
            console.error(error);
            console.error(error?.errors?.[0]?.message);

            const errorPayload = {
                status: STATUS.ERROR,
                alert: {
                    MESSAGE: error?.errors?.[0]?.message,
                    SEVERITY: SEVERITY_LEVELS.ERROR,
                },
            };
            dispatch({ type: GET_REPORT_ATHENA_ERROR, payload: errorPayload });
        }
    };
};