import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AuthButton from '../components/AuthButton';

const mapStateToProps = (state, ownProps) => {
  const { authentication } = state;
  const { status = '' } = authentication;

  return {
    status,
    name: authentication.user?.name,
  };
};

export default withRouter(connect(mapStateToProps, null)(AuthButton));
