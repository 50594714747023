export const getManageReviewerQuery = `query GetManageReviewer($reviewerEmployeeId: String) {
    getManageReviewer(reviewerEmployeeId: $reviewerEmployeeId) {
        firstName
        lastName
        reviewerEmployeeId
        admin {
            committeeName
            committeeId
            experienceKey
            experienceLabel
            quota
            jobType
            groupType
        }
        reviewer {
            committeeName
            committeeId
            experienceKey
            experienceLabel
            quota
            jobType
            groupType
        }
        experienceLevels {
            key
            value
        }
    }
}`;