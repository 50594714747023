import { API } from 'aws-amplify';
import { GET_REPORT, GET_CUSTOMFILTEROPTIONS } from '../../../actions-index';
import { SEVERITY_LEVELS, STATUS } from '../../../util/constants';
import { getReportQuery, listCustomFiltersQuery } from './query';
import { subscribeToFullDownload, getFullDownloadData } from "./csvSubscriptionDownloader";

// getCommonReportDataRequestFields
// export function getCRDRF(reportType, status = STATUS.INPROGRESS, committeeId, filtersFromDropdowns, filterExpressions, limit = 500, lastEvaluatedKey, fullDownloadData = false) {
//     return {
//         reportType,
//         status,
//         committeeId,
//         filtersFromDropdowns,
//         filterExpressions,
//         limit,
//         lastEvaluatedKey,
//         fullDownloadData
//     };
// }

export const getCommonReportData = ({committeeId, filtersFromDropdowns: filters, limit, reportType, status = STATUS.INPROGRESS, lastEvaluatedKey, filterExpressions, fullDownloadData = false}) => {
    return async (dispatch) => {
        const type = GET_REPORT;

        try {
            // console.info("lastEvalKey rdrdactions", JSON.stringify({committeeId, filters, limit, reportType, lastEvaluatedKey, filterExpressions, fullDownloadData}));
            const query = getReportQuery;
            const variables = { committeeId, status, filters, filterExpressions, limit, reportType, lastEvaluatedKey, fullDownloadData: getFullDownloadData(fullDownloadData)};
            let response = null;
            
            if (!variables.fullDownloadData.isFullDownload) {
                dispatch({ type, payload: { status: STATUS.LOADING } });
                response = await API.graphql({ query, variables });
            } else {
                const { adminEmployeeId, datetimeId } = variables.fullDownloadData;
                await subscribeToFullDownload(dispatch, adminEmployeeId, committeeId, datetimeId);

                // Necessary because subscribing is slow; may miss data if we make API call too fast
                setTimeout(() => API.graphql({ query, variables }), 3000);
                return;
            }
            
            if (response) {
                const payload = { status: STATUS.SUCCESS, data: response?.data?.getReport, timeStamp: new Date(), };
                dispatch({ type, payload });
                return;
            }

            // Handle errors
            const errorPayload = {
                status: STATUS.ERROR,
                alert: {
                    MESSAGE: response?.data?.getReport?.error?.message,
                    SEVERITY: SEVERITY_LEVELS.ERROR,
                },
            };
            dispatch({ type, payload: errorPayload });
        } catch (error) {
            console.error(error);
            console.error(error?.errors?.[0]?.message);

            const errorPayload = {
                status: STATUS.ERROR,
                alert: {
                    MESSAGE: error?.errors?.[0]?.message,
                    SEVERITY: SEVERITY_LEVELS.ERROR,
                },
            };
            dispatch({ type, payload: errorPayload });
        }
    };
}

export const listCustomFilters = (committeeId, isRetry = false) => {
    return async (dispatch) => {
        const type = GET_CUSTOMFILTEROPTIONS;

        try {
            dispatch({ type, payload: { status: STATUS.LOADING } });
            const query = listCustomFiltersQuery;
            const variables = { committeeId };
            const response = await API.graphql({ query, variables });

            const { error, filtersAsJson, status } = response?.data?.getAthenaCustomFilters ?? {};
            if (status === STATUS.SUCCESS && !error && typeof filtersAsJson == "string") {
                const payload = { status: STATUS.SUCCESS, data: filtersAsJson ?? [] };
                dispatch({ type, payload });
                return;
            }

            // Handle errors
            const errorPayload = {
                status: STATUS.ERROR,
                alert: {
                    MESSAGE: response?.data?.getAthenaCustomFilters?.error?.message ?? response?.data?.getAthenaCustomFilters?.error,
                    SEVERITY: SEVERITY_LEVELS.ERROR,
                },
            };

            dispatch({ type, payload: errorPayload });
        } catch (error) {
            // For timeouts, AppSync times out with "Execution timed out." message, the underlying lambda timing out causes AppSync to return "An internal failure.."
            if (error?.errors?.[0].message === "Execution timed out." || error?.errors?.[0].message === "An internal failure occurred while resolving this field.") {
                console.error("Retrying listCustomFiltersQuery after initial timeout");
                try {
                    const query = listCustomFiltersQuery;
                    const variables = { committeeId };
                    const response = await API.graphql({ query, variables });
        
                    const { error, filtersAsJson, status } = response?.data?.getAthenaCustomFilters ?? {};
                    if (status === STATUS.SUCCESS && !error && typeof filtersAsJson == "string") {
                        const payload = { status: STATUS.SUCCESS, data: filtersAsJson ?? [] };
                        dispatch({ type, payload });
                        return;
                    }
                } catch (exception) {
                    console.error("Error in retry of listCustomFiltersQuery", exception);
                } // swallow and allow original error to come through
            }
            
            console.error("Error in listCustomFilters", error?.errors?.[0]?.message, error);
            const errorPayload = {
                status: STATUS.ERROR,
                alert: {
                    MESSAGE: `Custom Filters: ${error?.errors?.[0]?.message}`,
                    SEVERITY: SEVERITY_LEVELS.ERROR,
                },
            };
            dispatch({ type, payload: errorPayload });
        }

    }
};
