export const getCommitteeReviewersFromDB = `query GetCommitteeReviewersFromDB($committeeId: String!, $createdDate: String!) {
    getCommitteeReviewersFromDB(committeeId: $committeeId, createdDate: $createdDate) {
        committeeId
        experienceLabel
        firstName
        lastName
        campusId
        jobType
        quota
        reviewerEmployeeId
        status
        type
    }
}`;