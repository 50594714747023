import { recalculateReviewerCounts } from './transform';
import { FETCH_REVIEWER_BY_ID, FETCH_COMMITTEE_DETAILS, SUBSCRIBE_REVIEWER_CHANGES, SUBSCRIBE_COMMITTEE_CHANGES } from '../actions-index';
import { ERROR_CONSTS } from "../util/constants";

const initialState = {
  status: '',
  alert: ERROR_CONSTS.UI.GENERIC.EMPTY_ERROR_OBJECT,
}

export const manageApplicationsAssignedReducer = (
  state = { assignedCounts: { ...initialState, data: [], type: FETCH_REVIEWER_BY_ID } },
  action = {}
) => {
  switch (action.type) {
    case FETCH_REVIEWER_BY_ID:
      return {
        ...state,
        assignedCounts: {
          status: action.payload.status ?? initialState.status,
          alert: action.payload.alert ?? initialState.alert,
          data: action.payload.data,
          type: FETCH_REVIEWER_BY_ID,
        }
      }
    case FETCH_COMMITTEE_DETAILS:
      return {
        ...state,
        committeeDetails: action.payload.data
      }
    case SUBSCRIBE_REVIEWER_CHANGES: {
      let assignedCounts = recalculateReviewerCounts(state.assignedCounts?.data, action.payload);
      return {
        ...state,
        assignedCounts: { data: {...assignedCounts} }
      }
    }

    case SUBSCRIBE_COMMITTEE_CHANGES: {
      return {
        ...state,
        committeeDetails: action.payload
      }
    }
    
    default:
      return state;
  }
};




