import { withStyles } from '@material-ui/core';
import { Mui} from '@osu/react-ui';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import {
  getCommitteeReviewersState,
  getStudentState,
  getTrainingState,
} from '../../../../Common/redux/selectors';
import { CREATE_TRAINING } from '../../../../actions-index';
import { generateId } from '../../../../util/functions';

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.colors.blue,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Mui.Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center"
  },
  variant: "menu"
};

const chooseReviewersId = generateId('choose-reviewers');

const CreateTrainingDialogContent = ({ isTraining }) => {
  const dispatch = useDispatch();
  const trainingState = useSelector((state) => getTrainingState(state));
  const studentState = useSelector((state) => getStudentState(state));
  let history = useHistory();
  const {
    emplids,
    expectedRecommendation,
    expJunior,
    expIntermediate,
    expAdvanced,
    expSenior,
    jobTypeRegular,
    jobTypeSeasonal,
    allReviewers,
  } = trainingState;
  
  const reviewersFromDB = useSelector(
    (state) => getCommitteeReviewersState(state)?.data ?? []
  );
 
  let filteredReviewersFromDB = reviewersFromDB;
  
  // Only filter when assigning on the training page
  if (isTraining) {
    const attachedReviewerIds = studentState?.data?.readers.map((item) => item.readerEmployeeId) ?? [];
    let unassignedReviewers = studentState?.data?.readers?.filter((item) => item.status.includes('UNASSIGNED'))
    unassignedReviewers = unassignedReviewers?.map((item) => item.readerEmployeeId);
    filteredReviewersFromDB = reviewersFromDB.filter((reviewer) => unassignedReviewers?.includes(reviewer.emplid) || !attachedReviewerIds.includes(reviewer.emplid));
  }
  
  
  const committeeButtonMap = trainingState?.recommendationOptions ?? [];

  const transformedReviewers = filteredReviewersFromDB?.map((item) => 
  ({
    campusId: item.campusId,
    emplid: item.emplid,
    experienceLabel: item.experienceLabel ? item.experienceLabel : "Junior",
    firstName: item.firstName,
    jobType: item.jobType ? item.jobType : "Seasonal",
    lastName: item.lastName
  }));
 
  const reviewersAvailable = transformedReviewers.filter(
    (reviewer) =>
      (
      (expJunior && (reviewer?.experienceLabel === 'Junior' || reviewer?.experienceLabel === null)) ||
      (expIntermediate && (reviewer?.experienceLabel === 'Intermediate' || reviewer?.experienceLabel === null)) ||
      (expAdvanced && (reviewer?.experienceLabel === 'Advanced' || reviewer?.experienceLabel === null)) ||
      (expSenior && (reviewer?.experienceLabel === 'Senior' || reviewer?.experienceLabel === null))
      ) &&
      (
        (jobTypeSeasonal && (reviewer?.jobType === 'Seasonal' || reviewer?.jobType === null)) ||
      (jobTypeRegular && (reviewer?.jobType === 'Regular' || reviewer?.jobType === null))
      )
  );
 
  const handleChangeIncludeAll = (event) => {
    if (event.target.checked) {
      dispatch({
        type: CREATE_TRAINING,
        payload: {
          ...trainingState,
          expJunior: true,
          expIntermediate: true,
          expAdvanced: true,
          expSenior: true,
          jobTypeSeasonal: true,
          jobTypeRegular: true,
          allReviewers: true,
          emplids: [],
        },
      });
    } else {
      dispatch({
        type: CREATE_TRAINING,
        payload: {
          ...trainingState,
          allReviewers: false,
        },
      });
    }
  };

  const handleChangeCheckbox = (event) => {
    dispatch({
      type: CREATE_TRAINING,
      payload: {
        ...trainingState,
        [event.target.name]: event.target.checked,
      },
    });
  };

  const handleChangeReviewers = (event) => {
    let selectedEmplids = event.target.value;
    if (selectedEmplids[selectedEmplids.length - 1] === "all") {
      const emplidsLength = selectedEmplids.length - 1;
      selectedEmplids = emplidsLength < reviewersAvailable.length? reviewersAvailable.map(e => e.emplid) : []
    }
     dispatch({
      type: CREATE_TRAINING,
      payload: {
        ...trainingState,
        emplids: selectedEmplids 
      },
    });
  };

  const handleChangeRecommendation = (value) => {
      const recommendation =
        trainingState?.expectedRecommendation === value ? '' : value;
      dispatch({
        type: CREATE_TRAINING,
        payload: {
          ...trainingState,
          expectedRecommendation: recommendation,
        },
      });
  };

  const formatName = (reviewer) => {
    if(!reviewer) return "";
    const { firstName, lastName, campusId} = reviewer;
    return `${firstName} ${lastName} (${campusId})`;
  }

  const getReviewerDisplay = (emplids) => {
    let vals = emplids.map(emplid => {
        const reviewer = transformedReviewers.find((item) => item.emplid === emplid);
        return formatName(reviewer);
    }).join(", ")
    return vals;
  }

  return (
    <Mui.Box>
      <Mui.FormControlLabel
        control={
          <IOSSwitch
            checked={allReviewers}
            onChange={handleChangeIncludeAll}
            name="allReviewers"
          />
        }
        label={`Include all reviewers.`}
      />
      <Mui.Collapse in={!allReviewers} timeout="auto" unmountOnExit>
        {!allReviewers && (
          <>
            <Mui.Box style={{ padding: '1rem 0 1rem 0' }}>
              <Mui.Typography>
                Filter reviewers by skill level (optional):
              </Mui.Typography>
              <Mui.FormControlLabel
                control={
                  <IOSSwitch
                    checked={expJunior}
                    onChange={handleChangeCheckbox}
                    name="expJunior"
                  />
                }
                label={`Junior`}
              />
              <Mui.FormControlLabel
                control={
                  <IOSSwitch
                    checked={expIntermediate}
                    onChange={handleChangeCheckbox}
                    name="expIntermediate"
                  />
                }
                label={`Intermediate`}
              />
               <Mui.FormControlLabel
                control={
                  <IOSSwitch
                    checked={expAdvanced}
                    onChange={handleChangeCheckbox}
                    name="expAdvanced"
                  />
                }
                label={`Advanced`}
              /> 
              <Mui.FormControlLabel
                control={
                  <IOSSwitch
                    checked={expSenior}
                    onChange={handleChangeCheckbox}
                    name="expSenior"
                  />
                }
                label={`Senior`}
              /> 
            </Mui.Box>

            <Mui.Box style={{ padding: '1rem 0 1rem 0' }}>
              <Mui.Typography>
                Filter reviewers by job type (optional):
              </Mui.Typography>
              <Mui.FormControlLabel
                control={
                  <IOSSwitch
                    checked={jobTypeSeasonal}
                    onChange={handleChangeCheckbox}
                    name="jobTypeSeasonal"
                  />
                }
                label={`Seasonal`}
              />
              <Mui.FormControlLabel
                control={
                  <IOSSwitch
                    checked={jobTypeRegular}
                    onChange={handleChangeCheckbox}
                    name="jobTypeRegular"
                  />
                }
                label={`Regular`}
              />
            </Mui.Box>

            <Mui.InputLabel id={chooseReviewersId}>
              Choose Reviewers{' '}
              {emplids?.length > 0 && `( ${emplids?.length} selected )`}
            </Mui.InputLabel>
            <Mui.Select
              labelId={chooseReviewersId}
              multiple
              fullWidth
              value={emplids}
              onChange={handleChangeReviewers}
              renderValue={getReviewerDisplay}
              input={
                <Mui.OutlinedInput
                  name="reviewers"
                  variant="outlined"
                  id="outlined-age-simple"
                />
              }
              MenuProps={MenuProps}
            >
               <Mui.MenuItem value="all">
                <Mui.ListItemIcon>
                  <Mui.Checkbox
                    checked={emplids?.length === reviewersAvailable.length}
                    indeterminate={
                      emplids?.length > 0 && emplids?.length < reviewersAvailable.length
                    }
                    inputProps={{ 'aria-label': 'select all' }}
                  />
                </Mui.ListItemIcon>
                <Mui.ListItemText
                  primary="Select All"
                />
              </Mui.MenuItem>
              {reviewersAvailable.map((reviewer) => (
                <Mui.MenuItem key={reviewer.emplid} value={reviewer.emplid}>
                  <Mui.ListItemIcon>
                    <Mui.Checkbox
                      checked={emplids.includes(reviewer.emplid)}
                      inputProps={{ 'aria-label': `select ${getReviewerDisplay([reviewer.emplid])}` }}
                    />
                  </Mui.ListItemIcon>
                  <Mui.ListItemText key={reviewer.emplid} value={reviewer.emplid}
                    primary={getReviewerDisplay([reviewer.emplid])}
                  />
                </Mui.MenuItem>
              ))}
            </Mui.Select>

          </>
        )}
      </Mui.Collapse>
      {history?.location?.pathname.includes('/training/') ? null : 
      <Mui.Box> 
      <Mui.Box>
        <Mui.FormControlLabel
        control={
          <IOSSwitch
            checked={trainingState.expectedRecommendation === "None"}
            onChange={(event) => {
              dispatch({
                type: CREATE_TRAINING,
                payload: {
                  ...trainingState,  
                  expectedRecommendation: event.target.checked ? "None" : "",  
                },
              });
            }}
          />
        }
        label={`No Recommendation Needed`}
      /> 
      </Mui.Box>
      {trainingState.expectedRecommendation === "None" ? null :
       <Mui.Box>
       <Mui.Typography style={{ marginTop: '1rem' }}>
       Expected Recommendation:
       </Mui.Typography>
         <Mui.ButtonGroup aria-label="outlined button group">
           {committeeButtonMap.map((item, index) => {
           const matchesDecision =
           expectedRecommendation.toLowerCase() === item.value.toLowerCase();
           return (
             <Mui.Button
               key={index}
               color={matchesDecision ? 'secondary' : undefined}
               variant={matchesDecision ? 'contained' : undefined}
               onClick={() => {
                 handleChangeRecommendation(item.value);
               }}
             >
               {item.value}  
             </Mui.Button>
           );
         })}
       </Mui.ButtonGroup>
     </Mui.Box>
      }
     
      </Mui.Box>
      }
     
    </Mui.Box>
  );
};

export default CreateTrainingDialogContent;
