const transformGetNextApp = (queryCommitteeId, response) => {
    return {
        training: queryCommitteeId === "training",
        ...response,
    }
}

export { transformGetNextApp };

export const sortInProgress = (values) => {
    if(!values) return values;
     // sort filtered by status of NOTIFY as first
     return values.sort((a, b) => {
      if (a.manuallyAssigned === true) {
        return -1;
      } else if (b.manuallyAssigned === true) {
        return 1;
      } else {
        return 0;
      }
    });
  }

export const filterInProgressByAdminAction = (progressData, saved, assigned, admin) => {
    // console.log("filterInProgressByAdminAction", progressData);
    saved = progressData?.inProgress?.filter(e => (e.status === "RESUMELATER" && e.adminAction !== "NOTIFY" && e.manuallyAssigned === false) || e.notificationCleared) ?? [];
    assigned = progressData?.inProgress?.filter(e => e.manuallyAssigned === true && e.adminAction !== "NOTIFY" && !e.notificationCleared) ?? [];
    admin = progressData?.inProgress?.filter(e => e.adminAction === "NOTIFY") ?? [];
    const dashInprogress = progressData?.inProgress?.filter(e => (e.adminAction !== "NOTIFY" || e.notificationCleared) && (e.manuallyAssigned === true || e.status === "RESUMELATER")) ?? [];
    
    return {
      inProgress: {
        saved: saved,
        assigned: assigned,
        notifyAdmin: admin,
        dashInprogress,
      },
      assigned: progressData?.assigned,
      pastDue: progressData?.pastDue,
      trainings: progressData?.trainings,
    };  
  };


export const filterAndSortInProgress = (inProgressViewFilter, saved, assigned, admin) => {
    //console.log("filterAndSortInProgress", inProgressViewFilter);
    if(!saved || !assigned || !admin) return [];
    let filtered = [];
    if (inProgressViewFilter.length > 0) {
      if (inProgressViewFilter.includes("saved")) {
        filtered = [...filtered, ...saved];
      }
      if (inProgressViewFilter.includes("assigned")) {
        filtered = [...filtered, ...assigned];   
      }
      if (inProgressViewFilter.includes("admin")) {
        filtered = [...filtered, ...admin]; 
      }
      filtered = sortInProgress(filtered);  
    } else {
      filtered = sortInProgress([...saved, ...assigned, ...admin]);
    }
  
    // dedupe filtered array
    filtered = filtered.filter((elem, index, self) => {
      return index === self.indexOf(elem);
    });
  
    return filtered;
  };

