import { GET_COMMITTEE_LIST, GET_IN_PROGRESS, GET_NEXT_APP, CLEANUP_NEXTAPP } from '../actions-index';

export const DashboardReducer = (
  state = { committees: [], loading: false, error: null },
  action = {}
) => {
  switch (action.type) {
    case GET_COMMITTEE_LIST:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export const InProgressReducer = (
  state = { progress: [], loading: false, error: null },
  action = {}
) => {
  switch (action.type) {
    case GET_IN_PROGRESS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
const initialFullState = { nextApp: [], loading: false, error: null };
export const NextAppReducer = (
  state = { nextApp: [], loading: false, error: null },
  action = {}
) => { 
  switch (action.type) {
    case CLEANUP_NEXTAPP:
      return initialFullState;
    case GET_NEXT_APP:
      return {
        ...state,
        nextApp: {
          status: action.payload.status,
          ...action.payload,
          type: GET_NEXT_APP
        }
      };
    default:
      return state;
  }
};
