export const getCommitteeReviewersFromDB = `query GetCommitteeReviewersFromDB($committeeId: String!, $createdDate: String!, $includeInactive:Boolean) {
    getCommitteeReviewersFromDB(committeeId: $committeeId, createdDate: $createdDate, includeInactive: $includeInactive) {
        committeeId
        experienceKey
        experienceLabel
        firstName
        lastName
        campusId
        jobType
        groupType
        quota
        reviewerEmployeeId
        status
        type
        admin
        pending
    }
}`;