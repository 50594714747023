import { Mui } from '@osu/react-ui';
import SearchIcon from '@material-ui/icons/Search';
import useRoleAuthentication from '../../hooks/useRoleAuthentication';

const SearchInput = ({ inputValue, setInputValue, search, width='100%' }) => { 
  const authDetails = useRoleAuthentication();
  
  return (
    <Mui.TextField
      id="search-input"
      label={(!authDetails?.ADMIN && authDetails?.SLT_READ_ONLY ) ? 'Applicant ID' : 'Reviewer Last Name or Applicant ID' }
      variant="outlined"
      style={{ margin: '0 1rem 0 0', width: width}}
      value={inputValue}
      InputLabelProps={ { style: { backgroundColor:"white" } }}
      InputProps={{
        endAdornment: <Mui.InputAdornment position="end">
        <Mui.IconButton
          aria-label={(!authDetails?.ADMIN && authDetails?.SLT_READ_ONLY ) ? 'Search for applicant button' : "Search for reviewer or applicant button" }
          onClick={() => search()}
          edge="end"
        >
          {<SearchIcon/>}
        </Mui.IconButton>
      </Mui.InputAdornment>,
      }}
      onChange={(e) => setInputValue(e.target.value)}
      onKeyDown={(e) => {
        e.key === 'Enter' && search();
      }}
    />
  );
};

export default SearchInput;
