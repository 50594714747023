import { Mui } from '@osu/react-ui';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getNotifyAdminState,
  getStudentState,
} from '../../../../Common/redux/selectors';
import { NOTIFY_ADMIN } from '../../../../actions-index';

const useStyles = Mui.makeStyles((theme) => ({
    requiredSubselectionInstructions: { fontSize: "90%" },
    placeholderWidth: { visibility: "hidden" }
}));

const NotifyAdminDialogContent = ({
  data,
  setData,
}) => {
  const classes = useStyles();
  const appData = useSelector(getStudentState)?.data;
  const notifyAdminState = useSelector(getNotifyAdminState);
  const notes = appData?.notes?.notify;
  const dispatch = useDispatch();

  const handleChange = (newData) => {
    if (data?.includes(newData)) {
      let newArray = data?.filter((item) => item !== newData);

      //should we get rid of parent?
      let children = newArray?.filter(item => item.parentLabel === newData?.parentLabel);
      if (children?.length <= 0) {
        newArray = newArray?.filter(item => item?.label !== newData?.parentLabel);
      }
      let parentShouldBeRemoved = notes?.filter(item => item?.parentLabel === newData?.label);
      newArray = newArray?.filter(item => !parentShouldBeRemoved?.includes(item));

      setData(newArray);
    } else {
      let parentShouldBeSelected = notes?.filter(item => item?.label === newData?.parentLabel);
      parentShouldBeSelected = parentShouldBeSelected.length > 0 && data?.includes(parentShouldBeSelected[0]) ? [] : parentShouldBeSelected
      setData([...data, newData, ...parentShouldBeSelected]);
    }
  };

  useEffect(() => {
    let anySelected = data.length > 0;
    let subLevelRequired = false;
    let subLevelFound = false;
    for (let dat of data) {
      if (dat.parentLabel === null) {
        // find the child
        let child = notes.find((d) => d.parentLabel === dat.label);
        if (child) {
          //check if they even need one
          if (!data?.find((note) => note.parentLabel === dat.label)) {
            subLevelRequired = true;
            subLevelFound = false
          }
        }
      }
    }
    

    if (!subLevelRequired && anySelected && notifyAdminState?.disabled === true) {
      dispatch({
        type: NOTIFY_ADMIN,
        payload: {
          disabled: false,
        },
      });
    } else if ((subLevelRequired && !subLevelFound && anySelected && notifyAdminState?.disabled === false) || (!subLevelRequired && !anySelected && notifyAdminState?.disabled === false)) {
      dispatch({
        type: NOTIFY_ADMIN,
        payload: {
          disabled: true,
        },
      });
    }
  }, [data, dispatch, notes, notifyAdminState?.disabled]);
  
  const isInvisible = !(notes?.filter?.(note => notes.some(potentialChildNote => potentialChildNote.parentLabel === note.label)).some(note => data?.includes(note)));
  const invisibleClass = isInvisible ? classes.placeholderWidth : "";

  return (
    <>
    <Mui.FormGroup>
      {notes?.map((note) => {
        if (!note?.parentLabel) {
          const subFields = notes?.filter(
            (item) => item?.parentLabel === note?.label
          );
          return (
            <>
              <Mui.FormControlLabel
                label={note?.label}
                control={
                  <Mui.Checkbox
                    inputProps={{ 'aria-label': note?.label }}
                    checked={data?.includes?.(note)}
                    onChange={() => handleChange(note)}
                    name={note?.label}
                  />
                }
              />
              {subFields?.map((field) => {
                const parentIsChecked = data?.includes(note);
                const asteriskAtEnd = parentIsChecked ? " *" : "";
                return (
                  <Mui.FormControlLabel
                    label={`${field?.label}${asteriskAtEnd}`}
                    style={{ paddingLeft: '2rem' }}
                    control={
                      <Mui.Checkbox
                        inputProps={{ 'aria-label': field?.label }}
                        checked={data?.includes?.(field)}
                        onChange={() => handleChange(field)}
                        name={field?.label}
                        style={{  color: parentIsChecked && !data?.find((e) => e.parentLabel === field.parentLabel) && 'red' }}
                      />
                    }
                  />
                );
              })}
            </>
          );
        } else return null;
      })}
    </Mui.FormGroup>
    <Mui.Typography className={`${classes.requiredSubselectionInstructions} ${invisibleClass}`}>* A subfield is required when you select class rank or transcript</Mui.Typography>
    </>
  );
};

export default NotifyAdminDialogContent;
