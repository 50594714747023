import { useDispatch, useSelector } from "react-redux";
import { assignApplication, unassignApplication, reportConflict, restartEvaluation, saveReview, notifyAdmin, reassignApplication, createTraining, clearNotifyAdmin, updatePriority, unassignTrainee } from "../../../../actions-index";
import { exportStringArray } from "../../../NotesState";
import { ERROR_CONSTS, SEVERITY_LEVELS, STATUS } from "../../../../util/constants";
import { isValidFutureDate } from "../../../../util/functions";

import {
  SAVE_REVIEW,
  ASSIGN_APPLICATION,
  UNASSIGN_APPLICATION,
  RESTART_APPLICATION,
  CLEAR_NOTIFY_ADMIN,
  NOTIFY_ADMIN,
  REASSIGN_APPLICATION,
  REPORT_CONFLICT,
  OVERRIDE_RECOMMENDATION,
  CREATE_TRAINING,
  ASSIGN_TRAINEE,
  UPDATE_PRIORITY,
  UNASSIGN_TRAINEE,
} from '../../../../actions-index';
import { getStudentState, getTrainingState, getUnassignTraineeState } from "../../../../Common/redux/selectors";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const useActionsDialog = ({ type, pageAppHeader, onClose, reviewer, secondReviewer, completedByDate, dialogState, data }) => {

  const dispatch = useDispatch();
  const location = useLocation();
  const isApplicantPage = location?.pathname?.includes("/applicant");

  const { term, applicantEmployeeId, committeeId } = useSelector(state => {
    const { demographics } = getStudentState(state)?.data ?? {}
    return {
      term: demographics?.admitTerm?.key,
      applicantEmployeeId: demographics?.applicantEmployeeId,
      committeeId: demographics?.committeeId,
    }
  })
  
  const trainingState = useSelector((state) => getTrainingState(state));
  const unassignTrainingState = useSelector((state) => getUnassignTraineeState(state));

  let actions = [
    {
      label: "Cancel",
      id: "action-button-cancel",
      // className: "margin-right-auto",
      onClick: () => dialogState?.type === OVERRIDE_RECOMMENDATION || !dialogState ? onClose() :
        dispatch({
          type: dialogState?.type,
          payload: { ...dialogState, status: STATUS?.CANCEL, alert: ERROR_CONSTS.UI.GENERIC.EMPTY_ALERT }
        }),
      color: "secondary",
      variant: "outlined",
      border: 'none',
    },
  ];

  const handleAssignApplication = () => {
    if (reviewer === "") {
      dispatch({
        type: ASSIGN_APPLICATION,
        payload: { alert: ERROR_CONSTS.UI.GENERIC.SELECT_REVIEWER_EMPTY }
      });
    } else if (!isValidFutureDate(completedByDate)) {
      dispatch({
        type: ASSIGN_APPLICATION,
        payload: { alert: ERROR_CONSTS.UI.GENERIC.SELECT_COMPLETEDBYDATE_EMPTY }
      });
    } else {
      dispatch(
        assignApplication({
          applicantEmployeeId,
          committeeId,
          term,
          reviewerEmployeeId: reviewer.emplid,
          reviewerFirstName: reviewer.firstName,
          reviewerLastName: reviewer.lastName,
          reviewerCompletedByDueDate: completedByDate,
        })
      );
    }
  }

  const handleUnassignTrainee = () => {
    dispatch(
      unassignTrainee({
        applicantEmployeeId,
        committeeId,
        term,
        emplids: unassignTrainingState?.emplids,
      })
    );
  }

  const handleCreateTraining = () => {
    if (isApplicantPage && !trainingState?.expectedRecommendation) {
      dispatch({
        type: CREATE_TRAINING,
        payload: {
          ...trainingState,
          status: STATUS?.ERROR,
          alert: {
            MESSAGE: 'Please select an expected recommendation before submitting.',
            SEVERITY: SEVERITY_LEVELS?.WARNING,
          }, 
        },
      });
    } else if (!trainingState?.allReviewers && trainingState?.emplids?.length === 0) {
      dispatch({
        type: CREATE_TRAINING,
        payload: {
          ...trainingState,
          status: STATUS?.ERROR,
          alert: {
            MESSAGE: 'Please select one or more reviewers before submitting.',
            SEVERITY: SEVERITY_LEVELS?.WARNING,
          }, 
        },
      });
    } else {
      dispatch(
        createTraining({
          applicantEmployeeId,
          committeeId,
          term,
          expectedRecommendation: trainingState?.expectedRecommendation,
          emplids: trainingState?.emplids,
        })
      )
    }
  }

  const handleUnassignApplication = () => {
    dispatch(
      unassignApplication({
        applicantEmployeeId,
        committeeId,
        term,
        reviewerEmployeeId: reviewer.emplid,
      })
    );
  }

  const handleUpdatePriority = () => {
    dispatch(
      updatePriority({
        applicantEmployeeId,
        committeeId,
        term,
      })
    );
  }

  const reportAppConflict = () => {
    dispatch(
      reportConflict({
        applicantEmployeeId,
        committeeId,
        term,
      })
    );
  }

  const restartEval = () => {
    dispatch(
      restartEvaluation({
        applicantEmployeeId,
        committeeId,
        term
      })
    );
  }

  const handleSaveReview = () => {
    const checkedCompetitive = exportStringArray(pageAppHeader?.noteState, "competitive");
    const checkedNoncompetitive = exportStringArray(pageAppHeader?.noteState, "noncompetitive");
    const checkedDocument = exportStringArray(pageAppHeader?.noteState, "document");
    const checkedEvaluation = exportStringArray(pageAppHeader?.noteState, "evaluation");
    const checkedOther = exportStringArray(pageAppHeader?.noteState, "othersConcerns");

    dispatch(
      saveReview({
        admitTerm: term,
        applicantEmployeeId: applicantEmployeeId,
        committeeId: committeeId,
        reviewNotes: {
          competitive: checkedCompetitive,
          document: checkedDocument,
          evaluation: checkedEvaluation,
          noncompetitive: checkedNoncompetitive,
          othersConcerns: checkedOther
        },
      })
    );
  }

  const handleNotifyAdmin = () => {
    if (data?.length <= 0) {
      dispatch({
        type: NOTIFY_ADMIN,
        payload: { alert: ERROR_CONSTS.UI.GENERIC.NOTIFY_EMPTY }
      });
    } else {
      dispatch(
        notifyAdmin({
          applicantEmployeeId: applicantEmployeeId,
          committeeId: committeeId,
          term: term,
          data: data
        })
      );
    }
  }

  const handleClearNotifyAdmin = () => {
    dispatch(
      clearNotifyAdmin({
        applicantEmployeeId: applicantEmployeeId,
        committeeId: committeeId,
        term: term       
      })
    );
  }

  const handleReassignApplication = () => {
    if (reviewer === "" || secondReviewer === "") {
      dispatch({
        type: REASSIGN_APPLICATION,
        payload: { alert: ERROR_CONSTS.UI.GENERIC.SELECT_REVIEWER_EMPTY }
      });
    } else if (!isValidFutureDate(completedByDate)) {
      dispatch({
        type: ASSIGN_APPLICATION,
        payload: { alert: ERROR_CONSTS.UI.GENERIC.SELECT_COMPLETEDBYDATE_EMPTY }
      });
    } else {
      dispatch(
        reassignApplication({
          applicantEmployeeId,
          committeeId,
          term,
          reviewerEmployeeIdFrom: reviewer.emplid,
          reviewerEmployeeIdTo: secondReviewer.emplid,
          reviewerCompletedByDueDate: completedByDate,
        })
      );
    }
  }

  switch (type) {
    case ASSIGN_APPLICATION:
      actions.push({
        label: dialogState?.status === STATUS?.LOADING ? "Assigning" : "Assign",
        id: "action-button-assign",
        onClick: () => {
          handleAssignApplication()
        },
        color: "primary",
        variant: "contained",
        border: 'none',
      });
      break;
    case REPORT_CONFLICT:
      //future state is to leverage step functions to be able to send email to administrator when this action is called
      actions.push({
        label: dialogState?.status === STATUS?.LOADING ? "Reporting" : "Report",
        id: "action-button-resume-later",
        onClick: () => {
          reportAppConflict()
        },
        color: "primary",
        variant: "contained",
        border: 'none',
      });
      break;
    case SAVE_REVIEW:
      actions.push({
        label: dialogState?.status === STATUS?.LOADING ? "Saving" : "Save",
        id: "action-button-resume-later",
        onClick: () => {
          handleSaveReview()
        },
        color: "primary",
        variant: "contained",
        border: 'none',
      });
      break;
    case REASSIGN_APPLICATION:
      actions.push({
        label: dialogState?.status === STATUS?.LOADING ? "Reassigning" : "Reassign",
        id: "action-button-reassign-reviewer",
        onClick: () => {
          handleReassignApplication()
        },
        color: "primary",
        variant: "contained",
        border: 'none',
      });
      break;
    case UNASSIGN_APPLICATION:
      actions.push({
        label: dialogState?.status === STATUS?.LOADING ? "Unassigning" : "Unassign",
        id: "action-button-unassign-reviewer",
        onClick: () => {
          handleUnassignApplication()
        },
        color: "primary",
        variant: "contained",
        border: 'none',
      });
      break;
    case OVERRIDE_RECOMMENDATION:
      actions.push({
        label: "Unlock",
        id: "action-button-override-rec",
        onClick: () => {
          pageAppHeader.unlock();
          onClose();
        },
        color: "primary",
        variant: "contained",
        border: 'none',
      });
      break;
    case RESTART_APPLICATION:
      actions.push({
        label: dialogState?.status === STATUS?.LOADING ? "Restarting" : "Restart",
        id: "action-button-restart-eval",
        onClick: () => {
          restartEval();
        },
        color: "primary",
        variant: "contained",
        border: 'none',
      });
      break;
    case NOTIFY_ADMIN:
      actions.push({
        label: dialogState?.status === STATUS?.LOADING ? "Sending" : "Send",
        disabled: dialogState?.disabled,
        id: "action-button-notify-admin",
        onClick: () => {
          handleNotifyAdmin()
        },
        color: "primary",
        variant: "contained",
        border: 'none',
      });
      break;
    case CLEAR_NOTIFY_ADMIN:
        actions.push({
          label: dialogState?.status === STATUS?.LOADING ? "Clearing" : "Clear",
          id: "action-button-clear-notify-admin",
          onClick: () => {
            handleClearNotifyAdmin()
          },
          color: "primary",
        variant: "contained",
        border: 'none',
        });
        break;
    case CREATE_TRAINING:
      actions.push({
        label: dialogState?.status === STATUS?.LOADING ? "Creating..." : "Create",
        id: "action-button-create-training",
        onClick: () => {
          handleCreateTraining()
        },
        color: "primary",
        variant: "contained",
        border: 'none',
      });
      break;
    case ASSIGN_TRAINEE:
        actions.push({
          label: dialogState?.status === STATUS?.LOADING ? "Assigning..." : "Assign",
          id: "action-button-assign-trainee",
          onClick: () => {
            handleCreateTraining()
          },
          color: "primary",
          variant: "contained",
          border: 'none',
        });
        break;
    case UNASSIGN_TRAINEE:
      actions.push({
        label: dialogState?.status === STATUS?.LOADING ? "Unassigning..." : "Unassign",
        id: "action-button-unassign-training",
        onClick: () => {
          handleUnassignTrainee()
        },
        color: "primary",
        variant: "contained",
        border: 'none',
      });
      break;
    case UPDATE_PRIORITY:
          actions.push({
            label: dialogState?.status === STATUS?.LOADING ? "Updating..." : "Update",
            id: "action-button-update-priority",
            onClick: () => {
              handleUpdatePriority()
            },
            color: "primary",
            variant: "contained",
            border: 'none',
          });
          break;
    default:
      break;
  }

  return {
    actions
  }
};

export default useActionsDialog;



