import { API } from 'aws-amplify';
import { REPORTS_DASH } from '../actions-index';
import { SEVERITY_LEVELS, STATUS } from "../util/constants";
import { listCustomReportsQuery } from './query';

export const listCustomReports = () => {
    return async (dispatch) => {
        const type = REPORTS_DASH;

        try {
            dispatch({ type, payload: { status: STATUS.LOADING } });
            const query = listCustomReportsQuery;
            const response = await API.graphql({ query });

            if (response) {
                const payload = { status: STATUS.SUCCESS, data: response?.data?.listCustomReports ?? [] };
                dispatch({ type, payload });
                return;
            }

            // Handle errors
            const errorPayload = {
                status: STATUS.ERROR,
                alert: {
                    MESSAGE: response?.data?.listCustomReports?.error?.message,
                    SEVERITY: SEVERITY_LEVELS.ERROR,
                },
            };

            dispatch({ type, payload: errorPayload });
        } catch (error) {
            console.error(error);
            console.error(error?.errors?.[0]?.message);

            const errorPayload = {
                status: STATUS.ERROR,
                alert: {
                    MESSAGE: error?.errors?.[0]?.message,
                    SEVERITY: SEVERITY_LEVELS.ERROR,
                },
            };
            dispatch({ type, payload: errorPayload });
        }
    };
};