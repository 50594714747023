import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ERROR_CONSTS, STATUS } from '../../util/constants';
import { useAlertBarStatus } from './useAlertBarStatus';

export const AlertBar = (props) => {
  const { children } = props;
  const dispatch = useDispatch();
  const focusRef = useRef(null);
  const status = useAlertBarStatus();
  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    Transition: Slide,
  });

  const { vertical, horizontal, open } = state;

  useEffect(() => {
    focusRef?.current?.focus();
  });

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      open: status?.length > 0,
    }));
  }, [status]);

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  useEffect(() => {
    state?.open === false &&
      // status.find((item) => {
      //   return item?.type === FETCH_SEARCH_RESULTS;
      // }) &&
      setTimeout(() => {
        status?.forEach((item) => {
          dispatch({
            type: item?.type,
            payload: {
              status: null,
              alert: ERROR_CONSTS.UI.GENERIC.EMPTY_ALERT,
              details: '',
            },
          });
        });
      }, 500);
  }, [state]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        TransitionComponent={state.Transition}
      >
        <Alert
          onClose={handleClose}
          role="alert"
          severity={
            status?.find(
              (item) => item?.status?.toUpperCase() === STATUS.ERROR
            ) && STATUS.ERROR.toLowerCase()
          }
          style={{ display: 'flex', alignItems: 'center', padding: '1rem' }}
        >
          {status?.map((item) => {
            return item?.alert?.MESSAGE + ' ';
          })}
        </Alert>
      </Snackbar>

      {children}
    </>
  );
};
