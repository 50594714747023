import { API } from 'aws-amplify';
import { getSearchResultsQuery } from '../AuditLookup/query';
import { FETCH_SEARCH_RESULTS, SET_SEARCH_VALUE } from '../actions-index';
import { SEVERITY_LEVELS, STATUS } from '../util/constants';
import { searchCommitteeReviewers } from './query';

export const fetchSearchResults = ( applicantEmployeeId ) => {
  return async (dispatch) => {
    const type = FETCH_SEARCH_RESULTS;
    dispatch({ type, payload: { status: STATUS?.LOADING } });

    try {
      const query = getSearchResultsQuery
      const variables = { applicantEmployeeId };
      let response = await API.graphql({ query, variables });

      if (
        response?.data?.getSearchResults?.status?.toUpperCase()
      ) {
        const result = response?.data?.getSearchResults?.data

        dispatch({
          type,
          payload: {
            status: STATUS?.SUCCESS,
            data: result
          },
        });
      } else {
        dispatch({
          type,
          payload: {
            status: STATUS?.ERROR,
            alert: {
              MESSAGE: response?.data?.getSearchResults?.error?.message,
              SEVERITY: SEVERITY_LEVELS?.ERROR,
            },
          },
        });
      }
    } catch (error) {
      dispatch({
        type,
        payload: {
          status: STATUS?.ERROR,
          alert: {
            MESSAGE: error?.errors[0]?.message,
            SEVERITY: SEVERITY_LEVELS.ERROR,
          },
        },
      });
    }
  };
};

export const setSearchValue = (value) => {
  let response;

  return async (dispatch) => {
    try {
      dispatch({
        type: SET_SEARCH_VALUE,
        payload: { searchValue: value },
      });
    } catch (error) {
      dispatch({
        type: SET_SEARCH_VALUE,
        payload: { searchValueError: response.statusText },
      });
    }
  };
};

export const fetchSearchResultsByNameNumber = (value) => {
  let response;

  return async (dispatch) => {
    dispatch({
      type: FETCH_SEARCH_RESULTS,
      payload: { status: STATUS?.LOADING },
    });

    try {
      response = await API.graphql({
        query: searchCommitteeReviewers,
        variables: {
          searchString: value,
        },
      });
      if (response?.data?.searchCommitteeReviewers?.data?.length > 0) {
        dispatch({
          type: FETCH_SEARCH_RESULTS,
          payload: {
            status: STATUS?.SUCCESS,
            data: response.data.searchCommitteeReviewers.data
          },
        });
      } else {
        dispatch({
          type: FETCH_SEARCH_RESULTS,
          payload: {
            status: STATUS?.ERROR,
            alert: {
              MESSAGE:
                response?.data?.searchCommitteeReviewers?.error
                  ?.message,
              SEVERITY: SEVERITY_LEVELS?.ERROR,
            },
          },
        });
      }
    } catch (error) {
      console.log("error:", error)
      dispatch({
        type: FETCH_SEARCH_RESULTS,
        payload: {
          status: STATUS?.ERROR,
          alert: {
            MESSAGE: error?.errors[0]?.message,
            SEVERITY: SEVERITY_LEVELS.ERROR,
          },
        },
      });
    }
  };
};
