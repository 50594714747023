import { FETCH_ONE_READER, UPDATE_MANAGE_REVIEWER } from '../actions-index';
import { ERROR_CONSTS } from "../util/constants";

const initialState = {
  status: '',
  alert: ERROR_CONSTS.UI.GENERIC.EMPTY_ERROR_OBJECT,
}
export const ManageReaderReducer = (
  state = { reviewer: [], loading: false, error: null },
  action = {}
) => {
  switch (action.type) {
    case FETCH_ONE_READER:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export const updateManageReviewerReducer = (
  state = { updateReviewer: { ...initialState, type: UPDATE_MANAGE_REVIEWER } },
  action = {}
) => {
  switch (action.type) {
    case UPDATE_MANAGE_REVIEWER:
      return {
        ...state,
        updateReviewer: {
          status: action.payload.status,
          alert: action.payload.alert,
          type: UPDATE_MANAGE_REVIEWER
        }
      };
    default:
      return state;
  }
};
