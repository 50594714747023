import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fetchSearchResults, fetchSearchResultsByNameNumber, setSearchValue } from "../../actions-index";
import Navbar from "../components";

const mapStateToProps = (state) => {
  return {
    searchValue: state.navbarSearchReducer.searchValue,
    searchResults: state.navbarSearchReducer.searchResults,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSearchResults: (value) => dispatch(fetchSearchResults(value)),
    setSearchValue: (value) => dispatch(setSearchValue(value)),
    fetchSearchResultsByNameNumber: (value) => dispatch(fetchSearchResultsByNameNumber(value)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));
