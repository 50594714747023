export const getManageApplicationsDataQuery = `query GetManageApplicationsDataQuery {
    getManageApplicationsData {
        committeesDetails {
            committeeId
            committeeName
            reviewers {
                reviewerEmployeeId
                firstName
                lastName
                campusId
            }
        }
        columns {
            key
            label
            width
        }
        filterOptionsAsJsonArr
    }
}`;

export const listAssignableApplicationsQuery = `query ListAssignableApplicationsQuery($reviewerEmployeeId: String!, $committeeId: String!, $filtersAsJsonArr: String, $lastEvaluatedKey: String, $limit: Int) {
    listAssignableApplications(reviewerEmployeeId: $reviewerEmployeeId, committeeId: $committeeId, filtersAsJsonArr: $filtersAsJsonArr, lastEvaluatedKey: $lastEvaluatedKey, limit: $limit) {
        applications {
            applicantEmployeeId
            committeeId
            term
            dataAsJsonMap
        }
        lastKey
    }
}`;

export const listAssignedApplicationsQuery = `query ListAssignedApplicationsQuery($reviewerEmployeeId: String!, $committeeId: String!, $countsOnly: Boolean) {
    listAssignedApplications(reviewerEmployeeId: $reviewerEmployeeId, committeeId: $committeeId, countsOnly: $countsOnly) {
        applications {
            applicantEmployeeId
            committeeId
            term
            dataAsJsonMap
        }
        quota
        assigned {
            committeeName
            count
        }
    }
}`;

export const getReviewerDetailsByCommitteeQuery = `query GetReviewerDetailsByCommittee($committeeId: String!) {
    getReviewerDetailsByCommittee(committeeId: $committeeId) {
        reviewers {            
            reviewerEmployeeId
            lastName
            lastAssignedDate
            firstName
            committeeDetails {
              submitted
              pending
              lastAssignedDate
              experience
              jobType
              groupType
              committeeId
              admin
            }
            campusId
          }
        }
    }`;

export const getReviewerDetailsByIdQuery = `query GetReviewerDetailsById($reviewerEmployeeId: String!) {
    getReviewerDetailsById(reviewerEmployeeId: $reviewerEmployeeId) {
        committeeDetails {
            committeeId
            experience
            lastAssignedDate
            pending
            submitted
            total
          }
          campusId
          firstName
          lastAssignedDate
          lastName
          reviewerEmployeeId
          totalPending
          totalSubmitted
        }
      }`;

export const getCommitteeDetailsQuery = `query GetCommitteeDetails($committeeId: String!) {
        getCommitteeDetails(committeeId: $committeeId) {
            committeeId
            allTotal
            earlyActionTotal
            onTimeTotal
            all1
            all2
            all3
            all4
            earlyAction1
            earlyAction2
            earlyAction3
            earlyAction4
            onTime1
            onTime2
            onTime3
            onTime4
        }
    }`;