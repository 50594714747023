export const saveReviewMutation = `mutation SaveReview($input: SaveReviewInput) {
    saveReview(input: $input) {
        status
        details
        error {
            message
            date
        }
    }
}`;

export const submitReviewMutation = `mutation SubmitReview($input: SubmitReviewInput) {
    submitReview(input: $input) {
        status
        details
        error {
            message
            date
        }
    }
}`;



export const submitTrainingMutation = `mutation SubmitTraining($input: SubmitTrainingInput) {
  submitTraining(input: $input) {
      status
      details
      error {
          message
          date
      }
      context {
        key
        value
      }
  }
}`;

export const overrideRecommendationMutation = `mutation SubmitOverride($input: SubmitOverrideInput) {
  submitOverride(input: $input) {
      status
      details
      error {
          message
          date
      }
  }
}`;
export const createTrainingMutation = `mutation CreateTraining($input: CreateTrainingInput!) {
  createTraining(input: $input) {
      status
      details
      error {
          message
          date
      }
  }
}`;


export const updatePriorityMutation = `mutation updateApplicationPriority($input: UpdatePriorityInput!) {
  updateApplicationPriority(input: $input) {
      status
      details
      error {
          message
          date
      }
  }
}`;

export const unassignTraineeMutation = `mutation UnassignTrainee($input: UnassignTraineeInput!) {
  unassignTrainee(input: $input) {
      status
      details
      error {
          message
          date
      }
  }
}`;

export const assignApplicationMutation = `mutation AssignApplication($input: AssignApplicationInput) {
    assignApplication(input: $input) {
        data {
            firstName
            assignTime
            comments {
              othersConcerns {
                label
                order
                parentLabel
              }
              notify {
                label
                order
                parentLabel
              }
              noncompetitive {
                label
                order
                parentLabel
              }
              evaluation {
                label
                order
                parentLabel
              }
              document {
                label
                order
                parentLabel
              }
              competitive {
                label
                order
                parentLabel
              }
              adminOverride
            }
            lastName
            recommendation
            reviewerEmployeeId
            stage
            submitTime
            type
          }
          details
          error {
            date
            message
          }
          status
          applicantEmployeeId
        }
}`;

export const unassignApplicationMutation = `mutation UnassignApplication($input: UnassignApplicationInput) {
    unassignApplication(input: $input) {
      data {
        comments {
          adminOverride
        }
        assignTime
        firstName
        lastName
        recommendation
        reviewerEmployeeId
        stage
        submitTime
        type
      }
      error {
        date
        message
      }
      applicantEmployeeId
      details
      status
}
}`;
export const reportConflictMutation = `mutation ReportConflict($input: ReportConflictInput) {
    reportConflict(input: $input) {
        status
        details
        error {
            message
            date
        }
    }
}`;

export const restartEvaluationMutation = `mutation RestartEvaluation($input: RestartEvaluationInput) {
    restartEvaluation(input: $input) {
        data {
            comments {
              adminOverride
            }
            assignTime
            firstName
            lastName
            recommendation
            reviewerEmployeeId
            stage
            submitTime
            type
          }
          error {
            date
            message
          }
          applicantEmployeeId
          details
          status
    }
}`;

export const notifyAdminMutation = `mutation NotifyAdmin($input: NotifyAdminInput) {
    notifyAdmin(input: $input) {
        status
        details
        error {
            message
            date
        }
    }
}`

export const reassignApplicationMutation = `mutation ReassignApplication($input: ReassignApplicationInput) {
    reassignApplication(input: $input) {
      status
      details
      error {
        date
        message
      }
      applicantEmployeeId
      data {
        comments {
          adminOverride
        }
        assignTime
        firstName
        lastName
        recommendation
        reviewerEmployeeId
        stage
        submitTime
        type
      }
    }
}`

export const clearNotifyAdminMutation = `mutation ClearNotifyAdmin($input: ClearNotifyAdminInput!) {
    clearNotifyAdmin(input: $input) {
        status
        details
        error {
            message
            date
        }
    }
}`;

export const holdForExportMutation = `mutation HoldForExport($input: HoldForExportInput!) {
    holdForExport(input: $input) {
      applicantEmployeeId
      committeeId
      holdForExport
      term
    }
}`;