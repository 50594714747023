import { Mui } from "@osu/react-ui";
import { useAlertHandling } from "../../../Common/hooks/useAlertHandling";
import { STATUS } from "../../../util/constants";
import useActionsDialog from "./hooks/useActionsDialog";

const Dialog = (props = {}) => {
  const { open, onClose, title, type, description, reviewer, secondReviewer, completedByDate, children, pageAppHeader, dialogState, data, localError } = props;
  const alert = useAlertHandling({ alert: dialogState?.alert })
  
  const { actions } = useActionsDialog({ 
    type, 
    onClose, 
    reviewer,
    secondReviewer,
    completedByDate,
    pageAppHeader,
    dialogState,
    data
  })
  
  return (
    <Mui.Dialog
      open={open}
      onClose={onClose}
      aria-live='polite'
      pageAppHeader={pageAppHeader}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth='sm'
    >
    {alert}
      <Mui.DialogTitle id="alert-dialog-title">{title}</Mui.DialogTitle>
      <Mui.DialogContent >
        <Mui.DialogContentText id="alert-dialog-description" >
          {description}
        </Mui.DialogContentText>
        {children}
      </Mui.DialogContent>
      <Mui.DialogActions className="padding-2">
        {actions.map(({ id, label, border, ...restOfAction }, index) => (
          <Mui.Button key={`action-btn-${index}-${id}`} variant="contained" color="primary" style={{ border: border }} disabled={dialogState?.status === STATUS.LOADING || (dialogState?.disabled && label.toUpperCase() !== STATUS?.CANCEL) ||  localError} {...restOfAction}>
            { dialogState?.status === STATUS.LOADING && label !== 'Cancel' && <Mui.CircularProgress size={20} style={{marginRight: '.5rem'}}/> } {label}
          </Mui.Button>
        ))}
      </Mui.DialogActions>
    </Mui.Dialog>
  );
}

export default Dialog
