import useRoleAuthentication from "../../../../Common/hooks/useRoleAuthentication";
import { AUTH_TYPES } from "../../../../util/constants";
import { menuItems } from "../enums";

const useApplicantMenuOptions = (extraInfo) => {
    const authentication = useRoleAuthentication();
    const currentRole = getCurrentRole(authentication);

    let actionsButtonMenuItems = menuItems.filter(menuItem => {
        const roles = menuItem.roles ?? [];
        const isCorrectRole = roles.includes(currentRole);
        extraInfo.menuItemType = menuItem.type

        if (!isCorrectRole) { return false; } // Exclude menu item

        if (extraInfo.isTraining && !menuItem.includeOnTrainingPage) { return false; }
        
        const filterFns = menuItem.specialCaseFilterFns ?? []; // Default to empty list of filtering fns

        // Check all the special exclusion cases
        for (let filterFn of filterFns) {
            if (!filterFn(extraInfo)) { return false; }
        }

        return true;
    });

    return {
        menuItems: actionsButtonMenuItems
    };
}

function getCurrentRole(authenticationInfo) {
    if (authenticationInfo[AUTH_TYPES.ADMIN_REVIEWER])  { return AUTH_TYPES.ADMIN_REVIEWER; }
    if (authenticationInfo[AUTH_TYPES.ADMIN])           { return AUTH_TYPES.ADMIN; }
    if (authenticationInfo[AUTH_TYPES.REVIEWER])        { return AUTH_TYPES.REVIEWER; }
    
    return undefined;
}

export default useApplicantMenuOptions
