import React from 'react';
import { useSelector } from 'react-redux';
import { Mui } from '@osu/react-ui';
import ArrowIcon from '@material-ui/icons/ArrowDownward';
import useApplicantMenuOptions from './hooks/useApplicantActions';
import useRoleAuthentication from '../../../Common/hooks/useRoleAuthentication';
import { getStudentState } from '../../../Common/redux/selectors';
import { makeStyles } from '@material-ui/core';
import { STATUSES } from '../../../util/constants';

const useStyles = makeStyles((theme) => ({
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '1rem',
    color: theme.palette.colors.blue,
  },
  menuItem: {
    padding: '.7rem 1rem .7rem 1rem',
    borderLeft: `4px solid white`,
    '&:hover': {
      borderLeft: `4px solid ${theme.palette.colors.blue}`,
    },
    minWidth: '16rem'
  },
  muiList: {
    '& .MuiMenu-list': {
      // paddingTop: 0,
      // paddingBottom: 0,
      minWwidth: '15rem',
    },
  },
}));

const Menu = (props) => {
  const classes = useStyles();
  const authDetails = useRoleAuthentication();
  const { selectMenuItem, menuAnchorEl, setMenuAnchorEl, isTraining, pageAppHeader } = props;

  const studentData = useSelector((state) => getStudentState(state)).data;
    
  const { assignable, status, isNotifyAdminActive, notifyAdminReviewerEmplids } = useSelector((state) => {
    const studentInfo = getStudentState(state)?.data ?? {};
    const notifyAdminReviewerEmplids = studentInfo?.readers
        ?.filter(reviewer => reviewer.adminAction === STATUSES.NOTIFY)
        ?.map(reviewer => reviewer?.readerEmployeeId);

    return {
      assignable: studentInfo?.demographics?.assignable,
      status: studentInfo?.demographics?.status,
      isNotifyAdminActive: studentInfo?.adminAction === STATUSES.NOTIFY,
      notifyAdminReviewerEmplids
    };
  });

  const isCurrentReviewerNotifyAdmin = (notifyAdminReviewerEmplids ?? []).includes(pageAppHeader?.userId);
  
  const handleClick = (event) => { setMenuAnchorEl(event.currentTarget); };
  const handleClose = () => { setMenuAnchorEl(null); };
  const disableActionMenu = (authDetails.ADMIN !== true && (isTraining || isCurrentReviewerNotifyAdmin)) || status?.includes(STATUSES.CANCELLED);

  const applicantTrainingDoesntExist = !(pageAppHeader.applicantTrainingExists === true);

  const extraInfo = {
    assignable,
    status,
    isNotifyAdminActive,
    isCurrentReviewerNotifyAdmin,
    priority: studentData?.priority,

    applicantTrainingDoesntExist,
    isTraining,
  };
  
  let { menuItems } = useApplicantMenuOptions(extraInfo);

  // console.log("extraInfo, authDetails, isTraining, status, training exists", extraInfo, authDetails, isTraining, status, pageAppHeader.applicantTrainingExists);

  return (
    <React.Fragment>
      <Mui.Button
        startIcon={<ArrowIcon />}
        variant="contained"
        color="primary"
        onClick={handleClick}
        disabled={disableActionMenu}
      >
        Actions
      </Mui.Button>
      <Mui.Menu
        id="applicant-menu"
        className={classes.muiList}
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={handleClose}
      >
        {
          menuItems.map(({ type, title, icon }, index) => {
            return (
              <Mui.Box key={`${type}-${index}`}>
                <Mui.MenuItem
                  onClick={() => selectMenuItem({ type })}
                  className={classes.menuItem}
                >
                  <Mui.Box className={classes.icon}>{icon}</Mui.Box>
                  <Mui.Box>{title}</Mui.Box>  
                </Mui.MenuItem>
              </Mui.Box>
            );
          })
        }
      </Mui.Menu>
    </React.Fragment>
  );
};

export default Menu;