export const SET_USER = "SET_USER";
export const USER_IS_LOADING = "USER_IS_LOADING";
export const USER_STATUS = "USER_STATUS";
export const FETCH_USER = "FETCH_USER";

// Actions Menu
export const GET_TRAINING_CONFIG = "GET_TRAINING_CONFIG";

// Manage Applicant
export const FETCH_STUDENT = "FETCH_STUDENT";
export const SAVE_REVIEW = "SAVE_REVIEW";
export const SUBMIT_RECOMMENDATION = "SUBMIT_RECOMMENDATION";
export const ASSIGN_APPLICATION = "ASSIGN_APPLICATION";
export const UNASSIGN_APPLICATION = "UNASSIGN_APPLICATION";
export const REASSIGN_APPLICATION = "REASSIGN_APPLICATION";
export const OVERRIDE_RECOMMENDATION = "OVERRIDE_RECOMMENDATION";
export const REPORT_CONFLICT = "REPORT_CONFLICT";
export const RESTART_APPLICATION = "RESTART_APPLICATION";
export const GET_COMMITTEE_REVIEWERS = "GET_COMMITTEE_REVIEWERS";
export const SEARCH_DOCUMENTS = "SEARCH_DOCUMENTS";
export const GET_DOCUMENT = "GET_DOCUMENT";
export const NOTIFY_ADMIN = "NOTIFY_ADMIN";
export const CLEAR_NOTIFY_ADMIN = "CLEAR_NOTIFY_ADMIN";
export const MENU_CHANGE = 'MENU_CHANGE';
export const SELECT_REVIEWER = 'SELECT_REVIEWER';
export const CREATE_TRAINING_CHOICES = 'CREATE_TRAINING_CHOICES';
export const SELECT_SECOND_REVIEWER = "SELECT_SECOND_REVIEWER";
export const SELECT_COMPLETEDBYDATE = "SELECT_COMPLETEDBYDATE";
export const ALERT = 'ALERT';
export const UPDATE_RECOMMENDATION_HISTORY = 'UPDATE_RECOMMENDATION_HISTORY';
export const ADD_TO_RECOMMENDATION_HISTORY = 'ADD_TO_RECOMMENDATION_HISTORY';
export const CLEANUP_APPLICANT_DETAIL = 'CLEANUP_APPLICANT_DETAIL';
export const CLEANUP_SEARCH_RESULTS = 'CLEANUP_SEARCH_RESULTS';
export const CREATE_TRAINING = 'CREATE_TRAINING';
export const ASSIGN_TRAINEE = 'ASSIGN_TRAINEE';
export const UNASSIGN_TRAINEE = 'UNASSIGN_TRAINEE';
export const PRIORITIZE_APPLICATION = 'PRIORITIZE_APPLICATION';
export const UPDATE_PRIORITY = 'UPDATE_PRIORITY';
export const HOLD_FOR_EXPORT = 'HOLD_FOR_EXPORT';
export const EXPECTED_RECOMMENDATION = 'EXPECTED_RECOMMENDATION';

// Search & NavSearch
export const GET_ULTIMATE_SEARCH_RESULTS = 'GET_ULTIMATE_SEARCH_RESULTS';

// Dashboard
export const GET_COMMITTEE_LIST = "GET_COMMITTEE_LIST";
export const GET_IN_PROGRESS = "GET_IN_PROGRESS";
export const GET_NEXT_APP = "GET_NEXT_APP"; 
export const CLEANUP_NEXTAPP = "CLEANUP_NEXTAPP"; 

// Manage Reviewers
export const FETCH_ALL_REVIEWERS = "FETCH_ALL_REVIEWERS";
export const FETCH_ALL_REVIEWERS_ACTIVE = "FETCH_ALL_REVIEWERS_ACTIVE"
export const BULK_ASSIGN = "BULK_ASSIGN";
export const ACTIVATE_REVIEWERS = "ACTIVATE_REVIEWERS";
export const UPDATE_REVIEWERS = 'UPDATE_REVIEWERS';
export const UPDATE_REVIEWER_GOALS = 'UPDATE_REVIEWER_GOALS';
export const UPDATE_REVIEWERS_STATUS = 'UPDATE_REVIEWERS_STATUS';

// Manage Reader
export const FETCH_ONE_READER = "FETCH_READER";
export const UPDATE_MANAGE_REVIEWER = "UPDATE_MANAGE_REVIEWER";

// Manage Applications
export const FETCH_MANAGE_APPLICATIONS_DATA = "FETCH_MANAGE_APPLICATIONS_DATA";
export const FETCH_ASSIGNABLE_APPLICATIONS = "FETCH_ASSIGNABLE_APPLICATIONS";
export const MANUAL_BATCH_ASSIGN_APPLICATIONS = "MANUAL_BATCH_ASSIGN_APPLICATIONS";
export const MANUAL_BATCH_UNASSIGN_APPLICATIONS = "MANUAL_BATCH_UNASSIGN_APPLICATIONS";
export const FETCH_ASSIGNED_APPLICATIONS = "FETCH_ASSIGNED_APPLICATIONS";
export const CLEAR_MESSAGES = "CLEAR_MESSAGES";
export const SUBSCRIBE_REVIEWER_CHANGES = "SUBSCRIBE_REVIEWER_CHANGES";
export const SUBSCRIBE_COMMITTEE_CHANGES = "SUBSCRIBE_COMMITTEE_CHANGES";
export const FETCH_REVIEWER_DETAILS_BY_COMMITTEE = "FETCH_REVIEWER_DETAILS_BY_COMMITTEE";
export const FETCH_COMMITTEE_DETAILS = "FETCH_COMMITTEE_DETAILS";
export const SET_SELECTED_REVIEWER = "SET_SELECTED_REVIEWER";
export const FETCH_REVIEWER_BY_ID = "FETCH_REVIEWER_BY_ID";

// Admission Cycle
export const LIST_ADMISSIONS_CYCLES = "LIST_ADMISSIONS_CYCLES";
export const SAVE_ADMISSIONS_CYCLE = "SAVE_ADMISSIONS_CYCLE";

// Navbar
export const FETCH_SEARCH_RESULTS = "FETCH_SEARCH_RESULTS";
export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE";

// Reports
export const REPORTS_DASH = "REPORTS_DASH";
export const FETCH_REPORTS_EXPORTS = "FETCH_REPORTS_EXPORTS";
export const REPORTS_EXPORTS_DOWNLOAD_START = "REPORTS_EXPORTS_DOWNLOAD_START";
export const REPORTS_EXPORTS_DOWNLOAD_STOP = "REPORTS_EXPORTS_DOWNLOAD_STOP";
export const REPORTS_EXPORTS_DOWNLOAD_ERROR = "REPORTS_EXPORTS_DOWNLOAD_ERROR";
export const EXPORTS_SAVE_TASKCOMPLETE = "EXPORTS_SAVE_TASKCOMPLETE";
export const GET_REPORT = "GET_REPORT";
export const GET_CUSTOMFILTEROPTIONS = "GET_CUSTOMFILTEROPTIONS";
export const GET_REPORT_ATHENA = "GET_REPORT_ATHENA";
export const GET_REPORT_ATHENA_ERROR = "GET_REPORT_ATHENA_ERROR";
export const GET_REPORT_ATHENA_CHANGE_REPORTID = "GET_REPORT_ATHENA_CHANGE_REPORTID";
export const RESET_REPORTSALERTS = "RESET_REPORTSALERTS";
export const RESET_REPORTSFILTERALERTS = "RESET_REPORTSFILTERALERTS";
export const RESET_REPORT_ATHENA_COUNT = "RESET_REPORT_ATHENA_COUNT";
export const EXPORT_RECOMMENDATION_REPORT = "EXPORT_RECOMMENDATION_REPORT";
export const EXPORT_ADMINREVIEW_REPORT = "EXPORT_ADMINREVIEW_REPORT";
export const CUSTOM_REPORTS = "CUSTOM_REPORTS";
export const EXPORT_REPORT_MESSAGE = "EXPORT_REPORT_MESSAGE";

/* Export each action file below */
export * from "./Authentication/actions";
export * from "./AssignDrawer/actions";
export * from "./Applicant/actions";
export * from "./Dashboard/actions";
export * from "./ManageReviewersBulkAssign/actions";
export * from "./ManageActiveReviewers/actions";
export * from "./ManageReviewer/actions";
export * from "./ManageApplications/actions";
export * from "./ManageAdmissionsCycles/actions";
export * from "./Navbar/actions";
export * from "./Common/components/reports/actions";
export * from "./ReportsDash/actions";
export * from "./ReportsExports/actions";
export * from "./ReportAdminReviews/actions";
export * from "./ReportRecommendations/actions";
export * from "./ReportAthenaCustom/actions";
export * from "./AuditLookup/actions";