import useRoleAuthentication from "../../Common/hooks/useRoleAuthentication";

function Unauthorized(props = {}) {
  const authDetails = useRoleAuthentication();
  return (
    <span>
      You are not authorized to see this page!
      <br />
      {!authDetails.HAS_ROLES && <span>Please request access</span>}
    </span>
  );
}

export default Unauthorized;
