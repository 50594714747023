import { FETCH_REPORTS_EXPORTS, REPORTS_EXPORTS_DOWNLOAD_START, REPORTS_EXPORTS_DOWNLOAD_STOP, REPORTS_EXPORTS_DOWNLOAD_ERROR, EXPORTS_SAVE_TASKCOMPLETE } from '../actions-index';
import { ERROR_CONSTS, STATUS } from '../util/constants';

const initialState = {
    status: STATUS.LOADING,
    alert: ERROR_CONSTS.UI.GENERIC.EMPTY_ALERT,
    data: [],
};

export const reportsExportsReducer = (
    state = {
        status: STATUS.LOADING,
        alert: ERROR_CONSTS.UI.GENERIC.EMPTY_ALERT,
        data: [],
        isGeneratingDownload: false,
        type: FETCH_REPORTS_EXPORTS,
    },
    action = {}
) => {
    switch (action.type) {
        case FETCH_REPORTS_EXPORTS: {
            return {
                ...state,
                status: action.payload.status ?? initialState.status,
                downloadErrorMessage: action.payload.alert?.MESSAGE ?? initialState.alert?.MESSAGE,
                data: action.payload.data ?? initialState.data,
            }
        }

        case REPORTS_EXPORTS_DOWNLOAD_START : { return { ...state, isGeneratingDownload: true }; }
        case REPORTS_EXPORTS_DOWNLOAD_STOP  : { return { ...state, isGeneratingDownload: false }; }
        case REPORTS_EXPORTS_DOWNLOAD_ERROR: {
            const downloadErrorMessage = action.payload ?? null; 
            return { ...state, downloadErrorMessage, isGeneratingDownload: false };
        }

        case EXPORTS_SAVE_TASKCOMPLETE: {
            return {
                ...state,
                ...getUpdatedTaskData(action.payload, state.data),
            }
        }

        default: return state;
    }
};

function getUpdatedTaskData(payload, exports) {
    const { executionId, recommendationKey, saving, error, isComplete } = payload;
    
    let exportsCopy = JSON.parse(JSON.stringify(exports));
    let currentExport = exportsCopy.find(currentExport => currentExport.executionId === executionId);
    let currentRecommendationLink = currentExport.recommendations?.find?.(recommendation => recommendation.recommendation === recommendationKey);
    
    if (saving) {
        currentRecommendationLink.isSaving = saving;
        currentRecommendationLink.isError = null;
    } else {
        currentRecommendationLink.isSaving = false;
        currentRecommendationLink.isError = error ? true : false;
        currentRecommendationLink.error = error; // Should probably do something more with this on the screen... oh well
        if (typeof isComplete !== "undefined") { currentRecommendationLink.isComplete = isComplete; }
    }

    let taskErrorMessages = exportsCopy.reduce((errorArray, currentExport) => {
        currentExport.recommendations?.forEach(recommendation => {
            if (recommendation.isError) {
                errorArray.push(JSON.stringify(recommendation.error));
            }
        });

        return errorArray;
    }, []);

    return { data: exportsCopy, taskErrorMessages };
}