import { API } from 'aws-amplify';
import { EXPORT_RECOMMENDATION_REPORT } from '../actions-index';
import { SEVERITY_LEVELS, STATUS } from '../util/constants';
import { exportRecommendationsReportsMutation } from './mutation';

export const exportRecommendationReportApproval = (committeeId, applicantEmployeeIdsAndTerms) => {
  return async (dispatch) => {
    const type = EXPORT_RECOMMENDATION_REPORT;

    try {
      dispatch({ type, payload: { status: STATUS.LOADING } });
      const variables = { committeeId, applicantEmployeeIdsAndTerms };
      
      const response = await API.graphql({
        query: exportRecommendationsReportsMutation,
        variables: { input: variables },
      });
      if (
        response.data.exportRecommendationsReports.status.toUpperCase() ===
        STATUS.SUCCESS
      ) {
        const payload = {
          status: STATUS.SUCCESS,
          alert: { MESSAGE: '', SEVERITY: SEVERITY_LEVELS?.SUCCESS },
        };
        dispatch({ type, payload });
        return;
      }

      // Handle errors
      const errorPayload = {
        status: STATUS.ERROR,
        alert: {
          MESSAGE: response?.data?.exportRecommendationsReports?.error?.message,
          SEVERITY: SEVERITY_LEVELS.ERROR,
        },
      };
      dispatch({ type, payload: errorPayload });
    } catch (error) {
      console.error(error);
      console.error(error?.errors?.[0]?.message);

      const errorPayload = {
        status: STATUS.ERROR,
        alert: {
          MESSAGE: error?.errors?.[0]?.message,
          SEVERITY: SEVERITY_LEVELS.ERROR,
        },
      };
      dispatch({ type, payload: errorPayload });
    }
  };
};