import {
  ASSIGN_APPLICATION,
  CLEANUP_APPLICANT_DETAIL,
  CREATE_TRAINING,
  FETCH_STUDENT,
  GET_COMMITTEE_REVIEWERS,
  GET_DOCUMENT,
  NOTIFY_ADMIN,
  CLEAR_NOTIFY_ADMIN,
  OVERRIDE_RECOMMENDATION,
  REASSIGN_APPLICATION,
  REPORT_CONFLICT,
  RESTART_APPLICATION,
  SAVE_REVIEW,
  SEARCH_DOCUMENTS,
  SUBMIT_RECOMMENDATION,
  UNASSIGN_APPLICATION,
  UPDATE_RECOMMENDATION_HISTORY,
  UPDATE_PRIORITY,
  HOLD_FOR_EXPORT,
  EXPECTED_RECOMMENDATION,
  GET_TRAINING_CONFIG,
  UNASSIGN_TRAINEE
} from '../actions-index';
import { API, graphqlOperation } from 'aws-amplify';
import { formatDatetime } from '../util/functions';

import {
  ALERT_MESSAGES,
  ERROR_CONSTS,
  RECOMMENDATION_HISTORY_UPDATES,
  SEVERITY_LEVELS,
  STATUS,
} from '../util/constants';
import {
  assignApplicationMutation,
  createTrainingMutation,
  notifyAdminMutation,
  clearNotifyAdminMutation,
  overrideRecommendationMutation,
  reassignApplicationMutation,
  reportConflictMutation,
  restartEvaluationMutation,
  saveReviewMutation,
  submitReviewMutation,
  submitTrainingMutation,
  unassignApplicationMutation,
  updatePriorityMutation,
  holdForExportMutation,
  unassignTraineeMutation,
} from './mutation';
import {
  getApplication,
  getApplicationDocument,
  getCommitteeReviewers,
  getExpectedRecommendationQuery,
  getTraining,
  searchApplicationDocuments,
  getTrainingConfigQuery,
  getApplicationTrainingDocument,
  getApplicationReadOnlyQuery,
  searchApplicationDocumentsReadOnlyQuery,
  getApplicationDocumentReadOnly,
} from './query';
import { onHistoryUpdateApplication } from './subscriptions';
import {
  transformDocumentList,
  transformReviewer,
  transformSchemaToComponent,
} from './transform';

let sub = null;

export const cleanupApplicantDetail = () => {
  return async (dispatch) => {
    if(sub?.unsubscribe)
      sub.unsubscribe();
    sub = null;
    dispatch({
      type: CLEANUP_APPLICANT_DETAIL,
      payload: {},
    });
    dispatch({
      type: SUBMIT_RECOMMENDATION,
      payload: {
        status: '',
        alert: ERROR_CONSTS.UI.GENERIC.EMPTY_ALERT,
      },
    });
  };
};

export const fetchStudent = ({
  queryKey,
  committeeId,
  applicantEmployeeId,
  admitTerm,
  recHistorySub, 
  setRecHistorySub,
}) => {
  let queryName
    if (queryKey === 'getApplication') {
      queryName = getApplication
    } else if (queryKey === 'getApplicationReadOnly') {
      queryName = getApplicationReadOnlyQuery
    } else {
      queryName = getTraining
    }
    
  let response;

  return async (dispatch) => {
    dispatch({
      type: FETCH_STUDENT,
      payload: {
        status: STATUS.LOADING,
      },
    });
    dispatch({
      type: HOLD_FOR_EXPORT,
      payload: { status: STATUS.CLEAR },
    });
    try {
      response = await API.graphql({
        query: queryName,
        variables: {
          committeeId: committeeId,
          applicantEmployeeId: applicantEmployeeId,
          admitTerm: admitTerm,
        },
      });
      if (
        response?.data?.[queryKey]?.status?.toUpperCase() === STATUS.SUCCESS
      ) {
        
        dispatch({
          type: FETCH_STUDENT,
          payload: {
            status: STATUS.SUCCESS,
            data: transformSchemaToComponent(response?.data?.[queryKey]?.data),
          },
        });
        dispatch({
          type: CREATE_TRAINING,
          payload: {
            expectedRecommendation:
              response?.data?.[queryKey]?.data?.decisionOverride ??
              response?.data?.[queryKey]?.data?.decision ??
              '',
          },
        });
      } else {
        dispatch({
          type: FETCH_STUDENT,
          payload: {
            status: STATUS.ERROR,
            alert: {
              MESSAGE: response?.data?.[queryKey]?.error?.message,
              SEVERITY: SEVERITY_LEVELS.ERROR,
            },
          },
        });
      }

      const variables = {
        applicantEmployeeId: applicantEmployeeId,
      };
    
      if (!recHistorySub) {
          sub = API.graphql(
            graphqlOperation(onHistoryUpdateApplication, variables)
          ).subscribe({
            next: ({ provider, value }) =>
              handleSubscription(provider, value, dispatch),
            error: (error) => console.warn(error),
          });
          setRecHistorySub(true)
      }

      return response;
    } catch (error) {
      //check if we actually do have data. appsync roles will prevent a data item from coming in (as intended) but amplify still throws.
      if (error?.data?.[queryKey]?.data) {
        dispatch({
          type: FETCH_STUDENT,
          payload: {
            status: STATUS.SUCCESS,
            data: transformSchemaToComponent(
              error?.data?.[queryKey]?.data
            ),
          },
        });
        dispatch({
          type: CREATE_TRAINING,
          payload: {
            expectedRecommendation:
              error?.data?.[queryKey]?.data?.decisionOverride ??
              error?.data?.[queryKey]?.data?.decision ??
              '',
          },
        });
      }
      else {       
        dispatch({
          type: FETCH_STUDENT,
          status: STATUS.ERROR,
          payload: {
            status: STATUS.ERROR,
            alert: {
              MESSAGE:
                error?.errors?.[0]?.message ??
                error?.message ??
                'Unexpected Error',
              SEVERITY: SEVERITY_LEVELS.ERROR,
            },
          },
        });
      }
    }
  };
};


const handleSubscription = (provider, value, dispatch) => {
  if (Array.isArray(value?.data?.onHistoryUpdate?.data)) {
    for (let itemToUpdate of value?.data?.onHistoryUpdate?.data) {
      let { firstName, lastName, reviewerEmployeeId, stage, recommendation } =
        itemToUpdate;

      if (recommendation === 'Cancelled') {
        dispatch({
          type: UPDATE_RECOMMENDATION_HISTORY,
          payload: {
            actionType: RECOMMENDATION_HISTORY_UPDATES?.UNASSIGN,
            reviewerEmployeeId: reviewerEmployeeId,
          },
        });
      } else {
        const assignTime = new Date();
        dispatch({
          type: UPDATE_RECOMMENDATION_HISTORY,
          payload: {
            actionType: RECOMMENDATION_HISTORY_UPDATES?.ASSIGN,
            user: {
              reviewerEmployeeId: reviewerEmployeeId,
              assignTime,
              assignTimeFormatted: formatDatetime(assignTime),
              comments: {
                adminOverride: null,
                document: Array(0),
                evaluation: Array(0),
                othersConcerns: Array(0),
              },
              firstName: firstName,
              lastName: lastName,
              nameFormatted: `${firstName} ${lastName}`,
              recommendation: recommendation ? recommendation : 'Pending',
              recommendationUnified: recommendation ? recommendation : 'Pending',
              stage: stage ? stage : '-',
              submitTime: null,
              type: null,
            },
          },
        });
      }
    }
  }
};

export const getReviewers = ({ committeeId }) => {
  let response;

  return async (dispatch) => {
    try {
      response = await API.graphql({
        query: getCommitteeReviewers,
        variables: {
          committeeId: committeeId,
          createdDate: 'CURRENT',
          includeInactive: false,
        },
      });
      if (response) {
        dispatch({
          type: GET_COMMITTEE_REVIEWERS,
          status: STATUS.SUCCESS,
          payload: {
            status: STATUS.SUCCESS,
            data: transformReviewer(
              response?.data?.getCommitteeReviewersFromDB
            ),
          },
        });
      }
      return response;
    } catch (error) {
      dispatch({
        type: GET_COMMITTEE_REVIEWERS,
        payload: {
          status: STATUS.ERROR,
          alert: {
            MESSAGE: error?.errors?.[0]?.message ? error?.errors?.[0]?.message : "Unexpected Error",
            SEVERITY: SEVERITY_LEVELS.ERROR,
          },
        },
      });
    }
  };
};

export const saveReview = (recommendationObject) => {
  return async (dispatch) => {
    let response;

    try {
      dispatch({
        type: SAVE_REVIEW,
        payload: { status: STATUS.LOADING },
      });

      response = await API.graphql({
        query: saveReviewMutation,
        variables: { input: recommendationObject },
      });

      if (
        response?.data?.saveReview?.status.toUpperCase() === STATUS.SUCCESS
      ) {
        dispatch({
          type: SAVE_REVIEW,
          payload: {
            status: STATUS.SUCCESS,
            alert: {
              MESSAGE: response?.data?.saveReview?.details,
              SEVERITY: SEVERITY_LEVELS.SUCCESS,
            },
          },
        });
      } else {
        dispatch({
          type: SAVE_REVIEW,
          payload: {
            status: STATUS.ERROR,
            alert: {
              MESSAGE: response?.data?.saveReview?.error?.message,
              SEVERITY: SEVERITY_LEVELS.ERROR,
            },
          },
        });
      }
    } catch (error) {
      dispatch({
        type: SAVE_REVIEW,
        payload: {
          status: STATUS.ERROR,
          alert: {
            MESSAGE: error?.errors?.[0]?.message ? error?.errors?.[0]?.message : "Unexpected Error",
            SEVERITY: SEVERITY_LEVELS.ERROR,
          },
        },
      });
    }
  };
};

export const submitReview = (recommendationObject) => {
  return async (dispatch) => {
    let response;

    try {
      dispatch({
        type: SUBMIT_RECOMMENDATION,
        payload: { status: STATUS.LOADING },
      });

      response = await API.graphql({
        query: submitReviewMutation,
        variables: { input: recommendationObject },
      });
        if (
          response?.data?.submitReview?.status.toUpperCase() === STATUS.SUCCESS
        ) {
          dispatch({
            type: SUBMIT_RECOMMENDATION,
            payload: {
              status: STATUS.SUCCESS,
              alert: {
                MESSAGE: response?.data?.submitReview?.details,
                SEVERITY: SEVERITY_LEVELS.SUCCESS,
              },
            },
          });
        } else {
          dispatch({
            type: SUBMIT_RECOMMENDATION,
            payload: {
              status: STATUS.ERROR,
              alert: {
                MESSAGE: response?.data?.submitReview?.error?.message,
                SEVERITY: SEVERITY_LEVELS.ERROR,
              },
            },
          });
        }
    } catch (error) {
      dispatch({
        type: SUBMIT_RECOMMENDATION,
        payload: {
          status: STATUS.ERROR,
          alert: {
            MESSAGE: error?.errors?.[0]?.message ? error?.errors?.[0]?.message : "Unexpected Error",
            SEVERITY: SEVERITY_LEVELS.ERROR,
          },
        },
      });
    }
  };
};

export const submitTraining = (recommendationObject) => {
  return async (dispatch) => {
    let response;

    try {
      dispatch({
        type: SUBMIT_RECOMMENDATION,
        payload: { status: STATUS.LOADING },
      });

      response = await API.graphql({
        query: submitTrainingMutation,
        variables: { input: recommendationObject },
      });

      if (response?.data?.submitTraining?.status.toUpperCase() === STATUS.SUCCESS) { 
        const expectedRecommendation =
          response?.data?.submitTraining?.context[0]?.value;
          
        const submittedRecommendation =
          response?.data?.submitTraining?.context[1]?.value;
        if (submittedRecommendation === expectedRecommendation) {
          dispatch({
            type: SUBMIT_RECOMMENDATION,
            payload: {
              status: STATUS.SUCCESS,
              context: response?.data?.submitTraining?.context,
              alert: {
                MESSAGE:
                  'Congratulations! Your recommendation matched the expected recommendation.',
                SEVERITY: SEVERITY_LEVELS.SUCCESS,
              },
            },
          });
        } else if (expectedRecommendation === "None" && submittedRecommendation !== "") {
          dispatch({
            type: SUBMIT_RECOMMENDATION,
            payload: {
              status: STATUS.SUCCESS,
              context: response?.data?.submitTraining?.context,
              alert: {
                MESSAGE:
                  'Your recommendation was submitted successfully.',
                SEVERITY: SEVERITY_LEVELS.SUCCESS,
              },
            },
          });
        } 
        else {
          dispatch({
            type: SUBMIT_RECOMMENDATION,
            payload: {
              status: STATUS.SUCCESS,
              context: response?.data?.submitTraining?.context,
              alert: {
                MESSAGE:
                  'Your recommendation did not match the expected recommendation.',
                SEVERITY: SEVERITY_LEVELS.WARNING,
              },
            },
          });
        }
      } else {
        dispatch({
          type: SUBMIT_RECOMMENDATION,
          payload: {
            status: STATUS.ERROR,
            alert: {
              MESSAGE: response?.data?.submitTraining?.error?.message,
              SEVERITY: SEVERITY_LEVELS.ERROR,
            },
          },
        });
      }
    } catch (error) {
      dispatch({
        type: SUBMIT_RECOMMENDATION,
        payload: {
          status: STATUS.ERROR,
          alert: {
            MESSAGE: error?.errors?.[0]?.message ? error?.errors?.[0]?.message : "Unexpected Error",
            SEVERITY: SEVERITY_LEVELS.ERROR,
          },
        },
      });
    }
  };
};

export const assignApplication = ({
  applicantEmployeeId,
  committeeId,
  term,
  reviewerEmployeeId,
  reviewerFirstName,
  reviewerLastName,
  reviewerCompletedByDueDate,
}) => {
  return async (dispatch) => {
    let response;
    const payload = {
      applicantEmployeeId: applicantEmployeeId,
      committeeId: committeeId,
      term: term,
      reviewerEmployeeId: reviewerEmployeeId,
      reviewerFirstName: reviewerFirstName,
      reviewerLastName: reviewerLastName,
      reviewerCompletedByDueDate: reviewerCompletedByDueDate.toISOString()
    };
    dispatch({
      type: ASSIGN_APPLICATION,
      payload: { status: STATUS.LOADING },
    });

    try {
      response = await API.graphql({
        query: assignApplicationMutation,
        variables: { input: payload },
      });

      if (
        response?.data?.assignApplication?.status?.toUpperCase() ===
        STATUS.SUCCESS
      ) {
        dispatch({
          type: ASSIGN_APPLICATION,
          payload: {
            status: STATUS.SUCCESS,
            alert: {
              MESSAGE: response?.data?.assignApplication?.details,
              SEVERITY: SEVERITY_LEVELS.SUCCESS,
            },
          },
        });
      } else {
        dispatch({
          type: ASSIGN_APPLICATION,
          payload: {
            status: STATUS.ERROR,
            alert: {
              MESSAGE: response?.data?.assignApplication?.error?.message,
              SEVERITY: SEVERITY_LEVELS.ERROR,
            },
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ASSIGN_APPLICATION,
        payload: {
          status: STATUS.ERROR,
          alert: {
            MESSAGE: error?.errors?.[0]?.message ? error?.errors?.[0]?.message : "Unexpected Error",
            SEVERITY: SEVERITY_LEVELS.ERROR,
          },
        },
      });
    }
  };
};

export const unassignApplication = ({
  applicantEmployeeId,
  committeeId,
  term,
  reviewerEmployeeId,
}) => {
  return async (dispatch) => {
    let response;
    const payload = {
      applicantEmployeeId: applicantEmployeeId,
      committeeId: committeeId,
      term: term,
      reviewerEmployeeId: reviewerEmployeeId,
    };
    dispatch({
      type: UNASSIGN_APPLICATION,
      payload: { status: STATUS.LOADING },
    });
    try {
      response = await API.graphql({
        query: unassignApplicationMutation,
        variables: { input: payload },
      });

      if (
        response?.data?.unassignApplication?.status?.toUpperCase() ===
        STATUS.SUCCESS
      ) {
        dispatch({
          type: UNASSIGN_APPLICATION,
          payload: {
            status: response?.data?.unassignApplication?.status,
            alert: {
              MESSAGE: response?.data?.unassignApplication?.details,
              SEVERITY: STATUS.SUCCESS,
            },
          },
        });
      } else {
        dispatch({
          type: UNASSIGN_APPLICATION,
          payload: {
            status: response?.data?.unassignApplication?.status,
            alert: {
              MESSAGE: response?.data?.unassignApplication?.error?.message,
              SEVERITY: STATUS.ERROR,
            },
          },
        });
      }
    } catch (error) {
      dispatch({
        type: UNASSIGN_APPLICATION,
        payload: {
          alert: {
            MESSAGE: response?.data?.unassignApplication?.error?.message,
            SEVERITY: STATUS.ERROR,
          },
        },
      });
    }
  };
};

export const reportConflict = ({ applicantEmployeeId, committeeId, term }) => {
  return async (dispatch) => {
    let response;
    const payload = {
      applicantEmployeeId: applicantEmployeeId,
      committeeId: committeeId,
      term: term,
    };
    dispatch({
      type: REPORT_CONFLICT,
      payload: { status: STATUS.LOADING },
    });
    try {
      response = await API.graphql({
        query: reportConflictMutation,
        variables: { input: payload },
      });

      if (
        response?.data?.reportConflict?.status?.toUpperCase() ===
        STATUS.SUCCESS
      ) {
        dispatch({
          type: REPORT_CONFLICT,
          payload: {
            status: response?.data?.reportConflict?.status,
            alert: {
              MESSAGE: response?.data?.reportConflict?.details,
              SEVERITY: STATUS.SUCCESS,
            },
          },
        });
      } else {
        dispatch({
          type: REPORT_CONFLICT,
          payload: {
            status: response?.data?.reportConflict?.status,
            alert: {
              MESSAGE: response?.data?.reportConflict?.error?.message,
              SEVERITY: STATUS.ERROR,
            },
          },
        });
      }
    } catch (error) {
      dispatch({
        type: REPORT_CONFLICT,
        payload: {
          status: response?.data?.reportConflict?.status,
          alert: {
            MESSAGE: error?.errors?.[0]?.message ? error?.errors?.[0]?.message : "Unexpected Error",
            SEVERITY: STATUS.ERROR,
          },
        },
      });
    }
  };
};

export const restartEvaluation = ({
  applicantEmployeeId,
  committeeId,
  term,
}) => {
  return async (dispatch) => {
    let response;
    const payload = {
      applicantEmployeeId: applicantEmployeeId,
      committeeId: committeeId,
      term: term,
    };
    dispatch({
      type: RESTART_APPLICATION,
      payload: { status: STATUS.LOADING },
    });
    try {
      response = await API.graphql({
        query: restartEvaluationMutation,
        variables: { input: payload },
      });

      if (
        response?.data?.restartEvaluation?.status.toUpperCase() ===
        STATUS.SUCCESS
      ) {
        dispatch({
          type: RESTART_APPLICATION,
          payload: {
            status: STATUS.SUCCESS,
            alert: {
              MESSAGE: response?.data?.restartEvaluation?.details,
              SEVERITY: SEVERITY_LEVELS.SUCCESS,
            },
          },
        });
      } else {
        dispatch({
          type: RESTART_APPLICATION,
          payload: {
            status: STATUS.ERROR,
            alert: {
              MESSAGE: response?.data?.restartEvaluation?.error?.message,
              SEVERITY: SEVERITY_LEVELS.ERROR,
            },
          },
        });
      }
    } catch (error) {
      dispatch({
        type: RESTART_APPLICATION,
        payload: {
          alert: {
            MESSAGE: error?.errors?.[0]?.message ? error?.errors?.[0]?.message : "Unexpected Error",
            SEVERITY: SEVERITY_LEVELS.ERROR,
          },
        },
      });
    }
  };
};

export const searchDocuments = ({ applicantEmployeeId, admitTerm, committeeId, isTraining = false, queryKey }) => {
  let queryName
  if (queryKey === 'searchApplicationDocuments') {
    queryName = searchApplicationDocuments
  } else if (queryKey === 'searchApplicationDocumentsReadOnly') {
    queryName = searchApplicationDocumentsReadOnlyQuery
  }


  return async (dispatch) => {
    dispatch({
      type: SEARCH_DOCUMENTS,
      payload: { status: STATUS.LOADING },
    });

    try {
      const response = await API.graphql({
        query: queryName,
        variables: { applicantEmployeeId, admitTerm, committeeId, isTraining },
      });


      if (
        response.data?.[queryKey].status.toUpperCase() === STATUS.SUCCESS
      ) {
        dispatch({
          type: SEARCH_DOCUMENTS,
          payload: {
            status: STATUS.SUCCESS,
            data: transformDocumentList(
              response.data?.[queryKey].data
            ),
          },
        });
      } else {
        dispatch({
          type: SEARCH_DOCUMENTS,
          payload: {
            status: SEVERITY_LEVELS.ERROR,
            alert: {
              MESSAGE:
              response.data?.[queryKey].data,
              SEVERITY: SEVERITY_LEVELS.ERROR,
            },
          },
        });
      }
    } catch (error) {
      dispatch({
        type: SEARCH_DOCUMENTS,
        payload: {
          status: STATUS.ERROR,
          alert: {
            MESSAGE: error?.errors?.[0]?.message ? error?.errors?.[0]?.message : error,
            SEVERITY: SEVERITY_LEVELS.ERROR,
          },
        },
      });
    }
  };
};

export const getDocument = (documents, applicantEmployeeId, queryKey) => {

  let queryName

  if (queryKey === 'getApplicationDocument') {
    queryName = getApplicationDocument
  } else if (queryKey === 'getApplicationDocumentReadOnly') {
    queryName = getApplicationDocumentReadOnly
  } else {
    queryName = getApplicationTrainingDocument
  }

  return async (dispatch) => {
    
    let requestCount = documents?.length ?? 0, requestSuccessCount = 0;

    try {
      if (documents.length < 1 || !Array.isArray(documents)) return;
      dispatch({
        type: GET_DOCUMENT,
        payload: { status: STATUS.LOADING, documents: documents },
      });

      let responses = documents.map(async (doc) => {
        
          try {
              const response = await API.graphql({
                query: queryName,
                variables: { documentId: doc.documentId, documentName: doc.documentName, applicantEmployeeId: applicantEmployeeId },
              });
              // Validate temp URL for document came back successfully
              const signedUrl = response?.data?.[queryKey]?.data?.signedUrl
              if (typeof signedUrl !== "string") {
                  if (requestCount > 1) { await sleep(3000); } // Let others succeed if they can
                  throw `Invalid signed url, ${signedUrl}`;
              }
              
              requestSuccessCount++;
              return { status: STATUS.SUCCESS, signedUrl };
          } catch (exception) {
              if (requestCount === 1) { throw exception; }
              console.error("Error while retrieving document", doc, doc.documentId, doc.documentName);
              return { status: STATUS.ERROR, documentId: doc.documentId, documentName: doc.documentName };
          }
        }
      );

      const signedUrls = await Promise.all(responses);
      const allSucceeded = requestSuccessCount === requestCount;
      const potentialAlert = {
            ALERT_BAR_MESSAGE: `Error retrieving ${requestCount - requestSuccessCount} of ${requestCount} documents`,
            MESSAGE: `Error retrieving ${requestCount - requestSuccessCount} of ${requestCount} documents`,
            SEVERITY: SEVERITY_LEVELS.ERROR
        };

      dispatch({
        type: GET_DOCUMENT,
        payload: {
          alert: allSucceeded ? undefined : potentialAlert,
          status: allSucceeded ? STATUS.SUCCESS : STATUS.ERROR,
          data: signedUrls,
        },
      });
    } catch (error) {
      console.error("Error retrieving documents", error);
      const errorMessage = requestCount <= 1 ? ALERT_MESSAGES.ERROR_RETRIEVING_DOCUMENT :
        `Error retrieving ${requestCount - requestSuccessCount} of ${requestCount} documents`;

      dispatch({
        type: GET_DOCUMENT,
        payload: {
          status: STATUS.ERROR,
          alert: {
            ALERT_BAR_MESSAGE: errorMessage,
            MESSAGE: error?.errors?.[0]?.message ? error?.errors?.[0]?.message : "Unexpected Error",
            SEVERITY: SEVERITY_LEVELS.ERROR,
          },
        },
      });
    }
  };
};


function sleep(ms) { return new Promise(resolve => setTimeout(resolve, ms || 100)); }  

export const notifyAdmin = ({
  applicantEmployeeId,
  committeeId,
  term,
  data,
}) => {
  let response;
  let notes = [];
  data.forEach((item) => notes.push(item.label));

  return async (dispatch) => {
    const payload = {
      applicantEmployeeId: applicantEmployeeId,
      committeeId: committeeId,
      term: term,
      notes: notes,
    };

    dispatch({
      type: NOTIFY_ADMIN,
      payload: { status: STATUS.LOADING },
    });
    try {
      response = await API.graphql({
        query: notifyAdminMutation,
        variables: { input: payload },
      });

      if (
        response?.data?.notifyAdmin?.status?.toUpperCase() === STATUS.SUCCESS
      ) {
        dispatch({
          type: NOTIFY_ADMIN,
          payload: {
            status: SEVERITY_LEVELS.SUCCESS,
            alert: {
              MESSAGE: response?.data?.notifyAdmin?.details,
              SEVERITY: SEVERITY_LEVELS.SUCCESS,
            },
          },
        });
      } else {
        dispatch({
          type: NOTIFY_ADMIN,
          payload: {
            status: SEVERITY_LEVELS.ERROR,
            details: '',
            alert: {
              MESSAGE: response?.data?.notifyAdmin?.error?.message,
              SEVERITY: SEVERITY_LEVELS.ERROR,
            },
          },
        });
      }
    } catch (error) {
      dispatch({
        type: NOTIFY_ADMIN,
        payload: {
          status: STATUS.ERROR,
          alert: {
            MESSAGE: error?.errors?.[0]?.message ? error?.errors?.[0]?.message : "Unexpected Error",
            SEVERITY: SEVERITY_LEVELS.ERROR,
          },
        },
      });
    }
  };
};

export const clearNotifyAdmin = ({
  applicantEmployeeId,
  committeeId,
  term
}) => {
  let response;

  return async (dispatch) => {
    const payload = {
      applicantEmployeeId: applicantEmployeeId,
      committeeId: committeeId,
      term: term   
    };

    dispatch({
      type: CLEAR_NOTIFY_ADMIN,
      payload: { status: STATUS.LOADING },
    });
    try {
      response = await API.graphql({
        query: clearNotifyAdminMutation,
        variables: { input: payload },
      });

      if (
        response?.data?.clearNotifyAdmin?.status?.toUpperCase() === STATUS.SUCCESS
      ) {
        dispatch({
          type: CLEAR_NOTIFY_ADMIN,
          payload: {
            status: SEVERITY_LEVELS.SUCCESS,
            alert: {
              MESSAGE: response?.data?.clearNotifyAdmin?.details,
              SEVERITY: SEVERITY_LEVELS.SUCCESS,
            },
          },
        });
      } else {
        dispatch({
          type: CLEAR_NOTIFY_ADMIN,
          payload: {
            status: SEVERITY_LEVELS.ERROR,
            details: '',
            alert: {
              MESSAGE: response?.data?.clearNotifyAdmin?.error?.message,
              SEVERITY: SEVERITY_LEVELS.ERROR,
            },
          },
        });
      }
    } catch (error) {
      dispatch({
        type: CLEAR_NOTIFY_ADMIN,
        payload: {
          status: STATUS.ERROR,
          alert: {
            MESSAGE: error?.errors?.[0]?.message ? error?.errors?.[0]?.message : "Unexpected Error",
            SEVERITY: SEVERITY_LEVELS.ERROR,
          },
        },
      });
    }
  };
};

export const reassignApplication = ({
  applicantEmployeeId,
  committeeId,
  term,
  reviewerEmployeeIdFrom,
  reviewerEmployeeIdTo,
  reviewerCompletedByDueDate
}) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: REASSIGN_APPLICATION,
        payload: { status: STATUS.LOADING },
      });

      let response = await API.graphql({
        query: reassignApplicationMutation,
        variables: {
          input: {
            applicantEmployeeId,
            committeeId,
            term,
            reviewerEmployeeIdFrom: reviewerEmployeeIdFrom,
            reviewerEmployeeIdTo: reviewerEmployeeIdTo,
            reviewerCompletedByDueDate: reviewerCompletedByDueDate,
          },
        },
      });
      if (
        response?.data?.reassignApplication?.status?.toUpperCase() ===
        STATUS.SUCCESS
      ) {
        dispatch({
          type: REASSIGN_APPLICATION,
          payload: {
            status: STATUS.SUCCESS,
            alert: {
              MESSAGE: response?.data?.reassignApplication?.details,
              SEVERITY: SEVERITY_LEVELS.SUCCESS,
            },
          },
        });
      } else {
        dispatch({
          type: REASSIGN_APPLICATION,
          payload: {
            status: SEVERITY_LEVELS.ERROR,
            details: '',
            alert: {
              MESSAGE: response?.data?.reassignApplication?.error?.message,
              SEVERITY: SEVERITY_LEVELS.ERROR,
            },
          },
        });
      }
    } catch (error) {
      dispatch({
        type: REASSIGN_APPLICATION,
        payload: {
          status: STATUS.ERROR,
          alert: {
            MESSAGE: error?.errors?.[0]?.message ? error?.errors?.[0]?.message : "Unexpected Error",
            SEVERITY: SEVERITY_LEVELS.ERROR,
          },
        },
      });
    }
  };
};

export const overrideRecommendation = ({
  applicantEmployeeId,
  committeeId,
  term,
  recommendation,
  restriction,
  comments,
}) => {
  return async (dispatch) => {
    let response;
    const payload = {
      applicantEmployeeId: applicantEmployeeId,
      committeeId: committeeId,
      term: term,
      recommendation: recommendation,
      restriction: restriction,
      comments: comments,
    };
    dispatch({
      type: OVERRIDE_RECOMMENDATION,
      payload: { status: STATUS.LOADING },
    });

    try {
      response = await API.graphql({
        query: overrideRecommendationMutation,
        variables: { input: payload },
      });
      if (
        response?.data?.submitOverride?.status?.toUpperCase() ===
        STATUS.SUCCESS
      ) {
        dispatch({
          type: OVERRIDE_RECOMMENDATION,
          payload: {
            status: STATUS.SUCCESS,
            alert: {
              MESSAGE: response?.data?.submitOverride?.details,
              SEVERITY: SEVERITY_LEVELS.SUCCESS,
            },
          },
        });
      } else {
        dispatch({
          type: OVERRIDE_RECOMMENDATION,
          payload: {
            status: STATUS.ERROR,
            alert: {
              MESSAGE: response?.data?.submitOverride?.error?.message,
              SEVERITY: SEVERITY_LEVELS.ERROR,
            },
          },
        });
      }
    } catch (error) {
      dispatch({
        type: OVERRIDE_RECOMMENDATION,
        payload: {
          status: STATUS.ERROR,
          alert: {
            MESSAGE: error?.errors?.[0]?.message ? error?.errors?.[0]?.message : "Unexpected Error",
            SEVERITY: SEVERITY_LEVELS.ERROR,
          },
        },
      });
    }
  };
};

export const createTraining = ({
  applicantEmployeeId,
  committeeId,
  term,
  expectedRecommendation,
  emplids,
}) => {
  return async (dispatch) => {
    let response;
    const dispatchType = CREATE_TRAINING
    
    const payload = {
      applicantEmployeeId: applicantEmployeeId,
      committeeId: committeeId,
      term: term,
      expectedRecommendation: expectedRecommendation,
      emplids: emplids
    };
    dispatch({
      type: dispatchType,
      payload: { status: STATUS.LOADING },
    });

    try {
      response = await API.graphql({
        query: createTrainingMutation,
        variables: { input: payload },
      });
      if (
        response?.data?.createTraining?.status?.toUpperCase() ===
        STATUS.SUCCESS
      ) {
        dispatch({
          type: dispatchType,
          payload: {
            status: STATUS.SUCCESS,
            alert: {
              MESSAGE: response?.data?.createTraining?.details,
              SEVERITY: SEVERITY_LEVELS.SUCCESS,
            },
          },
        });
        getTrainingConfig(applicantEmployeeId, committeeId, term)(dispatch)
      } else {
        dispatch({
          type: dispatchType,
          payload: {
            status: STATUS.ERROR,
            alert: {
              MESSAGE: response?.data?.createTraining?.error?.message,
              SEVERITY: SEVERITY_LEVELS.ERROR,
            },
          },
        });
      }
    } catch (error) {
      dispatch({
        type: dispatchType,
        payload: {
          status: STATUS.ERROR,
          alert: {
            MESSAGE: error?.errors?.[0]?.message ? error?.errors?.[0]?.message : "Unexpected Error",
            SEVERITY: SEVERITY_LEVELS.ERROR,
          },
        },
      });
    }
  };
};


export const updatePriority = ({
  applicantEmployeeId,
  committeeId,
  term,
}) => {
  return async (dispatch) => {
    let response;
    
    const payload = {
      applicantEmployeeId: applicantEmployeeId,
      committeeId: committeeId,
      term: term,
    };
    dispatch({
      type: UPDATE_PRIORITY,
      payload: { status: STATUS.LOADING },
    });

    try {
      response = await API.graphql({
        query: updatePriorityMutation,
        variables: { input: payload },
      });
      if (
        response?.data?.updateApplicationPriority?.status?.toUpperCase() ===
        STATUS.SUCCESS
      ) {
        dispatch({
          type: UPDATE_PRIORITY,
          payload: {
            status: STATUS.SUCCESS,
            alert: {
              MESSAGE: 'You have successfully prioritized this application. Reviewers can now access this application directly from the In Progress section of their dashboard.',
              SEVERITY: SEVERITY_LEVELS.SUCCESS,
            },
          },
        });
      } else {
        dispatch({
          type: UPDATE_PRIORITY,
          payload: {
            status: STATUS.ERROR,
            alert: {
              MESSAGE: response?.data?.updatePriority?.error?.message,
              SEVERITY: SEVERITY_LEVELS.ERROR,
            },
          },
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_PRIORITY,
        payload: {
          status: STATUS.ERROR,
          alert: {
            MESSAGE: error?.errors?.[0]?.message ? error?.errors?.[0]?.message : "Unexpected Error",
            SEVERITY: SEVERITY_LEVELS.ERROR,
          },
        },
      });
    }
  };
}

export const toggleHoldForExport = (
  applicantEmployeeId,
  committeeId,
  term,
  holdForExportArg
) => {
  return async (dispatch) => {
    let response;
    
    const payload = {
      applicantEmployeeId: applicantEmployeeId,
      committeeId: committeeId,
      term: term,
      holdForExport: holdForExportArg
    };

    dispatch({
      type: HOLD_FOR_EXPORT,
      payload: { status: STATUS.LOADING },
    });

    try {
      response = await API.graphql({
        query: holdForExportMutation,
        variables: { input: payload },
      });
      if (
        response?.data?.holdForExport?.applicantEmployeeId
      ) {
        let verbiage = holdForExportArg ? 'selected' : 'deselected';
        dispatch({
          type: HOLD_FOR_EXPORT,
          payload: {
            status: STATUS.SUCCESS,
            alert: {
              MESSAGE: `You have successfully ${verbiage} this application to be held for information.`,
              SEVERITY: SEVERITY_LEVELS.SUCCESS,
            },
          },
        });
      } else {
        dispatch({
          type: HOLD_FOR_EXPORT,
          payload: {
            status: STATUS.ERROR,
            alert: {
              MESSAGE: response?.data?.holdForExport?.error?.message,
              SEVERITY: SEVERITY_LEVELS.ERROR,
            },
          },
        });
      }
    } catch (error) {
      dispatch({
        type: HOLD_FOR_EXPORT,
        payload: {
          status: STATUS.ERROR,
          alert: {
            MESSAGE: error?.errors?.[0]?.message ? error?.errors?.[0]?.message : "Unexpected Error",
            SEVERITY: SEVERITY_LEVELS.ERROR,
          },
        },
      });
    }
  };
}

export const getExpectedRecommendation = (applicantEmployeeId, committeeId, term) => {
  return async (dispatch) => {
    let response;
    const dispatchType = EXPECTED_RECOMMENDATION
    
    const payload = {
      applicantEmployeeId: applicantEmployeeId,
      committeeId: committeeId,
      term: term,
    };
    dispatch({
      type: dispatchType,
      payload: { status: STATUS.LOADING },
    });

    try {
      response = await API.graphql({
        query: getExpectedRecommendationQuery,
        variables: payload,
      });
      if (
        response?.data?.getExpectedRecommendation?.status?.toUpperCase() ===
        STATUS.SUCCESS
      ) {
        dispatch({
          type: dispatchType,
          payload: {
            status: STATUS.SUCCESS,
            data: response?.data?.getExpectedRecommendation?.details
          },
        });
      } else {
        dispatch({
          type: dispatchType,
          payload: {
            status: STATUS.ERROR,
            alert: {
              MESSAGE: response?.data?.getExpectedRecommendation?.error?.message,
              SEVERITY: SEVERITY_LEVELS.ERROR,
            },
          },
        });
      }
    } catch (error) {
      dispatch({
        type: dispatchType,
        payload: {
          status: STATUS.ERROR,
          alert: {
            MESSAGE: error?.errors?.[0]?.message ? error?.errors?.[0]?.message : "Unexpected Error",
            SEVERITY: SEVERITY_LEVELS.ERROR,
          },
        },
      });
    }
  };
}

export const unassignTrainee = ({
  applicantEmployeeId,
  committeeId,
  term,
  emplids,
}) => {
  return async (dispatch) => {
    let response;
    const dispatchType = UNASSIGN_TRAINEE
    
    const payload = {
      applicantEmployeeId: applicantEmployeeId,
      committeeId: committeeId,
      term: term,
      emplids: emplids
    };
    dispatch({
      type: dispatchType,
      payload: { status: STATUS.LOADING },
    });

    try {
      response = await API.graphql({
        query: unassignTraineeMutation,
        variables: { input: payload },
      });
      if (
        response?.data?.unassignTrainee?.status?.toUpperCase() ===
        STATUS.SUCCESS
      ) {
        dispatch({
          type: dispatchType,
          payload: {
            status: STATUS.SUCCESS,
            alert: {
              MESSAGE: response?.data?.unassignTrainee?.details,
              SEVERITY: SEVERITY_LEVELS.SUCCESS,
            },
          },
        });
      } else {
        dispatch({
          type: dispatchType,
          payload: {
            status: STATUS.ERROR,
            alert: {
              MESSAGE: response?.data?.unassignTrainee?.error?.message,
              SEVERITY: SEVERITY_LEVELS.ERROR,
            },
          },
        });
      }
    } catch (error) {
      dispatch({
        type: dispatchType,
        payload: {
          status: STATUS.ERROR,
          alert: {
            MESSAGE: error?.errors?.[0]?.message ? error?.errors?.[0]?.message : "Unexpected Error",
            SEVERITY: SEVERITY_LEVELS.ERROR,
          },
        },
      });
    }
  };
}

export function getTrainingConfig (applicantEmployeeId, committeeId, term) {
  return async (dispatch) => {
    const type = GET_TRAINING_CONFIG;
    let payload = { status: STATUS.LOADING }, response;
    dispatch({ type, payload });
    payload.status = STATUS.ERROR;
        
    try {
        const query = getTrainingConfigQuery;
        const variables = { applicantEmployeeId, committeeId, term };
        response = await API.graphql({ query, variables });

        if (response?.data?.getTrainingConfig?.status?.toUpperCase() === STATUS.SUCCESS) {
            payload.status = STATUS.SUCCESS;
            payload.trainingExists = response?.data?.getTrainingConfig?.trainingExists;
            payload.options = response?.data?.getTrainingConfig?.options;
        } else {
            payload.alert = {
                MESSAGE: response?.data?.getTrainingConfig?.error?.message,
                SEVERITY: SEVERITY_LEVELS.ERROR,
            };
        }
    } catch (error) {
        console.error("Error in getTrainingConfig", response, error)
        payload.alert = {
            MESSAGE: error?.errors?.[0]?.message ? error?.errors?.[0]?.message : "Unexpected Error",
            SEVERITY: SEVERITY_LEVELS.ERROR,
        };
    }

    dispatch({ type, payload });
  };
}