import { API, graphqlOperation } from 'aws-amplify';
import {
    FETCH_MANAGE_APPLICATIONS_DATA, FETCH_ASSIGNABLE_APPLICATIONS, MANUAL_BATCH_ASSIGN_APPLICATIONS, FETCH_ASSIGNED_APPLICATIONS, CLEAR_MESSAGES,
    SUBSCRIBE_REVIEWER_CHANGES, SUBSCRIBE_COMMITTEE_CHANGES, 
    FETCH_REVIEWER_DETAILS_BY_COMMITTEE, FETCH_COMMITTEE_DETAILS, SET_SELECTED_REVIEWER, MANUAL_BATCH_UNASSIGN_APPLICATIONS
} from '../actions-index';
import { SEVERITY_LEVELS, STATUS } from '../util/constants';
import { assignApplicationsMutation, unassignApplicationsMutation } from './mutation';
import { getManageApplicationsDataQuery, listAssignableApplicationsQuery, listAssignedApplicationsQuery, getReviewerDetailsByCommitteeQuery, getCommitteeDetailsQuery } from './query';
import { onCommitteeChanges, onReviewerChanges } from './subscriptions';

// import { Hub } from 'aws-amplify';
// Hub.listen('api', (data) => {
//       const { payload } = data;
//       console.log('payload: ', payload);
// });

let committeeSub = null;
let reviewerSub = null;

export const getManageApplicationsData = (committeeId) => {
    return async (dispatch) => {
        const type = FETCH_MANAGE_APPLICATIONS_DATA;

        try {
            dispatch({ type, payload: { status: STATUS.LOADING } });
            const query = getManageApplicationsDataQuery;
            const response = await API.graphql({ query });

            if (response) {
                const payload = { status: STATUS.SUCCESS, data: response?.data?.getManageApplicationsData };
                dispatch({ type, payload });

                committeeSub?.unsubscribe?.(); // Unsubscribe from anything we're still subscribed too
                committeeSub = API.graphql(
                    graphqlOperation(onCommitteeChanges, { committeeId: committeeId })
                ).subscribe({
                    next: ({ provider, value }) => {
                        console.log("subscriptions: ", provider, value);
                        dispatch({ type: SUBSCRIBE_COMMITTEE_CHANGES, payload: value.data.onCommitteeChanges });
                    },
                    error: (error) => console.warn(error),
                });

                return;
            }
        } catch (error) {
            console.error(error);
            console.error(error?.errors?.[0]?.message);
            committeeSub?.unsubscribe?.();
            const errorPayload = {
                status: STATUS.ERROR,
                alert: {
                    MESSAGE: error?.errors?.[0]?.message,
                    SEVERITY: SEVERITY_LEVELS.ERROR,
                },
            };
            dispatch({ type, payload: errorPayload });
        }
    };
};

export const getReviewerDetailsByCommittee = (committeeId) => {
    return async (dispatch) => {
        const type = FETCH_REVIEWER_DETAILS_BY_COMMITTEE;

        try {
            dispatch({ type, payload: { status: STATUS.LOADING } });
            const query = getReviewerDetailsByCommitteeQuery;
            const variables = { committeeId };
            const response = await API.graphql({ query, variables });

            if (response) {
                const payload = { status: STATUS.SUCCESS, data: response?.data?.getReviewerDetailsByCommittee };
                dispatch({ type, payload });
                return;
            }

            // Handle errors
            const errorPayload = {
                status: STATUS?.ERROR,
                alert: {
                    MESSAGE: response?.data?.getReviewerDetailsByCommittee?.error?.message,
                    SEVERITY: SEVERITY_LEVELS.ERROR,
                },
            };

            dispatch({ type, payload: errorPayload });
        } catch (error) {
            console.error(error);
            console.error(error?.errors?.[0]?.message);

            const errorPayload = {
                status: STATUS.ERROR,
                alert: {
                    MESSAGE: error?.errors?.[0]?.message,
                    SEVERITY: SEVERITY_LEVELS.ERROR,
                },
            };
            dispatch({ type, payload: errorPayload });
        }
    };
};



export const listAssignableApplications = (reviewerEmployeeId, committeeId, filtersAsJsonArr, limit = 100, lastEvaluatedKey, refresh, createdDatetimeStart, createdDatetimeEnd) => {
    return async (dispatch) => {
        const type = FETCH_ASSIGNABLE_APPLICATIONS, uidForApiCall = new Date().getTime();
        if (typeof filtersAsJsonArr !== "string") {
            filtersAsJsonArr = appendCreatedDatetimeFilter(filtersAsJsonArr, createdDatetimeEnd, createdDatetimeStart);
            filtersAsJsonArr = JSON.stringify(filtersAsJsonArr);
        }

        try {
            if(refresh) {
                dispatch({ type, payload: { status: STATUS.CLEAR } })
                lastEvaluatedKey = null;
            }
            dispatch({ type, payload: { status: STATUS.LOADING, uidForApiCall } });
            const query = listAssignableApplicationsQuery;
            const variables = { reviewerEmployeeId, committeeId, filtersAsJsonArr, limit, lastEvaluatedKey };
            const response = await API.graphql({ query, variables });

            if (response) {
                const payload = { status: STATUS.SUCCESS, data: response?.data?.listAssignableApplications, uidForApiCall };
                dispatch({ type, payload });
                return;
            }

            // Handle errors
            const errorPayload = {
                status: STATUS?.ERROR,
                alert: {
                    MESSAGE: response?.data?.listAssignableApplications?.error?.message,
                    SEVERITY: SEVERITY_LEVELS.ERROR,
                },
            };

            dispatch({ type, payload: errorPayload });
        } catch (error) {
            console.error(error);
            console.error(error?.errors?.[0]?.message);

            const errorPayload = {
                status: STATUS.ERROR,
                alert: {
                    MESSAGE: error?.errors?.[0]?.message,
                    SEVERITY: SEVERITY_LEVELS.ERROR,
                },
            };
            dispatch({ type, payload: errorPayload });
        }
    };
};

function appendCreatedDatetimeFilter(filtersAsJsonArr, createdDatetimeEnd, createdDatetimeStart) {
    filtersAsJsonArr = JSON.parse(JSON.stringify(filtersAsJsonArr));

    if (createdDatetimeStart !== null) { filtersAsJsonArr.createdDatetimeStart = getSimpleDate(createdDatetimeStart); }
    if (createdDatetimeEnd !== null) { filtersAsJsonArr.createdDatetimeEnd = getSimpleDate(createdDatetimeEnd, 1); }

    return filtersAsJsonArr;
}

// Can potentially add a single day (if it's an end date) to make that date inclusive
function getSimpleDate(date, addedDays = 0) {
    const yyyymmdd = `${date.getYear() + 1900}-${('0'+ (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
    date = new Date(yyyymmdd);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
    date.setDate(date.getDate() + addedDays);
    return date.toISOString();
}

export const clearMessages = () => {
    return async (dispatch) => {
        const type = CLEAR_MESSAGES;
        dispatch({ type });
    };
};

export const assignApplications = (reviewerDetails, applicationsToAssign) => {
    return async (dispatch) => {
        const type = MANUAL_BATCH_ASSIGN_APPLICATIONS;

        try {
            dispatch({ type, payload: { status: STATUS.LOADING } });
            applicationsToAssign = deduplicateApplications(applicationsToAssign);

            const query = assignApplicationsMutation;
            const variables = { input: { reviewerDetails, applicationsToAssign } };
            const response = await API.graphql({ query, variables });

            if (response && response?.data?.manualBatchAssignApplicationsV2?.status === STATUS.success) {
                const payload = { status: STATUS.SUCCESS, data: response?.data?.manualBatchAssignApplicationsV2 };
                dispatch({ type, payload });
                return;
            }

            // Handle errors
            const errorPayload = {
                status: STATUS?.ERROR,
                alert: {
                    MESSAGE: response?.data?.manualBatchAssignApplicationsV2?.error?.message,
                    SEVERITY: SEVERITY_LEVELS.ERROR,
                },
                data: {
                    errorDetails: [ { message: "Server error" } ]
                }
            };

            dispatch({ type, payload: errorPayload });
        } catch (error) {
            console.error(error);
            console.error(error?.errors?.[0]?.message);

            const errorPayload = {
                status: STATUS.ERROR,
                alert: {
                    MESSAGE: error?.errors?.[0]?.message,
                    SEVERITY: SEVERITY_LEVELS.ERROR,
                },
                data: {
                    errorDetails: [ { message: error?.errors?.[0]?.message ?? "Client error" } ]
                }
            };
            dispatch({ type, payload: errorPayload });
        }
    };
}

function deduplicateApplications(applications) {
    let uniqueApplications = [];
    
    applications.forEach(application => {
        if (uniqueApplications.some(applicationToCheck => applicationToCheck.applicantEmployeeId === application.applicantEmployeeId)) { return; }
        uniqueApplications.push(application);
    });
    
    return uniqueApplications;
}

export const unassignApplications = (reviewerDetails, applicationsToAssign) => {
    return async (dispatch) => {
        const type = MANUAL_BATCH_UNASSIGN_APPLICATIONS;

        try {
            dispatch({ type, payload: { status: STATUS.LOADING } });

            const query = unassignApplicationsMutation;
            const variables = { input: { reviewerDetails, applicationsToAssign } };
            const response = await API.graphql({ query, variables });

            if (response && response?.data?.manualBatchUnassignApplications?.status === STATUS.success) {
                const payload = { status: STATUS.SUCCESS, data: response?.data?.manualBatchUnassignApplications };
                dispatch({ type, payload });
                return;
            }

            // Handle errors
            const errorPayload = {
                status: STATUS.ERROR,
                alert: {
                    MESSAGE: response?.data?.manualBatchUnassignApplications?.error?.message,
                    SEVERITY: SEVERITY_LEVELS.ERROR,
                },
                data: {
                    errorDetails: [ { message: "Server error" } ]
                }
            };

            dispatch({ type, payload: errorPayload });
        } catch (error) {
            console.error(error);
            console.error(error?.errors?.[0]?.message);

            const errorPayload = {
                status: STATUS.ERROR,
                alert: {
                    MESSAGE: error?.errors?.[0]?.message,
                    SEVERITY: SEVERITY_LEVELS.ERROR,
                },
                data: {
                    errorDetails: [ { message: error?.errors?.[0]?.message ?? "Client error" } ]
                }
            };
            dispatch({ type, payload: errorPayload });
        }
    };
}

export const listAssignedApplications = (reviewerEmployeeId, committeeId, countsOnly = false) => {
    return async (dispatch) => {
        const type = FETCH_ASSIGNED_APPLICATIONS;

        try {
            dispatch({ type, payload: { status: STATUS.LOADING } });
            const query = listAssignedApplicationsQuery;
            const variables = { reviewerEmployeeId, committeeId, countsOnly };
            const response = await API.graphql({ query, variables });

            if (response) {
                const payload = { status: STATUS.SUCCESS, data: response?.data?.listAssignedApplications };
                dispatch({ type, payload });
                return;
            }

            // Handle errors
            const errorPayload = {
                status: STATUS?.ERROR,
                alert: {
                    MESSAGE: response?.data?.listAssignedApplications?.error?.message,
                    SEVERITY: SEVERITY_LEVELS.ERROR,
                },
            };
            dispatch({ type, payload: errorPayload });
        } catch (error) {
            console.error(error);
            console.error(error?.errors?.[0]?.message);

            const errorPayload = {
                status: STATUS.ERROR,
                alert: {
                    MESSAGE: error?.errors?.[0]?.message,
                    SEVERITY: SEVERITY_LEVELS.ERROR,
                },
            };
            dispatch({ type, payload: errorPayload });
        }
    };
};

export const subscribeReviewerChanges = (reviewerEmployeeId) => {
    return async (dispatch) => {

        if (reviewerSub) {
            reviewerSub.unsubscribe();
        }

        reviewerSub = API.graphql(
            graphqlOperation(onReviewerChanges, { reviewerEmployeeId: reviewerEmployeeId })
        ).subscribe({
            next: ({ provider, value }) => {
                console.log("subscriptions: ", provider, value);
                dispatch({ type: SUBSCRIBE_REVIEWER_CHANGES, payload: value.data.onReviewerChanges })
            },
            error: (error) => console.warn(error),
        });
    }
}

export const getCommitteeDetails = (committeeId) => {
    return async (dispatch) => {
        const type = FETCH_COMMITTEE_DETAILS;

        try {
            dispatch({ type, payload: { status: STATUS.LOADING } });
            const query = getCommitteeDetailsQuery;
            const variables = { committeeId };
            const response = await API.graphql({ query, variables });

            if (response) {
                const payload = { status: STATUS.SUCCESS, data: response?.data?.getCommitteeDetails };
                dispatch({ type, payload });
                return;
            }

            // Handle errors
            const errorPayload = {
                status: STATUS?.ERROR,
                alert: {
                    MESSAGE: response?.data?.getCommitteeDetails?.error?.message,
                    SEVERITY: SEVERITY_LEVELS.ERROR,
                },
            };

            dispatch({ type, payload: errorPayload });
        } catch (error) {
            console.error(error);
            console.error(error?.errors?.[0]?.message);

            const errorPayload = {
                status: STATUS.ERROR,
                alert: {
                    MESSAGE: error?.errors?.[0]?.message,
                    SEVERITY: SEVERITY_LEVELS.ERROR,
                },
            };
            dispatch({ type, payload: errorPayload });
        }
    };
}

export const setSelectedReviewer = (reviewer) => {
    return async (dispatch) => {
        const type = SET_SELECTED_REVIEWER;
        dispatch({ type, payload: reviewer });
    };
}