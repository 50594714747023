export const listAssignedApplicationsQuery = `query ListAssignedApplicationsQuery($reviewerEmployeeId: String!, $committeeId: String!, $countsOnly: Boolean) {
    listAssignedApplications(reviewerEmployeeId: $reviewerEmployeeId, committeeId: $committeeId, countsOnly: $countsOnly) {
        applications {
            applicantEmployeeId
            committeeId
            term
            dataAsJsonMap
        }
        quota
        assigned {
            committeeName
            count
        }
    }
}`;

export const getReviewerDetailsByIdQuery = `query GetReviewerDetailsById($reviewerEmployeeId: String!) {
    getReviewerDetailsById(reviewerEmployeeId: $reviewerEmployeeId) {
        campusId
        committeeDetails {
          committeeId
          experience
          lastAssignedDate
          pending
          submitted
          total
        }
        firstName
        lastAssignedDate
        lastName
        reviewerEmployeeId
        totalPending
        totalSubmitted
      }
    }`;