import { useSelector } from 'react-redux';
import { UNASSIGN_APPLICATION } from '../../../../actions-index';
import {
  getCommitteeReviewersState,
  getStudentState,
} from '../../../../Common/redux/selectors';
import { STATUS } from '../../../../util/constants';

const useAutocompleteReviewers = (props = {}) => {
  const { type } = props;

  const { reviewers = [] } = useSelector((state) => {
    const studentInfo = getStudentState(state)?.data ?? {};

    //console.log("studentInfo", studentInfo?.readers);
    const assignedReviewers = studentInfo?.readers?.filter?.(
      (reviewer) =>
        reviewer.status !== 'Pending' || reviewer.status === 'RESUMELATER'
        // reviewer.recommendation === 'Pending'
    );

    let transformedReviewerData = [];
    assignedReviewers?.forEach?.((reviewer) =>
      transformedReviewerData?.push({
        emplid: reviewer.readerEmployeeId,
        firstName: reviewer.readerFirstName,
        lastName: reviewer.readerLastName,
        status: reviewer.status
      })
    );
    //console.log("transformedReviewerData", transformedReviewerData)
    const committeeReviewersFromRedux = getCommitteeReviewersState(state)?.data;
    return {
      reviewers:
        type === UNASSIGN_APPLICATION
          ? transformedReviewerData?.filter((reviewer) => reviewer?.status !== STATUS?.CANCELLED && reviewer?.status !== STATUS?.SUBMITTED)
          : committeeReviewersFromRedux?.filter?.((reviewer) => {
              return transformedReviewerData?.every((rev) => {
                return reviewer?.emplid !== rev?.emplid || (reviewer.admin === true && rev?.status === STATUS.CANCELLED)
              });
            }),
    };
  });
  
  //console.log("reviewers", reviewers);
  const reviewersList = reviewers?.map((reviewer = {}) => {
    let label = '';
    if (reviewer.firstName) label += reviewer.firstName + ' ';
    if (reviewer.lastName) label += reviewer.lastName;
    // if (reviewer.campusId) label = `${label} (${reviewer.campusId})`;

    return {
      ...reviewer,
      label,
    };
  });

  return {
    options: reviewersList,
  };
};

export default useAutocompleteReviewers;
