import {
  GET_ULTIMATE_SEARCH_RESULTS
} from '../actions-index';
// import axios from 'axios';
import { API } from 'aws-amplify';

import { searchCommitteeReviewers } from '../Navbar/query';
import { SEVERITY_LEVELS, STATUS } from '../util/constants';
import { getSearchResultsQuery } from './query';
// import { transformReviewer } from './transform';

export const getUltimateSearchResults = (applicantEmployeeId) => {
  let response;
  const dispatchType = GET_ULTIMATE_SEARCH_RESULTS;

  return async (dispatch) => {
    dispatch({
      type: dispatchType,
      payload: {
        status: STATUS.LOADING,
      },
    });

    try {
      response = await API.graphql({
        query: getSearchResultsQuery,
        variables: {
          applicantEmployeeId: applicantEmployeeId.trim(),
        },
      });

      if (response) {
        dispatch({
          type: dispatchType,
          payload: {
            status: STATUS.SUCCESS,
            data: response?.data?.getSearchResults.data,
            // data: transformReviewer(response?.data?.getUltimateSearchResults),
          },
        });
      }
      return response;
    } catch (error) {
      console.log('error:', error);
      dispatch({
        type: dispatchType,
        payload: {
          status: STATUS.ERROR, 
          alert: {
            MESSAGE: error?.errors?.[0]?.message
              ? error?.errors?.[0]?.message
              : 'Unexpected Error',
            SEVERITY: SEVERITY_LEVELS.ERROR,
          },
        },
      });
    }
  };
};

export const fetchSearchResultsByNameNumberUltimate = (value) => {
  let response;
  const dispatchType = GET_ULTIMATE_SEARCH_RESULTS;

  return async (dispatch) => {
    dispatch({
      type: dispatchType,
      payload: {
        status: STATUS.LOADING,
      },
    });

    try {
      response = await API.graphql({
        query: searchCommitteeReviewers,
        variables: {
          searchString: value,
        },
      });
      if (response?.data?.searchCommitteeReviewers?.data?.length > 0) {
        dispatch({
          type: dispatchType,
          payload: {
            status: STATUS.SUCCESS,
            data: response.data.searchCommitteeReviewers.data,
          },
        });
      } else {
        dispatch({
          type: dispatchType,
          payload: {
            status: STATUS.ERROR,
            data: [],
            alert: {
              MESSAGE: response?.data?.searchCommitteeReviewers?.error?.message,
              SEVERITY: SEVERITY_LEVELS.ERROR,
            },
          },
        });
      }
    } catch (error) {
      console.log('error:', error);
      dispatch({
        type: dispatchType,
        payload: {
          status: STATUS.ERROR,
          alert: {
            MESSAGE: error?.errors[0]?.message,
            SEVERITY: SEVERITY_LEVELS.ERROR,
          },
        },
      });
    }
  };
};
