import { FETCH_ONE_READER, UPDATE_MANAGE_REVIEWER } from '../actions-index';
import { API } from 'aws-amplify';
import {getManageReviewerQuery} from './query';
import {updateManageReviewerMutation} from './mutation';
import { SEVERITY_LEVELS, STATUS } from '../util/constants';

export const fetchReaderAction = (reviewerEmployeeId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_ONE_READER,
        payload: { loading: true },
      });

      const response = await API.graphql({
        query: getManageReviewerQuery,
        variables: {
          reviewerEmployeeId,
        }
      });

      if (response) {
        dispatch({
          type: FETCH_ONE_READER,
          payload: {reviewer: response?.data?.getManageReviewer},
        });
      }
      dispatch({
        type: FETCH_ONE_READER,
        payload: { loading: false },
      });
    } catch (error) {
      console.log('GetManageReviewer Error:', error);
    }
  };
};

export const updateManageReviewer = (reviewers)  => {
  let response;
  return async (dispatch) => {    
    dispatch({
      type: UPDATE_MANAGE_REVIEWER,
      payload: { status: STATUS?.LOADING },
    });
    try {
      response = await API.graphql({
        query: updateManageReviewerMutation,
        variables: { input: reviewers },
      });

      if (
        response?.data?.updateManageReviewer?.status?.toUpperCase() === STATUS.SUCCESS
      ) {
        dispatch({
          type: UPDATE_MANAGE_REVIEWER,
          payload: {
            status: SEVERITY_LEVELS?.SUCCESS,
            alert: {
              MESSAGE: response?.data?.updateManageReviewer?.details,
              SEVERITY: SEVERITY_LEVELS?.SUCCESS,
            },
          },
        });
      } else {
        dispatch({
          type: UPDATE_MANAGE_REVIEWER,
          payload: {
            status: SEVERITY_LEVELS?.ERROR,
            alert: {
              MESSAGE: response?.data?.updateManageReviewer?.error?.message,
              SEVERITY: SEVERITY_LEVELS?.ERROR,
            },
          },
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_MANAGE_REVIEWER,
        payload: {
          status: STATUS?.ERROR,
          alert: {
            MESSAGE: error?.errors[0]?.message,
            SEVERITY: SEVERITY_LEVELS?.ERROR,
          },
        },
      });
    }
  };
};



