import { Fragment, Suspense, useContext } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { AppContext } from "../../App/context";
import withErrorBoundary from "../../Common/hocs/withErrorBoundary";
import PATHS, { authenticationFlowPaths } from "../paths";
import NoComponentFound from "../views/NoComponentFound";
import RenderableView from "./RenderableView";

function Routes({ setPageAppHeader, setContainerSize, loading }) {
  const { authorized } = useContext(AppContext);
  let RoutesToRender = PATHS.map((pathInformation, index) => (

    <Route
      key={`route${encodeURIComponent(pathInformation.title)}#${index}`}
      exact
      path={pathInformation.path}
      render={(renderProps) => {
        return (
          <RenderableView
            pathInformation={pathInformation}
            renderProps={renderProps}
            setPageAppHeader={setPageAppHeader}
            setContainerSize={setContainerSize}
          />
        );
      }}
    />
  ));

  return (
    <Fragment>
      <Suspense fallback={<div>Loading</div>}>
        <Switch>
          {authenticationFlowPaths.map(({ path, Component }, index) => (
            <Route
              key={`route${encodeURIComponent(path)}#${index}`}
              exact
              path={path}
              render={() => <Component />}
            />
          ))}
          {authorized && RoutesToRender}
          <Route render={() => <NoComponentFound />} />
        </Switch>
      </Suspense>
    </Fragment>
  );
}

export default withRouter(withErrorBoundary(Routes));
