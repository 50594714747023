import {
    ASSIGN_APPLICATION,
    ASSIGN_TRAINEE,
    UNASSIGN_TRAINEE,
    CLEAR_NOTIFY_ADMIN,
    CREATE_TRAINING,
    NOTIFY_ADMIN,
    OVERRIDE_RECOMMENDATION,
    REASSIGN_APPLICATION,
    REPORT_CONFLICT,
    RESTART_APPLICATION,
    SAVE_REVIEW,
    UNASSIGN_APPLICATION,
    UPDATE_PRIORITY,
  } from '../../../actions-index';
import { AUTH_TYPES } from "../../../util/constants"
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import RemoveIcon from '@material-ui/icons/Remove';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import VoiceOverOffIcon from '@material-ui/icons/VoiceOverOff';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import ErrorIcon from '@material-ui/icons/Error';
import { STATUS } from '../../../util/constants';

const actionLabels = {
    [REPORT_CONFLICT]: 'Conflict of Interest',
    [SAVE_REVIEW]: 'Resume Later',
    [ASSIGN_APPLICATION]: 'Assign Reviewer',
    [REASSIGN_APPLICATION]: 'Reassign Reviewer',
    [UNASSIGN_APPLICATION]: 'Unassign Reviewer',
    [OVERRIDE_RECOMMENDATION]: 'Administrative Recommendation',
    [RESTART_APPLICATION]: 'Restart Evaluation',
    [UPDATE_PRIORITY]: 'Prioritize Application',
    [NOTIFY_ADMIN]: 'Notify Admin',
    [CLEAR_NOTIFY_ADMIN]: 'Clear Notify Admin',
    [CREATE_TRAINING]: 'Create Training',
    [ASSIGN_TRAINEE]: 'Assign Trainee',
    [UNASSIGN_TRAINEE]: 'Unassign Trainee',
}


let menuItems = [
    {
        type: REPORT_CONFLICT,
        roles: [AUTH_TYPES.REVIEWER, AUTH_TYPES.ADMIN_REVIEWER],
        icon: <ErrorIcon />,
    },
    {
        type: SAVE_REVIEW,
        roles: [AUTH_TYPES.REVIEWER, AUTH_TYPES.ADMIN_REVIEWER],
        icon: <BookmarkBorderIcon />,
    },
    {
        type: ASSIGN_APPLICATION,
        roles: [AUTH_TYPES.ADMIN, AUTH_TYPES.ADMIN_REVIEWER],
        icon: <PersonAddIcon />,
        specialCaseFilterFns: [ isInProgress, isAssignable ]
    },
    {
        type: REASSIGN_APPLICATION,
        roles: [AUTH_TYPES.ADMIN, AUTH_TYPES.ADMIN_REVIEWER],
        icon: <SyncAltIcon />,
        specialCaseFilterFns: [ isInProgress, ]
    },
    {
        type: UNASSIGN_APPLICATION,
        roles: [AUTH_TYPES.ADMIN, AUTH_TYPES.ADMIN_REVIEWER],
        icon: <RemoveIcon />,
        specialCaseFilterFns: [ isInProgress, ]
    },
    {
        type: OVERRIDE_RECOMMENDATION,
        roles: [AUTH_TYPES.ADMIN],
        icon: <SupervisedUserCircleIcon />,
        specialCaseFilterFns: [ canAdminOverride, ]
    },
    {
        type: RESTART_APPLICATION,
        roles: [AUTH_TYPES.ADMIN, AUTH_TYPES.ADMIN_REVIEWER],
        icon: <RotateLeftIcon />,
    },
    {
        type: UPDATE_PRIORITY,
        roles: [AUTH_TYPES.ADMIN, AUTH_TYPES.ADMIN_REVIEWER],
        icon: <AssignmentLateIcon />, 
        specialCaseFilterFns: [ notPriorityApplication ]
    },
    {
        type: NOTIFY_ADMIN,
        roles: [AUTH_TYPES.REVIEWER, AUTH_TYPES.ADMIN_REVIEWER],
        icon: <RecordVoiceOverIcon />,
        specialCaseFilterFns: [ notAlreadyNotifyingAnAdmin  ]
    },
    {
        type: CLEAR_NOTIFY_ADMIN,
        roles: [AUTH_TYPES.ADMIN, AUTH_TYPES.ADMIN_REVIEWER],
        icon: <VoiceOverOffIcon />,
        specialCaseFilterFns: [ canClearNotifyAdmin, ]
    },
    {
        type: CREATE_TRAINING,
        roles: [AUTH_TYPES.ADMIN, AUTH_TYPES.ADMIN_REVIEWER],
        icon: <AssignmentIcon />,
        specialCaseFilterFns: [ applicantTrainingDoesntExist, ]
    },
    {
        type: ASSIGN_TRAINEE,
        roles: [AUTH_TYPES.ADMIN, AUTH_TYPES.ADMIN_REVIEWER],
        icon: <AssignmentIcon />,   
        includeOnTrainingPage: true,
        specialCaseFilterFns: [ isTrainingPageOnly, ]
    },
    {
        type: UNASSIGN_TRAINEE,
        roles: [AUTH_TYPES.ADMIN, AUTH_TYPES.ADMIN_REVIEWER],
        icon: <HighlightOffIcon />,
        includeOnTrainingPage: true,
        specialCaseFilterFns: [ isTrainingPageOnly, ]
    }
];

menuItems = menuItems.map((item) => ({
    ...item,
    title: actionLabels[item.type]
}))

// Special case functions; allows for more fine-grain control of menu items in different states

// Show ONLY on the training page menu
function isTrainingPageOnly(extraInfo) { return extraInfo.isTraining; }

// Show ONLY if there isn't already one created
function applicantTrainingDoesntExist(extraInfo) { return extraInfo.applicantTrainingDoesntExist; }

function notAlreadyNotifyingAnAdmin(extraInfo) { return !extraInfo.isCurrentReviewerNotifyAdmin; }

function notPriorityApplication(extraInfo) { return !extraInfo.priority }

function canClearNotifyAdmin(extraInfo) { return extraInfo.isNotifyAdminActive; }

function canAdminOverride(extraInfo) { return !(extraInfo.status === STATUS.INPROGRESS || extraInfo.status === STATUS.ADMINREVIEW); }

function isInProgress(extraInfo) { return extraInfo.status === STATUS.INPROGRESS; }

function isAssignable(extraInfo) { return extraInfo.assignable === true; }


const descriptions = {
    [REPORT_CONFLICT]: 'You are reporting a conflict with this application.',
    [SAVE_REVIEW]: 'You are saving your work and will be able to resume later.',
    [ASSIGN_APPLICATION]: ({ reviewer, committeeName }) => `You are assigning ${reviewer.label ?? " a reviewer"} for this applicant for the
    ${committeeName} Committee.`,
    [REASSIGN_APPLICATION]: 'Choose an existing reviewer and a new reviewer from the list below to reassign to this application.',
    [UNASSIGN_APPLICATION]: 'Choose a reviewer from the list below to unassign from this application.',
    [OVERRIDE_RECOMMENDATION]: 'Your ability to give an administrative recommendation will be unlocked.',
    [RESTART_APPLICATION]: 'You are cancelling the current evaluation and starting a new one.',
    [UPDATE_PRIORITY]: 'This will prioritize the application for all current and future reviewers. ',
    [NOTIFY_ADMIN]: 'Please choose at least one reason for notifying an administrator:',
    [CLEAR_NOTIFY_ADMIN]: 'Clear the notification for the below reviewers?',
    [CREATE_TRAINING]: 'Choose from the options below to create a training application for reviewers.',
    [ASSIGN_TRAINEE]: 'Choose from the options below to assign a training application for reviewers.',
}

export {
    menuItems,
    actionLabels,
    descriptions
}
