export const listAdmissionsCyclesQuery = `query ListAdmissionsCyclesQuery {
    listAdmissionsCycles {
        cycles {
            isCurrent
            adminEmployeeId
            adminFullName
            cycleKey
            datetimeCreated
            terms {
                year
                description
            }
            yearEnd
            yearStart
        }
        termsDescriptions
    }
}`;
