import { FiberManualRecord } from "@material-ui/icons";
import Skeleton from "@material-ui/lab/Skeleton";
import { Mui } from "@osu/react-ui";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { COMMITTEE_COLOR_MAP } from "../../util/constants";
import Actions from "./Actions";
import useRoleAuthentication from "../../Common/hooks/useRoleAuthentication";

const useStyles = Mui.makeStyles((theme) => {
  return {
    chipPriority: {
      backgroundColor: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      marginLeft: '1rem'
    },
    ChipLabel: {
      color: "black",
      display: "flex",
      alignItems: "center",
    },
  };
});


const ApplicantAppBar = (props) => {
  const { pageAppHeader } = props;
  const classes = useStyles();
  const location = useLocation();
  const authDetails = useRoleAuthentication();
  const isTraining = location.pathname.includes('/training/')
  const isTrainingReport = location.pathname.includes('/reports/training/')
  window.dispatchEvent(new Event("scroll"));

  return (
  !isTrainingReport &&
    <Mui.Toolbar className="padding-y-1 display-flex justify-content-space-between align-items-center">
      {pageAppHeader.loading === true ? (
        <>
          <Skeleton variant="text" height={60} width="100%" />
        </>
      ) : (
        pageAppHeader?.name ? <>
          <Mui.Box style={{display: 'flex', flexDirection: 'column'}}>
            <Mui.Box style={{display: 'flex', flexDirection: 'row'}}>
              <Mui.Typography
                component="span"
                variant="h5"
              >
                {pageAppHeader?.name}
              </Mui.Typography>
              <Mui.Chip
                style={{
                  marginLeft: "1rem",
                  border: `1px solid ${
                    COMMITTEE_COLOR_MAP[pageAppHeader?.committee]
                  }`,
                }}
                label={
                  <Mui.Typography className={classes.ChipLabel}>
                    <FiberManualRecord
                      style={{
                        color: COMMITTEE_COLOR_MAP[pageAppHeader?.committee],
                        verticalAlign: "middle", // Add verticalAlign property
                      }}
                    />
                    {pageAppHeader?.committeeName}
                  </Mui.Typography>
                }
                variant="outlined"
              />
              {pageAppHeader?.priority && authDetails.ADMIN && <Mui.Chip
                className={classes.chipPriority}
                label={
                  <Mui.Typography className={classes.ChipLabel} style={{color:'white '}}>
                    Priority
                  </Mui.Typography>
                }
                variant="outlined"
              />}
              {isTraining && <Mui.Chip
                style={{
                  border: `1px solid orange`,
                  backgroundColor: 'orange',
                  marginLeft: '1rem'
                }}
                label={
                  <Mui.Typography className={classes.ChipLabel} style={{color: 'black'}}>
                    Training
                  </Mui.Typography>
                }
                variant="outlined"
              />}
            </Mui.Box>
            <Mui.Box>
              <Mui.Typography color="secondary">
                ID: {pageAppHeader?.emplId}
                {pageAppHeader?.dateOfBirth &&
                  " | " + "DOB: " + new Date(pageAppHeader?.dateOfBirth).toLocaleDateString()}
              </Mui.Typography>
            </Mui.Box>
        </Mui.Box>
          {!location?.pathname?.includes('view') &&
          <Actions pageAppHeader={pageAppHeader} isTraining={isTraining}/>
      }
        </> : <></>
      )}
    </Mui.Toolbar>
  );
};
export default ApplicantAppBar;
