import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { adminReviewCommitteeList } from '../components/OneTrueDataGrid/reportConstants';

function getStateData(state) {
  let newState = {};
  if (state?.authentication?.user?.osuid) {
    newState.jwt_osuid = state.authentication.user.osuid;
    newState.cognitoGroups = state.authentication.user.cognitoGroups;
  }

  if (state?.studentData) {
    newState.committee = state?.studentData?.student?.data?.demographics?.committeeId;
    newState.readers = state?.studentData?.student?.data?.readers;
  }
  return newState;
  
}

function isAdminOfAnyCommittee(state) { return state?.cognitoGroups?.some((group) => group.includes("ADMIN")); }

function listAdminCommittees(state) {
    const adminCommitteeGroups = state?.cognitoGroups?.filter?.(e => e?.includes?.("-ADMIN"));
    if (!adminCommitteeGroups || !(adminCommitteeGroups?.length > 0)) { return []; }

    const committeeIds = adminCommitteeGroups.map?.((committeeId) => {
            committeeId = committeeId.split?.("-").slice(0, -1).join("-").toLowerCase?.(); // Drop -ADMIN at the end
            return committeeId;
        });

    return committeeIds;
}

function checkAdmin(state, overrideCommitteeId) {
  if(state?.cognitoGroups?.some((group) => group.includes("ADMIN")) && !state?.committee && !overrideCommitteeId)
    return true;

  if (overrideCommitteeId) {
    return state?.cognitoGroups?.some((group) => group.includes(`${overrideCommitteeId.toUpperCase()}-ADMIN`));
  }

  const expectedCommitteeAdminRole = `${state?.committee?.toUpperCase()}-ADMIN`;
  return state?.cognitoGroups?.some(group => group.includes(expectedCommitteeAdminRole));
}

function checkReader(state, isAdmin, overrideCommitteeId) {
  if(overrideCommitteeId) {
    const expectedCommitteeRole = `${overrideCommitteeId.toUpperCase()}-REVIEWER`;
    if (!isAdmin && !state?.cognitoGroups?.includes(expectedCommitteeRole)) { return false; }
    return !!matchReader(state); // Is assigned to the given applicant
  }
  else if (state?.committee) {
    const expectedCommitteeRole = `${state?.committee?.toUpperCase()}-REVIEWER`;
    if (!isAdmin && !state?.cognitoGroups?.includes(expectedCommitteeRole)) { return false; }
    return !!matchReader(state); // Is assigned to the given applicant
  }

  // Handle all other pages
  return state?.cognitoGroups?.some?.(group => group.includes("-REVIEWER")) ? true : false;
}

function checkRole(state, role) {
  return state?.cognitoGroups?.includes(role) ? true : false;
}

function matchReader(state) {
  if (!state?.readers) return false;
  let found = state.readers.filter((reader) => {
    if (reader.readerEmployeeId === state.jwt_osuid && (reader.status !== "SUBMITTED" && reader.status !== "CANCELLED") )
      return reader;
    return null;
  });

  if(found.length > 0) 
    return found;
  else
    return false;
}

function checkInitialApprover(state) {
  let reviewer = matchReader(state);
  if (reviewer) {
    return reviewer?.[0]?.initialApprover
  }
  else
    return false;
}


function useRoleAuthentication(parms = { overrideCommitteeId: null}) {
  const location = useLocation();
  const stateData = useSelector((state) => getStateData(state), shallowEqual);
  const isAdmin = checkAdmin(stateData, parms.overrideCommitteeId);
  const isReader = checkReader(stateData, isAdmin, parms.overrideCommitteeId);
  const isInitialApprover = checkInitialApprover(stateData);
  const isSLTReadOnly = checkRole(stateData, 'SLT-READ-ONLY')
  const isCycleManager = checkRole(stateData, 'CYCLE-MANAGEMENT');
  const hasReadAccess = checkRole(stateData, 'READ-ACCESS'); // only admins/reviewers receive this role
  
  const isSearch = location?.pathname?.includes('/search');
  const isReadOnlyView = location?.pathname?.includes('/view');
  const isManageCyclesView = location?.pathname?.includes('/manage-cycles');

  let states = {
    ADMIN: false,
    REVIEWER: false,
    ADMIN_REVIEWER: false,
    UNAUTHORIZED: true,
    HAS_ROLES: false,
    INITIAL_APPROVER: false,
    SLT_READ_ONLY: false
  };
  
  states.CURRENT_EMPLID = stateData?.jwt_osuid;
  states.ADMIN_REVIEWER = isAdmin && isReader;
  states.REVIEWER = isReader;
  states.ADMIN = isAdmin;
  states.ADMIN_ANYCOMMITTEE = isAdminOfAnyCommittee(stateData);
  states.INITIAL_APPROVER = isInitialApprover;
  states.SLT_READ_ONLY = isSLTReadOnly
  states.UNAUTHORIZED = !states.REVIEWER && !states.ADMIN && stateData?.committee;
  states.HAS_ROLES = stateData?.cognitoGroups?.length > 0;
  states.ADMIN_COMMITTEES = listAdminCommittees(stateData);
  states.ADMINREVIEW_COMMITTEE_ADMIN = adminReviewCommitteeList.some(committee => committee.adminReview == true && states?.ADMIN_COMMITTEES?.includes?.(committee.id));
  states.CYCLE_MANAGER = isCycleManager;
  states.READ_ACCESS = hasReadAccess;
  // check additional view situations
  if(isReadOnlyView === true) {
    states.ADMIN = true;
    states.UNAUTHORIZED = states.SLT_READ_ONLY === false ? true : false;
  }

  if(isSearch === true && ( states.ADMIN === false && states.SLT_READ_ONLY === false)) {
    states.UNAUTHORIZED = true;
  }

  if(isManageCyclesView === true && states.CYCLE_MANAGER === false) {
    states.UNAUTHORIZED = true;
  }

  // console.log("states:", states)
  if(!stateData?.jwt_osuid)
    states.HAS_ROLES = undefined; //prevent a false unauth

  return states;
}

export default useRoleAuthentication;