/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_content_delivery_bucket": "admissionsreview-20221221163418-hostingbucket-main",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d34fx8u01kl95c.cloudfront.net"
};


export default awsmobile;
