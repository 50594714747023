import { API, graphqlOperation } from 'aws-amplify';
import { onFullDownloadSubscription } from './subscriptions';
import { EXPORT_REPORT_MESSAGE } from "../../../actions-index";
import { STATUS } from "../../../util/constants";

export function getFullDownloadData(fdd) {
    return {
        isFullDownload: !!(fdd.isFullDownload),
        adminEmployeeId: fdd.adminEmployeeId ?? "",
        committeeId: fdd.committeeId ?? "",
        datetimeId: fdd.datetimeId ?? "",
        filename: fdd.filename,
    };
}

let activeSubscriptions = [];
let downloads = [];

/* CSV Download Process:
   1. Click "Download Export" button
   2. normal getReportData is called with fullDownloadData options
   3. lambda #1 (get-report) recognizes fullDownloadData.isFullDownload=true & kicks off lambda #2 (get-report-fulldl) to get ALL data
   4. lambda #2 (get-report-fulldl) pulls all data, and sends that data through subscription in chunks
   5. this module gathers chunks, concats them, and prompts user with a CSV download
 */
export async function subscribeToFullDownload(dispatch, adminEmployeeId, committeeId, datetimeId) {
    const status = STATUS.ERROR, type = EXPORT_REPORT_MESSAGE;
    
    function handleIncomingSubscriptionData(dispatch, value) {
        try {
            const responseData = value?.data?.onFullDownload ?? {};
            const { filename, rawText } = responseData;
            saveFilenameToActiveSubscriptions(responseData, filename); // We get filenames from the backend
            
            if (filename && rawText) {
                downloads.push({ filename, rawText });
                checkIfAnyDownloadsAreComplete();
                
                if (activeSubscriptions.length === 0) { dispatch({ type, downloadComplete: true }); }
                return;
            }
            
            const message = `Received export data, but missing filename=[${filename}] or rawText data=[${rawText?.length}]`;
            dispatch({ status, type, message });
        } catch (error) {
            console.error("Error and raw response:", error, value);
            dispatch({ status, type, message: JSON.stringify(error) });
        }
    }
    
    const subscriptionHandlerFunctions = {
        next: ({ value }) => handleIncomingSubscriptionData(dispatch, value),
        error: (error) => console.warn("Error while attempting to subscribe", error),
    };
    
    const variables = { adminEmployeeId, committeeId, datetimeId };
    const subscriptionGql = graphqlOperation(onFullDownloadSubscription, variables);
    const subscription = API.graphql( subscriptionGql )
        .subscribe(subscriptionHandlerFunctions);

    let newActiveSubscription = { variables, subscription };
    newActiveSubscription.downloadTimeoutTimer = setTimeout(() => unsubscribeForTimeout(dispatch, newActiveSubscription), 60000);
    
    activeSubscriptions.push(newActiveSubscription);
    
    dispatch({ type, downloadComplete: false }); // Disable download button
    // console.info("New subscription complete to", variables, ", response:", subscription);
}

// We'll use this filename later when unsubscribing
function saveFilenameToActiveSubscriptions({ adminEmployeeId, committeeId, datetimeId }, filename) {
    if (!adminEmployeeId || !committeeId || !datetimeId) { console.warn("Missing fields in report export data", adminEmployeeId, committeeId, datetimeId); }
    
    const subscriptionToUpdate = activeSubscriptions.find(subscription => {
        return adminEmployeeId == subscription.variables.adminEmployeeId
                && committeeId == subscription.variables.committeeId
                && datetimeId  == subscription.variables.datetimeId;
    });
    
    if (subscriptionToUpdate) { subscriptionToUpdate.filename = subscriptionToUpdate.filename ?? filename; }
}

function checkIfAnyDownloadsAreComplete() {
    const uniqueFilenames = downloads.reduce((allFilenames, currentDownload) => {
        if (!currentDownload.wasDownloaded) { allFilenames.add(currentDownload.filename); }
        return allFilenames;
    }, new Set());
    
    for (const filename of uniqueFilenames) {
        const isDownloaded = checkIfDownloadIsComplete(filename);
        if (isDownloaded) { unsubscribeByFilename(filename); }
    }
}

function checkIfDownloadIsComplete(filename) {
    // console.info("EXPORTS: Checking if download is complete", filename);
    const chunks = downloads.filter(download => download.filename == filename);
    if (chunks.length === 0) { return false; }
    
    const chunk1Data = JSON.parse(chunks[0].rawText);
    const expectedChunkCount = chunk1Data.count;
    
    // console.warn("EXPORTS: Expecting more chunks from subscription, download is not yet complete:", chunks.length, "/", expectedChunkCount);
    if (expectedChunkCount > chunks.length) { return false; }
    
    // console.info("EXPORTS: Ready for download, initiating", filename);
    console.info("dachunks", chunks);
    const finalRawText = chunks.map(chunk => JSON.parse(chunk.rawText))
        .sort((a, b) => {
            if (a.index === b.index) { console.warn("EXPORTS: Received a duplicate message somehow, that's bad!", a.index); }
            return a.index < b.index ? -1 : a.index > b.index ? 1 : 0;
        })
        .filter((value, index, fullArray) => fullArray.findIndex(currentValue => currentValue === value) === index) // ignore duplicates
        .map(chunk => chunk.text)
        .join("");
    
    triggerFileDownload(filename, finalRawText);
    chunks.forEach(download => download.wasDownloaded = true); // Mark as downloaded
    downloads = downloads.filter(download => download.filename !== filename); // Remove so we don't accidentally re-kick off downloads
    return true;
}

function triggerFileDownload(filename, rawText) {
    var element = document.createElement("a");
    element.setAttribute("href", `data:text/plain;charset=utf-8-sig,${encodeURIComponent(rawText)}`);
    element.setAttribute("download", filename);
    element.style.display = "none";
    
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

function unsubscribeByFilename(filename) {
    const activeSubscriptionWrapper = activeSubscriptions.find(sub => sub.filename === filename);
    if (!activeSubscriptionWrapper) { console.warn(`Unable to find subscription for filename ${filename}, skipping unsubscribe`); return; } // No need to throw
    
    unsubscribeByWrapper(activeSubscriptionWrapper);
}

function unsubscribeForTimeout(dispatch, activeSubscriptionWrapper) {
    unsubscribeByWrapper(activeSubscriptionWrapper);
 
    const status = STATUS.ERROR, type = EXPORT_REPORT_MESSAGE;
    dispatch({ status, type, message: "Report export download timed out after 60 seconds" });
}

function unsubscribeByWrapper(activeSubscriptionWrapper) {
    clearTimeout(activeSubscriptionWrapper?.downloadTimeoutTimer);
    activeSubscriptionWrapper.subscription?.unsubscribe(); // Disconnect websocket
    activeSubscriptions = activeSubscriptions.filter(sub => sub != activeSubscriptionWrapper);
}