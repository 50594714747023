import { REPORTS_DASH } from '../actions-index';
import { STATUS } from '../util/constants';

export const reportsDashReducer = (state, action) => {
    switch (action.type) {
        case REPORTS_DASH: {
            return {
                status: action.payload.status ?? "",
                customReports: action.payload.data?.reports ?? [],
                lastUpdatedTime: action.payload.data?.lastUpdatedTime ?? "",
            };
        }

        default: return state ?? { // Initial state
            customReports: [],
            lastUpdatedTime: "",
            status: STATUS.LOADING,
        };
    }
};