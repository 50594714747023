import React, { Fragment, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Helmet, PageHeadingContext } from "@osu/react-ui";

export function RenderableView({
  pathInformation = {},
  renderProps = {},
  setPageAppHeader,
  setContainerSize,
}) {
  const {
    props = {},
    Component,
    noIndex,
    title: pathTitle,
    titleProps,
    containerSize = "xl",
  } = pathInformation;
  const { setHeading, setOptions } = useContext(PageHeadingContext);
  renderProps.username = useSelector((state) => state?.authentication?.user?.name);
  renderProps.reportName = useSelector((state) => state?.athenaReportReducer?.reportName);
  
  const title =
    typeof pathTitle === "function" ? pathTitle(renderProps) : pathTitle;
  const authRequired = pathInformation.authRequired;

  useEffect(() => {
    title && setHeading(title)
    return () => {
      setHeading("")
    }
  }, [setHeading, title])

  useEffect(() => {
    setContainerSize(containerSize)
  }, [setContainerSize, containerSize])

  useEffect(() => {
    const options = Object.assign(
      { variant: "h2", className: "margin-bottom-2" },
      titleProps
    );
    setOptions(options);
  }, [setOptions, titleProps]);

  return (
    <Fragment>
      {(noIndex || authRequired) && (
        <Helmet>
          <meta id="meta-hidden" name="robots" content="noindex" />
        </Helmet>
      )}
      <Component
        setPageAppHeader={setPageAppHeader}
        {...renderProps}
        {...props}
      />
    </Fragment>
  );
}

RenderableView.defaultProps = {
  renderProps: {},
  pathInformation: {
    props: {},
    noIndex: false,
    authRequired: false,
  },
};

RenderableView.propTypes = {
  pathInformation: PropTypes.shape({
    path: PropTypes.string.isRequired,
    props: PropTypes.object,
    // title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    // titleProps: PropTypes.object,
    Component: PropTypes.oneOfType([PropTypes.element, PropTypes.object])
      .isRequired,
    noIndex: PropTypes.bool,
    authRequired: PropTypes.bool,
  }).isRequired,
  renderProps: PropTypes.object,
  createTitle: PropTypes.func,
};

export default RenderableView;
