import { API } from 'aws-amplify';
import {
  ACTIVATE_REVIEWERS,
  FETCH_ALL_REVIEWERS_ACTIVE,
  UPDATE_REVIEWERS_STATUS,
} from '../actions-index';
import { SEVERITY_LEVELS, STATUS } from '../util/constants';
import { activateReviewersMutation } from './mutation';
import { getCommitteeReviewersFromDB } from './query';

export const getCommitteeReviewersActive = ({ committeeId, createdDate, status }) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_ALL_REVIEWERS_ACTIVE,
        payload: {
          status: STATUS?.LOADING,
        },
      });

      const response = await API.graphql({
        query: getCommitteeReviewersFromDB,
        variables: {
          committeeId,
          createdDate,
          // status,
          includeInactive: true
        },
      });

      if (response) {
        dispatch({
          type: FETCH_ALL_REVIEWERS_ACTIVE,
          payload: {
            status: STATUS?.SUCCESS,
            data: response?.data?.getCommitteeReviewersFromDB,
          },
        });
      } else {
        dispatch({
          type: FETCH_ALL_REVIEWERS_ACTIVE,
          payload: {
            status: STATUS?.ERROR,
            alert: {
              MESSAGE:
                response?.data?.getCommitteeReviewersFromDB?.error?.message,
              SEVERITY: SEVERITY_LEVELS?.ERROR,
            },
          },
        });
      }
    } catch (error) {
      console.log(error);
      console.log(error?.errors[0]?.message);
      dispatch({
        type: FETCH_ALL_REVIEWERS_ACTIVE,
        payload: {
          status: STATUS.ERROR,
          alert: {
            MESSAGE: error?.errors[0]?.message,
            SEVERITY: SEVERITY_LEVELS?.ERROR,
          },
        },
      });
    }
  };
};

export const activateReviewers = (reviewers) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIVATE_REVIEWERS,
        payload: {
          status: STATUS?.LOADING,
        },
      });


      const response = await API.graphql({
        query: activateReviewersMutation,
        variables: {
          input: reviewers,
        },
      });

      if (response?.data?.activateReviewers?.status.toUpperCase() === STATUS?.SUCCESS) {
        dispatch({
          type: ACTIVATE_REVIEWERS,
          payload: {
            status: STATUS?.SUCCESS,
            alert: {
              MESSAGE: response?.data?.activateReviewers?.details,
              SEVERITY: SEVERITY_LEVELS?.SUCCESS,
            },
          },
        });
        dispatch({
          type: UPDATE_REVIEWERS_STATUS,
          payload: {
            reviewers: reviewers,
          },
        });
      } else {
        dispatch({
          type: ACTIVATE_REVIEWERS,
          payload: {
            status: STATUS?.ERROR,
            alert: {
              MESSAGE: response?.data?.activateReviewers?.error?.message,
              SEVERITY: SEVERITY_LEVELS?.ERROR,
            },
          },
        });
      }
    } catch (error) {
      console.log(error);
      console.log(error?.errors[0]?.message);
      dispatch({
        type: ACTIVATE_REVIEWERS,
        payload: {
          status: STATUS.ERROR,
          alert: {
            MESSAGE: error?.errors[0]?.message,
            SEVERITY: SEVERITY_LEVELS?.ERROR,
          },
        },
      });
    }
  };
};
