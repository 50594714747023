export const onHistoryUpdateApplication = `
    subscription OnHistoryUpdate($applicantEmployeeId: String!) {
      onHistoryUpdate(applicantEmployeeId: $applicantEmployeeId) {
            data {
                firstName
                assignTime
                comments {
                  othersConcerns {
                    label
                    order
                    parentLabel
                  }
                  notify {
                    label
                    order
                    parentLabel
                  }
                  noncompetitive {
                    label
                    order
                    parentLabel
                  }
                  evaluation {
                    label
                    order
                    parentLabel
                  }
                  document {
                    label
                    order
                    parentLabel
                  }
                  competitive {
                    label
                    order
                    parentLabel
                  }
                  adminOverride
                }
                lastName
                recommendation
                reviewerEmployeeId
                stage
                submitTime
                type
              }
              details
              error {
                date
                message
              }
              status
              applicantEmployeeId
            }
    }`;