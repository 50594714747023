import { API } from 'aws-amplify';
import { EXPORT_ADMINREVIEW_REPORT } from '../actions-index';
import { SEVERITY_LEVELS, STATUS } from '../util/constants';
import { exportAdminReviewsReportApplicationsMutation } from './mutation';

export const exportAdminReviewsReportApplications = (committeeId, applicantEmployeeIdsAndTerms) => {
    return async (dispatch) => {
        const type = EXPORT_ADMINREVIEW_REPORT;

        try {
            dispatch({ type, payload: { status: STATUS.LOADING } });
            const variables = { committeeId, applicantEmployeeIdsAndTerms };

            const response = await API.graphql({
                query: exportAdminReviewsReportApplicationsMutation,
                variables: { input: variables },
            });

            if (response.data.exportAdminReviewsReports?.status?.toUpperCase?.() === STATUS.SUCCESS) {
                const status = STATUS.SUCCESS;
                const alert = { MESSAGE: '', SEVERITY: SEVERITY_LEVELS.SUCCESS };
                const payload = { status, alert };

                dispatch({ type, payload });
                return;
            }

            // Handle errors
            const errorPayload = {
                status: STATUS.ERROR,
                alert: {
                    MESSAGE: response?.data?.exportAdminReviewsReports?.error?.message,
                    SEVERITY: SEVERITY_LEVELS.ERROR,
                },
            };

            dispatch({ type, payload: errorPayload });
        } catch (error) {
            console.error(error);
            console.error(error?.errors?.[0]?.message);

            const errorPayload = {
                status: STATUS.ERROR,
                alert: {
                    MESSAGE: error?.errors?.[0]?.message,
                    SEVERITY: SEVERITY_LEVELS.ERROR,
                },
            };

            dispatch({ type, payload: errorPayload });
        }
    };
};