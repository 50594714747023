import { API } from 'aws-amplify';
import { FETCH_REVIEWER_BY_ID } from '../actions-index';
import { SEVERITY_LEVELS, STATUS } from '../util/constants';

import { getReviewerDetailsByIdQuery } from './query';

export const getReviewerDetailsById = (reviewerEmployeeId) => {
    return async (dispatch) => {
        const type = FETCH_REVIEWER_BY_ID;

            try {
                dispatch({ type, payload: { status: STATUS.LOADING } });
                const query = getReviewerDetailsByIdQuery;
                const variables = { reviewerEmployeeId };
                const response = await API.graphql({ query, variables });

                if (response) {
                    const payload = { status: STATUS.SUCCESS, data: response?.data?.getReviewerDetailsById };
                    dispatch({ type, payload });
                    return;
                }

                // Handle errors
                const errorPayload = {
                    status: STATUS?.ERROR,
                    alert: {
                        MESSAGE: response?.data?.getReviewerDetailsById?.error?.message,
                        SEVERITY: SEVERITY_LEVELS.ERROR,
                    },
                };
                dispatch({ type, payload: errorPayload });
            } catch (error) {
                console.error(error);
                console.error(error?.errors?.[0]?.message);

                const errorPayload = {
                    status: STATUS.ERROR,
                    alert: {
                        MESSAGE: error?.errors?.[0]?.message,
                        SEVERITY: SEVERITY_LEVELS.ERROR,
                    },
                };
                dispatch({ type, payload: errorPayload });
            }
        };
    };

