import { API } from 'aws-amplify';
import { getCommitteeListQuery, getInProgressQuery, getNextAppQuery } from './query';
import { GET_COMMITTEE_LIST, GET_IN_PROGRESS, GET_NEXT_APP, CLEANUP_NEXTAPP, SUBMIT_RECOMMENDATION } from '../actions-index';
import { ERROR_CONSTS, SEVERITY_LEVELS, STATUS } from '../util/constants';
import { transformGetNextApp, filterInProgressByAdminAction } from './transform';

export const getCommitteeList = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_COMMITTEE_LIST,
        payload: { loading: true },
      });
    } catch (error) {
      console.log('GetCommitteeList Error:', error);
    }

    try {
      const response = await API.graphql({
        query: getCommitteeListQuery
      });

      if (response) {
        dispatch({
          type: GET_COMMITTEE_LIST,
          payload: {
            loading: false,
            committees: response?.data?.getCommitteeList?.committees,
            committeeMap: response?.data?.getCommitteeList?.committeeMap,
          },
        });
      }
    } catch (error) {
      console.log('GetCommitteeList Error:', error);
    }
  };
};

export const updateNextApp = (committeeId) => {
  let response;
  return async (dispatch) => {
    try {
      response = await API.graphql({
        query: getNextAppQuery,
        variables: {
          committeeId: committeeId,
        },
      });
      if (response) {
        dispatch({
          type: GET_NEXT_APP,
          status: STATUS?.SUCCESS,
          payload: {
            status: STATUS?.SUCCESS,
            nextApp: transformGetNextApp(committeeId, response?.data?.getNextApplication)
          },
        });
      }
      return response;
    } catch (error) {
      dispatch({
        type: GET_NEXT_APP,
        payload: {
          status: STATUS?.ERROR,
          alert: {
            MESSAGE: error?.errors[0]?.message,
            SEVERITY: SEVERITY_LEVELS?.ERROR,
          },
        },
      });
    }
  };
};

export const cleanupDashboardNextApp = () => {
  return async (dispatch) => {
    dispatch({
      type: CLEANUP_NEXTAPP,
      payload: {}
    })
    dispatch({
      type: SUBMIT_RECOMMENDATION,
      payload: {
        status: '',
        alert: ERROR_CONSTS.UI.GENERIC.EMPTY_ALERT,
      },
    });
  }
};

export const getInProgress = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_IN_PROGRESS,
        payload: { loading: true },
      });

      const response = await API.graphql({
        query: getInProgressQuery
      });

      if (response) {
        dispatch({
          type: GET_IN_PROGRESS,
          payload: {
            progress: filterInProgressByAdminAction(response?.data?.getInProgress)
          },
        });
      }
      dispatch({
        type: GET_IN_PROGRESS,
        payload: { loading: false },
      });
    } catch (error) {
      console.log('GetInProgress Error:', error);
    }
  };
};

