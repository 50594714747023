import { API } from 'aws-amplify';
import { FETCH_REPORTS_EXPORTS, REPORTS_EXPORTS_DOWNLOAD_START, REPORTS_EXPORTS_DOWNLOAD_STOP, REPORTS_EXPORTS_DOWNLOAD_ERROR, EXPORTS_SAVE_TASKCOMPLETE } from '../actions-index';
import { SEVERITY_LEVELS, STATUS } from "../util/constants";
import { listExportsForCommitteeIdQuery, getDownloadableLinkForPathsQuery } from './query';
import { saveExportTaskCompleteMutation } from './mutation';

export const listExportsForCommitteeId = (committeeId) => {
    return async (dispatch) => {
        if (typeof committeeId !== "string") { return; }
        const type = FETCH_REPORTS_EXPORTS;

        try {
            dispatch({ type, payload: { status: STATUS.LOADING } });
            const query = listExportsForCommitteeIdQuery;
            const variables = { committeeId };
            const response = await API.graphql({ query, variables });

            if (response) {
                const payload = { status: STATUS.SUCCESS, data: response?.data?.listExportsForCommitteeId ?? [] };
                dispatch({ type, payload });
                return;
            }

            // Handle errors
            const errorPayload = {
                status: STATUS.ERROR,
                alert: {
                    MESSAGE: response?.data?.listExportsForCommitteeId?.error?.message,
                    SEVERITY: SEVERITY_LEVELS.ERROR,
                },
            };

            dispatch({ type, payload: errorPayload });
        } catch (error) {
            console.error(error);
            console.error(error?.errors?.[0]?.message);

            const errorPayload = {
                status: STATUS.ERROR,
                alert: {
                    MESSAGE: error?.errors?.[0]?.message,
                    SEVERITY: SEVERITY_LEVELS.ERROR,
                },
            };
            dispatch({ type, payload: errorPayload });
        }
    };
};

export const getDownloadableLinkForPaths = (filenames) => {
    return async (dispatch) => {
        dispatch({ type: REPORTS_EXPORTS_DOWNLOAD_START });

        const query = getDownloadableLinkForPathsQuery;
        const variables = { filenames };

        try {
            const response = await API.graphql({ query, variables });
            const { status, details: presignedDownloadUrl, error } = response?.data?.getDownloadableLinkForPaths ?? {};
            dispatch({ type: REPORTS_EXPORTS_DOWNLOAD_STOP });
            
            if (status != "success" || (presignedDownloadUrl?.length ?? 0) === 0 || error) {
                if (error) { throw `${error.date} ${error.message}`; }
                throw `Expected success, received ${status} instead. presignedDownloadUrl was ${presignedDownloadUrl}`;
            }
        
            window.location.href = presignedDownloadUrl;
        } catch(error) {
            console.error("Error details", error);
            dispatch({ type: REPORTS_EXPORTS_DOWNLOAD_ERROR, payload: error });
        }
    };
};

export const saveExportTaskComplete = (executionId, recommendationKey, isComplete) => {
    return async (dispatch) => {
        const type = EXPORTS_SAVE_TASKCOMPLETE;
        dispatch({ type, payload: { executionId, recommendationKey, saving: true } });

        const query = saveExportTaskCompleteMutation;
        const variables = { input: { executionId, recommendationKey, isComplete } };

        try {
            const response = await API.graphql({ query, variables });
            const { status, error } = response?.data?.saveExportTaskComplete ?? {};
            console.info("Response:", status, error);

            if (error) { throw error; }
            
            dispatch({ type, payload: { executionId, recommendationKey, isComplete, saving: false } });
        } catch(error) {
            console.error("Error details", JSON.stringify(error));
            dispatch({ type, payload: { executionId, recommendationKey, error, saving: false } });
        }
    };
}