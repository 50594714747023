import { Mui } from '@osu/react-ui';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getStudentState,
  getUnassignTraineeState,
} from '../../../../Common/redux/selectors';
import { UNASSIGN_TRAINEE } from '../../../../actions-index';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const UnassignTraineeDialogContent = ({ isTraining }) => {
  const dispatch = useDispatch();
  const studentState = useSelector((state) => getStudentState(state));
  const unassignTraineeState = useSelector((state) =>
    getUnassignTraineeState(state)
  );
  const assignedReviewers = studentState?.data?.readers?.filter((item) => item.status === 'ASSIGNED') ?? [];
  const [emplids, setEmplids] = useState(unassignTraineeState?.emplids)

  const handleChangeReviewers = (event) => {
    setEmplids(event.target.value);
  };

  const finishSelect = () => {
    dispatch({
      type: UNASSIGN_TRAINEE,
      payload: {
        emplids: emplids,
      },
    });
  }

  return (
    <Mui.Box>
      <Mui.InputLabel id="mutiple-name-label">
        Currently Assigned Reviewers
        {emplids?.length > 0 &&
          `( ${emplids?.length} selected )`}
      </Mui.InputLabel>
      <Mui.Select
        labelId="mutiple-name-label"
        id="menu-reviewer-names"
        multiple
        fullWidth
        // open={true}
        value={emplids}
        onClose={finishSelect}
        onChange={handleChangeReviewers}
        input={
          <Mui.OutlinedInput
            name="reviewers"
            variant="outlined"
            id="menu-reviewer-name"
          />
        }
        MenuProps={MenuProps}
      >
        {assignedReviewers.map((reviewer) => (
          <Mui.MenuItem
            key={reviewer?.readerEmployeeId}
            value={reviewer?.readerEmployeeId}
          >
            {`${reviewer?.readerFirstName} ${reviewer?.readerLastName}`}
          </Mui.MenuItem>
        ))}
      </Mui.Select>
      {emplids?.length > 0 && (
        <Mui.Typography style={{ marginTop: '1rem' }}>
          You are about to unassign {emplids?.length}{' '}
          reviewers.
        </Mui.Typography>
      )}
    </Mui.Box>
  );
};

export default UnassignTraineeDialogContent;
