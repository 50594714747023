import React, { useContext, useEffect } from "react";
import { PageHeadingContext } from "@osu/react-ui";
import { AppContext } from "../../App/context";
import { Mui } from '@osu/react-ui';
import { Link } from 'react-router-dom';


const LogOut = () => {
  const { handleSignOut, authStatus } = useContext(AppContext);
  const { setHeading } = useContext(PageHeadingContext);

  useEffect(() => {
    setHeading("Log Out");
  }, [setHeading]);

  useEffect(() => {
    let isMounted = true;
    if (!isMounted || authStatus === 'cleared') {
      return;
    }

    const signOut = async () => {
      try {
        await handleSignOut();
      } catch (error) {
        console.error("error", error);
      }
    };

    signOut();
    return () => {
      isMounted = false;
    };
  }, [authStatus, handleSignOut]);

  return <Mui.Paper className="padding-4">
    <div>
      <Mui.Typography component="h2" variant="h5" className="mb-1">
        You have been logged out
      </Mui.Typography>
    </div>
    <div>
      <Mui.Typography component="h3" variant="subtitle1" className="mb-1">
        Please log in again
      </Mui.Typography>
      <br />
      <Mui.Button variant="contained" color="primary" style={{width: "12rem"}} component={Link} to={"/login"}>
        Log In
      </Mui.Button>
    </div>
  </Mui.Paper>

  // <div>
  //   <p>You are logged out</p>
  //   <p>Please sign in again</p>
  //   <a href={process.env.REACT_APP_SIGNIN} >sign in</a>
  // </div>;
};

export default LogOut;
