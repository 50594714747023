export const getSearchResultsQuery = `query GetSearchResults($applicantEmployeeId: String!) {
    getSearchResults(applicantEmployeeId: $applicantEmployeeId) {
        status
        error {
            message
            date
        }
        data {
            committee {
                key
                value
            }
            applicantEmployeeId
            firstName
            lastName
            appStatus
            term {
                key
                value
            }
            flags
        }
    }
}`