export const getCommitteeListQuery = `query GetCommitteeList {
    getCommitteeList {
        committees {
          userType
          committeeName
          committeeId
        }
        committeeMap {
          value
          key
        }
      }
}`;

export const getNextAppQuery = `query GetNextApplication($committeeId: String!) {
    getNextApplication(committeeId: $committeeId) {
        applicantEmployeeId
        committeeId  
        status
        term
        error {
            message
            date
        }
    }
}`;

export const getInProgressQuery = `query GetInProgress {
    getInProgress {
        assigned {
          count
          committeeId
        }
        pastDue {
          count
          committeeId
        }
        inProgress {
          adminAction
          applicantEmployeeId
          committeeId
          committeeName
          completedByDueDate
          createdDatetime
          lastName
          firstName
          manuallyAssigned
          notificationCleared
          status
          term
        }
        trainings {
          applicantEmployeeId
          committeeId
          committeeName
          lastName
          firstName
          status
          term
        }
      }
}`;

