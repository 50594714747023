export const listExportsForCommitteeIdQuery = `
    query ListExportsForCommitteeId($committeeId: String!) {
        listExportsForCommitteeId(committeeId: $committeeId) {
            adminEmployeeId
            adminName
            committeeId
            datetimeCompleted
            datetimeStarted
            executionId
            recommendations {
                count
                filename
                recommendation
                isComplete
                updatedByEmployeeId
                updatedDatetime
            }
            status
            requestAddtlInfo
        }
    }
`;

export const getDownloadableLinkForPathsQuery = `
    query GetDownloadableLinkForPaths($filenames: [String!]) {
        getDownloadableLinkForPaths(filenames: $filenames) {
            status
            details
            error {
                message
                date
            }
        }
    }
`;