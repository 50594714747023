export const getReportQuery = `query getReport($committeeId: String!, $status: String!, $filters: String, $filterExpressions: String, $limit: Int!, $lastEvaluatedKey: String, $reportType: String, $fullDownloadData: FullDownloadData) {
    getReport(committeeId: $committeeId, status: $status, filters: $filters, filterExpressions: $filterExpressions, limit: $limit, lastEvaluatedKey: $lastEvaluatedKey, reportType: $reportType, fullDownloadData: $fullDownloadData) {
        rowsAsJsonMaps
        columns {
            key
            label
            width
        }
        filterOptionsAsJsonArr
        lastEvaluatedKey
    }
}`;

export const listCustomFiltersQuery = `
    query listCustomFiltersQuery($committeeId: String!) {
        getAthenaCustomFilters(committeeId: $committeeId) {
            filtersAsJson
            status
            error
        }
    }
`;