import { FETCH_SEARCH_RESULTS, SET_SEARCH_VALUE } from '../actions-index';
import { ERROR_CONSTS } from '../util/constants';

const initialState = {
  status: '',
  alert: ERROR_CONSTS.UI.GENERIC.EMPTY_ALERT,
  data: []
}

export const navbarSearchReducer = (
  state = { searchResults: { ...initialState, type: FETCH_SEARCH_RESULTS }, searchValue: null}, 
  action = {}) => {
  switch (action.type) {
    case FETCH_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: {
          status: action.payload.status,
          alert: action.payload.alert,
          data: action.payload.data ?? [],
          type: FETCH_SEARCH_RESULTS,
        },
      };
    case SET_SEARCH_VALUE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
