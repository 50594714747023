import { FETCH_MANAGE_APPLICATIONS_DATA, FETCH_ASSIGNABLE_APPLICATIONS, MANUAL_BATCH_ASSIGN_APPLICATIONS, FETCH_ASSIGNED_APPLICATIONS,
     CLEAR_MESSAGES, SUBSCRIBE_COMMITTEE_CHANGES, FETCH_REVIEWER_DETAILS_BY_COMMITTEE, SET_SELECTED_REVIEWER, MANUAL_BATCH_UNASSIGN_APPLICATIONS, GET_CUSTOMFILTEROPTIONS } from '../actions-index';
import { ERROR_CONSTS, STATUS } from '../util/constants';

const initialState = {
    status: "",
    alert: ERROR_CONSTS.UI.GENERIC.EMPTY_ALERT,
    data: { applications: [], columns: [], filterOptions: [] },
};

export const manageApplicationsMultiReducer = (
    state = {
        manageApplicationsPageData: {
            status: "",
            alert: ERROR_CONSTS.UI.GENERIC.EMPTY_ALERT,
            data: {},
            type: FETCH_MANAGE_APPLICATIONS_DATA
        },
        assignableApplications: {
            ...initialState,
            type: FETCH_ASSIGNABLE_APPLICATIONS
        },
        manualBatchAssignApplications: {
            ...initialState,
            data: "",
            type: MANUAL_BATCH_ASSIGN_APPLICATIONS
        },
        manualBatchUnassignApplications: {
            ...initialState,
            data: "",
            type: MANUAL_BATCH_UNASSIGN_APPLICATIONS
        },
        assignedApplications: {
            ...initialState,
            type: FETCH_ASSIGNED_APPLICATIONS
        },
    },
    action = {}
) => {

    switch (action.type) {
        case CLEAR_MESSAGES: {

            return {
                ...state,
                manualBatchAssignApplications: {
                    ...initialState,
                    data: "",
                    type: MANUAL_BATCH_ASSIGN_APPLICATIONS
                }
            }
        }

        case FETCH_MANAGE_APPLICATIONS_DATA: {
            return {
                ...state,
                manageApplicationsPageData: {
                    status: action.payload.status ?? initialState.status,
                    alert: action.payload.alert ?? initialState.alert,
                    data: {
                        ...(state.manageApplicationsPageData?.data ?? {}),
                        ...convertJsonStringsToOptions(action.payload.data ?? initialState.data, state.manageApplicationsPageData?.data?.filterOptionsCustom)
                    },
                    type: FETCH_MANAGE_APPLICATIONS_DATA
                }
            }
        }

        case GET_CUSTOMFILTEROPTIONS: {
            const filterOptionsOriginal = state.manageApplicationsPageData.data.filterOptionsOriginal ?? [];
            const filterOptionsCustom = getSafeCustomFilters(action.payload.data) ?? state.manageApplicationsPageData.data.filterOptionsCustom ?? [];
    
            let newState = {
                ...state,
                manageApplicationsPageData: {
                    ...state.manageApplicationsPageData,
                    data: {
                        ...state.manageApplicationsPageData?.data,
                        filterOptions: [ ...filterOptionsOriginal, ...filterOptionsCustom ],
                        filterOptionsCustom,
                        filterOptionsCustomStatus: action.payload.status,
                        isLoadingCustomFilters: action.payload.status === STATUS.LOADING,
                        isErrorCustomFilters: action.payload.status === STATUS.ERROR,
                    },
                    type: FETCH_MANAGE_APPLICATIONS_DATA
                }
            };
    
            return newState;
        }

        case FETCH_ASSIGNABLE_APPLICATIONS: {
            let finalData = state.assignableApplications?.data ?? initialState.data;
            const uidForApiCall = (action.payload?.status === STATUS.LOADING ? action.payload.uidForApiCall : state.assignableApplications?.uidForApiCall) ?? state.assignableApplications?.uidForApiCall;

            if(action.payload?.status === STATUS.CLEAR) {
                finalData = initialState.data;
            }

            if(action.payload?.status === STATUS.SUCCESS) {
                if (action.payload.uidForApiCall === state.assignableApplications?.uidForApiCall) {
                    finalData = action.payload.data;
                    transformApplications(finalData);
                    
                    finalData = {
                        applications: [...finalData.applications, ...state.assignableApplications.data.applications],
                        lastKey: finalData.lastKey
                    };
                } else {
                    // console.info("Mismatched uuid, skipping", uidForApiCall, action.payload.uidForApiCall);
                }
            }

            return {
                ...state,
                assignableApplications: {
                    status: action.payload.status ?? initialState.status,
                    uidForApiCall,
                    alert: action.payload.alert ?? initialState.alert,
                    data: finalData,
                    type: FETCH_ASSIGNABLE_APPLICATIONS,
                }
            };
        }

        case MANUAL_BATCH_ASSIGN_APPLICATIONS: {
            return {
                ...state,
                manualBatchAssignApplications: {
                    status: action.payload.status ?? initialState.status,
                    alert: action.payload.alert ?? initialState.alert,
                    data: action.payload.data,
                    type: MANUAL_BATCH_ASSIGN_APPLICATIONS,
                }
            };
        }

        case MANUAL_BATCH_UNASSIGN_APPLICATIONS: {
            return {
                ...state,
                manualBatchUnassignApplications: {
                    status: action.payload.status ?? initialState.status,
                    alert: action.payload.alert ?? initialState.alert,
                    data: action.payload.data,
                    type: MANUAL_BATCH_UNASSIGN_APPLICATIONS,
                }
            };
        }

        case FETCH_ASSIGNED_APPLICATIONS: {
            let finalData = action.payload.data ?? initialState.data;
            transformApplications(finalData);

            return {
                ...state,
                assignedApplications: {
                    status: action.payload.status ?? initialState.status,
                    alert: action.payload.alert ?? initialState.alert,
                    data: finalData,
                    type: FETCH_ASSIGNED_APPLICATIONS,
                }
            }
        }


        case SUBSCRIBE_COMMITTEE_CHANGES: {
            return {
                ...state,
                committeeSubscription: {
                    data: action.payload
                }
            }
        }

        case FETCH_REVIEWER_DETAILS_BY_COMMITTEE: {
            return {
                ...state,
                getReviewerDetailsByCommitteeData: {
                    data: action.payload.data
                }
            }
        }

        case SET_SELECTED_REVIEWER: {
            return {
                ...state,
                selectedReviewer: action.payload                
            }
        }

        default: return state;
    }
};

function transformApplications(data) {
    data = convertJsonStringsToOptions(data);

    const applications = data.applications;
    if (!Array.isArray(applications)) { return data; }

    data.applications.forEach?.(application => {
        Object.assign(application, convertJsonToMap(application.dataAsJsonMap));
        application.id = application.applicantEmployeeId + " - " + application.term;
    });
}

function convertJsonStringsToOptions(data, oldFilterOptionsCustom) {
    if (typeof data.filterOptionsAsJsonArr === "string") {
        const filterOptions = JSON.parse(data.filterOptionsAsJsonArr) ?? [];
        data.filterOptionsOriginal = filterOptions;
        data.filterOptionsCustom = oldFilterOptionsCustom ?? [];
        
        data.filterOptions = [ ...data.filterOptionsOriginal, ...data.filterOptionsCustom];
    }
    return data;
}

function convertJsonToMap(str) {
    try {
        return JSON.parse(str);
    } catch (error) { return {}; } // Skip
}

function getSafeCustomFilters(str) {
    if (!str) { return; }

    try {
        return JSON.parse(str);
    } catch (exception) {
        console.error("Error while parsing custom filters from str", str, exception);
    }

    return [];
}