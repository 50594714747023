export function omitReduxProps(props = {}) {
    const clonedObject = Object.assign({}, props)
    delete clonedObject.history
    delete clonedObject.location
    delete clonedObject.match
    delete clonedObject.staticContext
    delete clonedObject.dispatch

    return clonedObject
}

export function isUnauthenticated(error) {
    const status = (error?.response && error?.response?.status) ? error.response.status : null;
    const unauthorizedErrors = ["The user is not authenticated", "No current user", "Request failed with status code 401"];
    
    return unauthorizedErrors.includes(error) || status === 401
}

export const capitalizeFirstLetter = (word = '') => {
    const capitalizedWord = word && word[0].toUpperCase() + word.slice(1);
    return capitalizedWord;
  };  

  export const removeSpaces = (input) => {
    return input.replace(/\s/g, '')
  }

  export const hasPeriod = (value) => {
    const hasPeriod = /\./;   
    return hasPeriod.test(value)
  }

export const sortByAttribute = (array, key ) => {
  return array.sort((a,b) => {
    const valA = a[key];
    const valB = b[key];
    if(valA < valB){
      return -1;
    } else if(valB > valA)
    return 1;
     else {
      return 0;
     }
  })
}

export function isValidFutureDate(o) {
    let todaysDate = new Date();
    todaysDate.setHours(0);
    todaysDate.setMinutes(0);
    todaysDate.setSeconds(0);
    todaysDate.setMilliseconds(0);
  
    return o instanceof Date && !isNaN(o.valueOf()) && todaysDate.getTime() <= o.getTime();
}

export function getSafeDate(date) {
  try {
    return new Date(date).toString().split(" ").slice(0, 5).join(" ");
  } catch (exception) {
    console.error("Exception while attempting to trim date string", exception, date);
    return date;
  }
}

export function formatDatetime(dttm) { return dttm ? new Date(dttm).toLocaleString() : "-"; }

export function getFormattedDate(date) {
  
  try {  
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const day = date.getDate();
    const month = date.getMonth() + 1; // getMonth() returns a zero-based value
    const year = date.getFullYear();
    
     // Determine whether the time is AM or PM
     const period = hours >= 12 ? 'PM' : 'AM';

     // Convert the hours to a 12-hour format
     hours %= 12;
 
     // The hour '0' should be '12' in 12-hour time
     if (hours === 0) {
       hours = 12;
     }
      return `${month}/${day}/${year}, ${hours}:${minutes < 10 ? '0' + minutes : minutes} ${period}`;
  } catch (exception) {
    console.error("Exception while attempting to format date string", exception, date);
    return date;
  }
}

export function generateId(prefix, index) {
  const idx = `${index ?? 0}`;
  const idPrefix = prefix ? `${prefix}-${idx}`: `id-${idx}`;

   // Generate a random string of 8 characters
   const randomString = Math.random().toString(36).substring(2, 10);
   const id = `${idPrefix}-${randomString}`;
   return id;
}