import { SEVERITY_LEVELS } from '../../util/constants';
import ErrorAlert from '../components/ErrorAlert';

export const useAlertHandling = (props = {}) => {
  const { alert } = props;
  const isSuccess = alert?.SEVERITY === SEVERITY_LEVELS?.SUCCESS;
  const message = isSuccess ? "Success" : alert?.MESSAGE;     

  return ErrorAlert({
    message: message,
    severity: alert?.SEVERITY?.toLowerCase(),
    style: isSuccess ? { fontWeight: 'bold' } : {},
  });
};
